var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var HF,IF,JF,ada,KF,MF,NF;
$CLJS.GF=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.P.N(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Kc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};HF=function(a,b){this.db=a;this.vh=b;this.C=393216;this.I=0};IF=function(a){for(var b=null;;){var c;a=$CLJS.jo.g(a,b);a=c=$CLJS.Cn(a)?$CLJS.wn(a):a;if(!$CLJS.Cn(c))return a}};JF=function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return!($CLJS.wd(a)&&!0===$CLJS.Dt.h(a))};
ada=function(a,b,c){var d=IF($CLJS.jo.g(a,c));return $CLJS.pq($CLJS.nn(d),$CLJS.kn(d),function(){var e=$CLJS.mn(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.ln($CLJS.jo.g(d,null));return $CLJS.n(e)?e:c}())};KF=function(a){return function(b,c,d){var e=$CLJS.z(c);b=$CLJS.B(e);e=$CLJS.C(e);c=$CLJS.vk.g(function(f){return $CLJS.jo.g(f,d)},c);return new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.vk.g($CLJS.bu,c),$CLJS.ab(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.LF=function(a,b,c,d,e,f,k,l,m,t,u,v,x,A,D,J,M,V,Z){this.form=a;this.options=b;this.forms=c;this.U=d;this.Xd=e;this.ya=f;this.children=k;this.min=l;this.Eb=m;this.qe=t;this.Rb=u;this.parent=v;this.Li=x;this.type=A;this.Me=D;this.cache=J;this.max=M;this.Ue=V;this.Uh=Z;this.C=393216;this.I=0};MF=function(a,b,c,d,e,f,k,l,m){this.Ue=a;this.qe=b;this.type=c;this.min=d;this.max=e;this.Xd=f;this.Eb=k;this.Rb=l;this.Me=m;this.C=393216;this.I=0};
NF=function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.Yi),d=$CLJS.I.g(b,$CLJS.Kn),e=$CLJS.I.g(b,$CLJS.Rj),f=$CLJS.I.g(b,$CLJS.xu),k=$CLJS.I.g(b,$CLJS.Rp),l=$CLJS.I.g(b,$CLJS.ct);return new MF(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.Yi,$CLJS.wp],null))};$CLJS.Y=function(a,b){$CLJS.Gh.v(OF,$CLJS.S,a,b);$CLJS.Pe($CLJS.PF,$CLJS.N);return null};HF.prototype.P=function(a,b){return new HF(this.db,b)};HF.prototype.O=function(){return this.vh};HF.prototype.rd=$CLJS.r;
HF.prototype.bd=function(a,b){return $CLJS.Ym($CLJS.$m($CLJS.q(this.db)),b)};var QF=function QF(a){switch(arguments.length){case 2:return QF.g(arguments[0],arguments[1]);case 3:return QF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};QF.g=function(a,b){return QF.j(a,b,null)};
QF.j=function(a,b,c){function d(v,x){return $CLJS.F.g($CLJS.Qj,v)?new $CLJS.Q(null,2,5,$CLJS.R,[null,x],null):$CLJS.Ye.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Eq.h(x)],null),$CLJS.Gq.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.Lk.l($CLJS.G([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?IF($CLJS.jo.g(a,c)):null;b=$CLJS.n(b)?IF($CLJS.jo.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.Qn.h(a):null,k=$CLJS.n(b)?$CLJS.Qn.h(b):null,l=$CLJS.O(c),m=$CLJS.I.j(l,$CLJS.su,function(v,x){return x}),t=$CLJS.I.j(l,$CLJS.vu,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Sa(function(){var v=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.mt,null,$CLJS.Qj,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.mt,null,$CLJS.Qj,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.F.g(f,$CLJS.Qj)&&$CLJS.F.g(k,$CLJS.Qj)){l=e($CLJS.kn(a),$CLJS.kn(b));var u=$CLJS.Oe($CLJS.Ug);a=$CLJS.ab(function(v,x){var A=
$CLJS.H(x,0,null);if($CLJS.n(function(){var D=$CLJS.q(u);return D.h?D.h(A):D.call(null,A)}()))return $CLJS.ab(function(D,J){var M=$CLJS.H(J,0,null),V=$CLJS.$d.g;if($CLJS.F.g(M,A)){M=$CLJS.H(J,0,null);var Z=$CLJS.H(J,1,null),fa=$CLJS.H(J,2,null);$CLJS.H(x,0,null);var Da=$CLJS.H(x,1,null),Ha=$CLJS.H(x,2,null);J=JF(J);var mb=JF(x);J=t.g?t.g(J,mb):t.call(null,J,mb);Z=$CLJS.Lk.l($CLJS.G([Z,Da]));Ha=new $CLJS.Q(null,3,5,$CLJS.R,[M,$CLJS.S.j(Z,$CLJS.Dt,$CLJS.Sa(J)),QF.j?QF.j(fa,Ha,c):QF.call(null,fa,Ha,
c)],null);M=$CLJS.H(Ha,0,null);fa=$CLJS.H(Ha,1,null);Ha=$CLJS.H(Ha,2,null);M=$CLJS.Sa(Ha)?new $CLJS.Q(null,2,5,$CLJS.R,[M,fa],null):$CLJS.n($CLJS.n(fa)?!1===$CLJS.Dt.h(fa)&&$CLJS.F.g(1,$CLJS.E(fa)):fa)?new $CLJS.Q(null,2,5,$CLJS.R,[M,Ha],null):$CLJS.Sa($CLJS.z(fa))?new $CLJS.Q(null,2,5,$CLJS.R,[M,Ha],null):!1===$CLJS.Dt.h(fa)?new $CLJS.Q(null,3,5,$CLJS.R,[M,$CLJS.lk.g(fa,$CLJS.Dt),Ha],null):new $CLJS.Q(null,3,5,$CLJS.R,[M,fa,Ha],null)}else M=J;return V.call($CLJS.$d,D,M)},$CLJS.tf,v);$CLJS.Gh.j(u,
$CLJS.$d,A);return $CLJS.$d.g(v,x)},$CLJS.tf,$CLJS.Wf.g($CLJS.mn(a),$CLJS.mn(b)));return $CLJS.pq($CLJS.Qj,l,a,c)}return function(v,x){var A=$CLJS.z(v);v=$CLJS.B(A);var D=$CLJS.C(A);A=$CLJS.B(D);D=$CLJS.C(D);var J=$CLJS.z(x);x=$CLJS.B(J);var M=$CLJS.C(J);J=$CLJS.B(M);M=$CLJS.C(M);return $CLJS.pq($CLJS.mt,e(v,x),$CLJS.Ye.l(new $CLJS.Q(null,1,5,$CLJS.R,[QF.j(A,J,c)],null),D,$CLJS.G([M])),c)}(d(f,a),d(k,b))};QF.A=3;
var RF=function RF(a){switch(arguments.length){case 2:return RF.g(arguments[0],arguments[1]);case 3:return RF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};RF.g=function(a,b){return RF.j(a,b,null)};
RF.j=function(a,b,c){return QF.j(a,b,$CLJS.wk.j($CLJS.wk.j(c,$CLJS.su,$CLJS.GF($CLJS.Rd,function(d,e,f){var k=$CLJS.F.g($CLJS.bu.g(d,null),$CLJS.bu.g(e,null));return $CLJS.n(k)?d:$CLJS.jo.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,d,e],null),f)})),$CLJS.vu,$CLJS.GF($CLJS.Rd,function(d,e){return $CLJS.n(d)?e:d})))};RF.A=3;
var SF=function SF(a){switch(arguments.length){case 2:return SF.g(arguments[0],arguments[1]);case 3:return SF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};SF.g=function(a,b){return SF.j(a,b,null)};SF.j=function(a,b,c){var d=$CLJS.Yg(b);return ada(a,function(e){return $CLJS.bf(function(f){f=$CLJS.H(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};SF.A=3;$CLJS.g=$CLJS.LF.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.LF(this.form,this.options,this.forms,this.U,this.Xd,this.ya,this.children,this.min,this.Eb,this.qe,this.Rb,this.parent,this.Li,this.type,this.Me,this.cache,this.max,this.Ue,b)};$CLJS.g.O=function(){return this.Uh};$CLJS.g.Ya=$CLJS.r;$CLJS.g.xa=function(){return $CLJS.gn(this.ya)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.So(this,new $CLJS.Q(null,1,5,$CLJS.R,[this.ya],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.ta=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.hn(this.ya,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.r;$CLJS.g.ia=$CLJS.ta(5);$CLJS.g.ie=$CLJS.r;$CLJS.g.Kd=function(){return this.ya};$CLJS.g=MF.prototype;$CLJS.g.P=function(a,b){return new MF(this.Ue,this.qe,this.type,this.min,this.max,this.Xd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Me};$CLJS.g.Xa=$CLJS.r;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.Ln(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.uf(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.H(a,0,null),k=$CLJS.H(a,1,null),l=$CLJS.H(a,2,null);return new $CLJS.LF(new $CLJS.Bh(function(){return $CLJS.po(e.type,b,k,d)}),d,k,b,e.Xd,l,f,e.min,e.Eb,e.qe,e.Rb,this,a,e.type,e.Me,$CLJS.oo(),e.max,e.Ue,new $CLJS.h(null,1,[$CLJS.Yi,$CLJS.Vp],null))};
$CLJS.TF=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);$CLJS.UF=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);var OF;$CLJS.PF=$CLJS.Oe($CLJS.N);
OF=$CLJS.Oe($CLJS.Lk.l($CLJS.G([$CLJS.zt(),new $CLJS.h(null,3,[$CLJS.tu,NF(new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.tu,$CLJS.ct,KF(QF)],null)),$CLJS.ru,NF(new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.ru,$CLJS.ct,KF(RF)],null)),$CLJS.yu,NF(new $CLJS.h(null,5,[$CLJS.Yi,$CLJS.yu,$CLJS.xu,1,$CLJS.Kn,2,$CLJS.Rj,2,$CLJS.ct,function(a,b,c){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wk.j(b,0,function(d){return $CLJS.jo.g(d,c)}),$CLJS.wk.j(b,0,function(d){return $CLJS.bu.g(d,c)}),$CLJS.P.g(SF,$CLJS.$d.g(b,c))],null)}],null))],
null)])));$CLJS.Pe($CLJS.hl,$CLJS.$m(new HF(OF,$CLJS.N)));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var f_,Pja,g_,Qja,Rja,Sja,h_,Uja,Vja,Wja,Xja,k_,l_,m_,n_,p_,Zja,q_,r_,$ja,aka,bka,j_,Tja,s_,t_,Yja,v_;f_=function(a,b){var c=$CLJS.z(b);for(a=$CLJS.z($CLJS.Te(a,b));;)if(a)c=$CLJS.C(c),a=$CLJS.C(a);else return c};
Pja=function(){return function(){function a(k,l,m){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi.j?$CLJS.Yi.j(k,l,m):$CLJS.Yi.call(null,k,l,m),$CLJS.Zk.j?$CLJS.Zk.j(k,l,m):$CLJS.Zk.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi.g?$CLJS.Yi.g(k,l):$CLJS.Yi.call(null,k,l),$CLJS.Zk.g?$CLJS.Zk.g(k,l):$CLJS.Zk.call(null,k,l)],null)}function c(k){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi.h?$CLJS.Yi.h(k):$CLJS.Yi.call(null,k),$CLJS.Zk.h?$CLJS.Zk.h(k):$CLJS.Zk.call(null,
k)],null)}function d(){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi.o?$CLJS.Yi.o():$CLJS.Yi.call(null),$CLJS.Zk.o?$CLJS.Zk.o():$CLJS.Zk.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.y(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.P.N($CLJS.Yi,m,t,u,v),$CLJS.P.N($CLJS.Zk,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.B(m);m=$CLJS.C(m);var u=$CLJS.B(m);m=$CLJS.C(m);var v=$CLJS.B(m);m=$CLJS.Kc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.y(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};g_=function(a){return $CLJS.qN($CLJS.OX)(a,$CLJS.tf,$CLJS.tf)};Qja=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return null==e?d:$CLJS.S.j(d,f,e)},null==b?a:$CLJS.S.j(a,$CLJS.MH,b),$CLJS.du(2,2,c))};Rja=function(a,b){var c=$CLJS.Rl(a,$CLJS.iS);if($CLJS.n(c)){var d=$CLJS.S.j;c=$CLJS.zb(c);b=$CLJS.Eu.g?$CLJS.Eu.g(c,b):$CLJS.Eu.call(null,c,b);a=d.call($CLJS.S,a,$CLJS.iS,b)}return a};
Sja=function(a){return $CLJS.Ye.g($CLJS.nX(a),$CLJS.oX(a))};h_=function(a,b,c){var d=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.It,null,$CLJS.el,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Pv(2,c):$CLJS.Pv(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?f_(2,c):f_(1,c);var f=$CLJS.H(b,0,null);return $CLJS.z(e)?$CLJS.XY.j(a,e,function(k){var l=$CLJS.S.j(k,f,null);return $CLJS.yd(k)?$CLJS.Wf.j($CLJS.tf,$CLJS.uk.h($CLJS.ll),l):$CLJS.wd(k)?$CLJS.wC($CLJS.Ta,l):l}):$CLJS.lk.g(a,f)};
Uja=function(a){return $CLJS.ab(function(b,c){var d=$CLJS.H(c,0,null);$CLJS.H(c,1,null);return h_(b,Tja,d)},a,Sja(a))};
Vja=function(a){for(var b=0;;){var c=$CLJS.bd($CLJS.SM.h(a),b);a:{var d=c;for(var e=$CLJS.tf;;){var f=$CLJS.B($CLJS.Ze.g(Pja(),$CLJS.bf($CLJS.tk.j(i_,$CLJS.B,$CLJS.Zk),$CLJS.Mt.h(g_(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);var l=h_(d,f,k),m=$CLJS.zC($CLJS.vA);if($CLJS.n($CLJS.yC("metabase.lib.convert",m))){var t=$CLJS.oD.l($CLJS.G([$CLJS.qB,$CLJS.Eh.l($CLJS.G([k]))])),u=$CLJS.oD,v=u.l,x=$CLJS.Eh,A=x.l;var D=f;D=$CLJS.n(D)?D:$CLJS.vN(g_(d));$CLJS.xC("metabase.lib.convert",
m,$CLJS.XX("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([t,v.call(u,$CLJS.G([$CLJS.qB,A.call(x,$CLJS.G([D]))])),$CLJS.oD.l($CLJS.G([$CLJS.fB,$CLJS.YX($CLJS.B($CLJS.uv(d,l)))]))])),null)}if($CLJS.F.g(l,d))break a;d=l;e=$CLJS.$d.g(e,new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null))}else break a}}d=Uja(d);if($CLJS.F.g(c,d)){if($CLJS.F.g(b,$CLJS.E($CLJS.SM.h(a))-1))return a;b+=1}else a=$CLJS.wk.N(a,$CLJS.SM,$CLJS.S,b,d)}};
Wja=function(a){var b=$CLJS.kY();return $CLJS.vk.g(function(c){return $CLJS.F.g($CLJS.KG.h(c),"__join")?$CLJS.wk.j(c,$CLJS.KG,b):c},a)};Xja=function(a){return"string"===typeof $CLJS.HQ.h(a)?$CLJS.lk.g($CLJS.S.j(a,$CLJS.pX,$CLJS.iY($CLJS.HQ.h(a))),$CLJS.HQ):a};k_=function(a,b){var c=$CLJS.I.j(a,b,j_);if($CLJS.F.g(c,j_))throw $CLJS.ai(["Unable to find ",$CLJS.Eh.l($CLJS.G([b]))," in map."].join(""),new $CLJS.h(null,2,[Yja,a,$CLJS.Fy,b],null));return c};
l_=function(){return $CLJS.uk.h(function(a){var b=$CLJS.H(a,0,null);$CLJS.H(a,1,null);(a=$CLJS.ke(b))?(b=$CLJS.ge(b),b=$CLJS.n(b)?$CLJS.F.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};m_=function(a){return $CLJS.Wf.j($CLJS.N,l_(),a)};n_=function(a){return $CLJS.De($CLJS.Wf.j($CLJS.N,$CLJS.tk.g(l_(),$CLJS.uk.h(function(b){var c=$CLJS.H(b,0,null);$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.ti)})),a))};
p_=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d=$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.Ze.h($CLJS.o_),c);a=$CLJS.F.g(a,$CLJS.FH)&&$CLJS.C(c)?$CLJS.$d.g($CLJS.rd(d),new $CLJS.h(null,1,[$CLJS.Wh,$CLJS.qd(d)],null)):d;b=$CLJS.De(n_(b));return $CLJS.n(b)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WN,a,b],null):a};Zja=function(a){return $CLJS.Wf.j($CLJS.tf,$CLJS.tk.g($CLJS.Ze.h(function(b){return $CLJS.rC(b,$CLJS.tD)}),$CLJS.Ze.h($CLJS.o_)),$CLJS.eY.h(a))};
q_=function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.SM);a=$CLJS.B($CLJS.ab(function(b,c){var d=$CLJS.H(b,0,null),e=$CLJS.H(b,1,null);b=$CLJS.R;var f=$CLJS.o_.h(c);d=$CLJS.n(d)?$CLJS.S.j(f,$CLJS.kS,d):f;d=$CLJS.n(e)?$CLJS.S.j(d,$CLJS.tQ,Zja(e)):d;return new $CLJS.Q(null,2,5,b,[d,$CLJS.PW.h(c)],null)},null,a));return $CLJS.n($CLJS.LR.h(a))?$CLJS.bX(a,new $CLJS.h(null,1,[$CLJS.LR,$CLJS.jO],null)):a};
r_=function(a,b,c){var d=$CLJS.F.g($CLJS.E($CLJS.I.g(a,b)),1)?$CLJS.uY(a,b,$CLJS.tk.g($CLJS.o_,$CLJS.B)):a;a=1<$CLJS.E($CLJS.I.g(a,b))?$CLJS.uY(d,b,function(e){return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.mt],null),$CLJS.Ze.h($CLJS.o_),e)}):d;return $CLJS.bX(a,$CLJS.Ce([b,c]))};$ja=function(a){var b=$CLJS.pX.h(a);return $CLJS.n(b)?$CLJS.S.j($CLJS.lk.g(a,$CLJS.pX),$CLJS.HQ,["card__",$CLJS.p.h(b)].join("")):a};
aka=new $CLJS.K(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);bka=new $CLJS.K(null,"legacy-ref","legacy-ref",1686404700);j_=new $CLJS.K("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Tja=new $CLJS.K("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);s_=new $CLJS.K("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
t_=new $CLJS.K("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.u_=new $CLJS.K("lib.convert","converted?","lib.convert/converted?",1937433683);Yja=new $CLJS.K(null,"m","m",1632677161);v_=new $CLJS.K("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var i_,cka,dka,eka,fka,gka,hka,ika,jka,kka,lka;$CLJS.w_=$CLJS.N;$CLJS.x_=$CLJS.N;i_=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.LX,null,$CLJS.GG,null,$CLJS.fP,null,$CLJS.BR,null,$CLJS.LH,null,$CLJS.qT,null,$CLJS.MH,null],null),null);cka=$CLJS.Oe($CLJS.N);dka=$CLJS.Oe($CLJS.N);eka=$CLJS.Oe($CLJS.N);fka=$CLJS.Oe($CLJS.N);gka=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.UD],null),$CLJS.Ti,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
$CLJS.y_=new $CLJS.Xh($CLJS.xh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.SD,gka,cka,dka,eka,fka);$CLJS.y_.m(null,$CLJS.Wh,function(a){if($CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K){var b=$CLJS.qd(a);var c=$CLJS.wd(b);a=c?$CLJS.rd(a):a;var d=$CLJS.z(a);a=$CLJS.B(d);d=$CLJS.C(d);b=$CLJS.SX($CLJS.Wf.j(new $CLJS.Q(null,2,5,$CLJS.R,[a,c?b:$CLJS.N],null),$CLJS.Ze.h($CLJS.y_),d))}else b=a;return b});$CLJS.y_.m(null,$CLJS.JX,function(a){return a});
$CLJS.y_.m(null,$CLJS.wX,function(a){var b=$CLJS.y_.h($CLJS.MH.h(a)),c=$CLJS.De($CLJS.vk.g(function(t){var u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);return $CLJS.bY($CLJS.y_.h(t),u)},$CLJS.BR.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(t,u){$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=$CLJS.O(u);u=$CLJS.I.g(u,$CLJS.mG);return new $CLJS.Q(null,2,5,$CLJS.R,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(t,u){$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=$CLJS.O(u);u=$CLJS.I.g(u,$CLJS.mG);return new $CLJS.Q(null,
2,5,$CLJS.R,[u,t],null)}),b),f=$CLJS.x_,k=$CLJS.w_;$CLJS.x_=d;$CLJS.w_=e;try{var l=Qja(Xja(a),b,$CLJS.G([$CLJS.BR,c])),m=$CLJS.ab(function(t,u){return $CLJS.Sa($CLJS.I.g(t,u))?t:$CLJS.wk.j(t,u,$CLJS.y_)},l,$CLJS.mk.l(i_,$CLJS.MH,$CLJS.G([$CLJS.BR])));return $CLJS.n($CLJS.fP.h(m))?$CLJS.wk.j(m,$CLJS.fP,Wja):m}finally{$CLJS.w_=k,$CLJS.x_=f}});$CLJS.y_.m(null,$CLJS.DX,function(a){return Rja(a,function(b){return $CLJS.uY(b,$CLJS.LN,$CLJS.y_)})});
$CLJS.y_.m(null,$CLJS.NM,function(a){a=$CLJS.wk.j($CLJS.wk.j(a,$CLJS.VM,$CLJS.y_),$CLJS.SM,$CLJS.y_);var b=$CLJS.n($CLJS.GG.h(a))?$CLJS.wk.j(a,$CLJS.GG,function(c){return $CLJS.xl(c)?$CLJS.vk.g($CLJS.y_,c):$CLJS.rh.h(c)}):a;return $CLJS.Sa($CLJS.KG.h(a))?$CLJS.S.j(b,$CLJS.KG,"__join"):b});$CLJS.y_.m(null,$CLJS.aD,function(a){return $CLJS.vk.g($CLJS.y_,a)});
$CLJS.y_.m(null,$CLJS.cD,function(a){return $CLJS.n($CLJS.Yi.h(a))?Vja($CLJS.S.j($CLJS.wk.j($CLJS.fY(a),$CLJS.SM,function(b){return $CLJS.vk.g($CLJS.y_,b)}),$CLJS.u_,!0)):$CLJS.Eu(a,$CLJS.y_)});$CLJS.y_.m(null,$CLJS.XH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.wd(b)?new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null):new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.SX(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,a,b],null))});
$CLJS.y_.m(null,$CLJS.oj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.bX(a,new $CLJS.h(null,3,[$CLJS.TE,$CLJS.gD,$CLJS.fQ,$CLJS.Ej,$CLJS.yT,$CLJS.hG],null));var c=$CLJS.S.j;var d=$CLJS.ti.h(a);$CLJS.n(d)||(d=$CLJS.gD.h(a),d=$CLJS.n(d)?d:$CLJS.BG(b));a=c.call($CLJS.S,a,$CLJS.ti,d);return $CLJS.SX(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oj,a,b],null))});
$CLJS.y_.m(null,$CLJS.FH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.Wh.h(c);b=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FH,$CLJS.lk.g(c,$CLJS.Wh),$CLJS.vk.g($CLJS.y_,b)],null);b=$CLJS.SX(b);return null!=a?$CLJS.$d.g(b,$CLJS.y_.h(a)):b});$CLJS.y_.m(null,$CLJS.dD,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.SX(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,c],null))});
$CLJS.y_.m(null,$CLJS.MH,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.SX(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,k_($CLJS.x_,c)],null))});$CLJS.y_.m(null,$CLJS.WN,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=$CLJS.y_.h(b);var c=$CLJS.z(b);b=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);d=$CLJS.C(d);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.Lk.l($CLJS.G([c,a]))],null),d)});hka=$CLJS.Oe($CLJS.N);ika=$CLJS.Oe($CLJS.N);
jka=$CLJS.Oe($CLJS.N);kka=$CLJS.Oe($CLJS.N);lka=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.UD],null),$CLJS.Ti,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));$CLJS.o_=new $CLJS.Xh($CLJS.xh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.SD,lka,hka,ika,jka,kka);
$CLJS.o_.m(null,$CLJS.Wh,function(a){if($CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K){a=$CLJS.z(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);if($CLJS.wd(a)){c=$CLJS.Wf.g;b=new $CLJS.Q(null,1,5,$CLJS.R,[b],null);var e=$CLJS.Ye.g;d=$CLJS.Ze.g($CLJS.o_,d);a=n_(a);a=c.call($CLJS.Wf,b,e.call($CLJS.Ye,d,$CLJS.n(a)?new $CLJS.Q(null,1,5,$CLJS.R,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[b],null),$CLJS.Ze.g($CLJS.o_,$CLJS.ce(a,d)))}else a=$CLJS.wd(a)?$CLJS.Eu(m_(a),$CLJS.o_):
a;return a});for(var z_=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[v_,t_],null)),A_=null,B_=0,C_=0;;)if(C_<B_){var mka=A_.X(null,C_);$CLJS.fH(mka,s_);C_+=1}else{var D_=$CLJS.z(z_);if(D_){var E_=D_;if($CLJS.zd(E_)){var F_=$CLJS.kc(E_),nka=$CLJS.lc(E_),oka=F_,pka=$CLJS.E(F_);z_=nka;A_=oka;B_=pka}else{var qka=$CLJS.B(E_);$CLJS.fH(qka,s_);z_=$CLJS.C(E_);A_=null;B_=0}C_=0}else break}
for(var G_=$CLJS.z(new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.Iz,$CLJS.RH,$CLJS.fI,$CLJS.BH,$CLJS.Rj,$CLJS.kI,$CLJS.Kn,$CLJS.IH,$CLJS.dI,$CLJS.nI,$CLJS.jI,$CLJS.sI],null)),H_=null,I_=0,J_=0;;)if(J_<I_){var rka=H_.X(null,J_);$CLJS.fH(rka,v_);J_+=1}else{var K_=$CLJS.z(G_);if(K_){var L_=K_;if($CLJS.zd(L_)){var M_=$CLJS.kc(L_),ska=$CLJS.lc(L_),tka=M_,uka=$CLJS.E(M_);G_=ska;H_=tka;I_=uka}else{var vka=$CLJS.B(L_);$CLJS.fH(vka,v_);G_=$CLJS.C(L_);H_=null;I_=0}J_=0}else break}
for(var N_=$CLJS.z($CLJS.pf([$CLJS.Is,$CLJS.ku,$CLJS.Js,$CLJS.tI,$CLJS.FH,$CLJS.$H,$CLJS.wH,$CLJS.vI,$CLJS.pH,$CLJS.KH,$CLJS.sH,$CLJS.VH,$CLJS.bI,$CLJS.tH,$CLJS.EK,$CLJS.GK,$CLJS.wA,$CLJS.AK,$CLJS.IK,$CLJS.uK,$CLJS.MK,$CLJS.zK,$CLJS.sK,$CLJS.LK,$CLJS.TK,$CLJS.PK,$CLJS.yK,$CLJS.WK,$CLJS.OK,$CLJS.UK,$CLJS.vH,$CLJS.qH,$CLJS.wI,$CLJS.LJ,$CLJS.ZH,$CLJS.Gz,$CLJS.oI,$CLJS.qI,$CLJS.xI,$CLJS.Iu,$CLJS.Qu],!0)),O_=null,P_=0,Q_=0;;)if(Q_<P_){var wka=O_.X(null,Q_);$CLJS.fH(wka,t_);Q_+=1}else{var R_=$CLJS.z(N_);
if(R_){var S_=R_;if($CLJS.zd(S_)){var T_=$CLJS.kc(S_),xka=$CLJS.lc(S_),yka=T_,zka=$CLJS.E(T_);N_=xka;O_=yka;P_=zka}else{var Aka=$CLJS.B(S_);$CLJS.fH(Aka,t_);N_=$CLJS.C(S_);O_=null;P_=0}Q_=0}else break}$CLJS.o_.m(null,s_,function(a){return p_(a)});$CLJS.o_.m(null,$CLJS.cD,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.tk.g(l_(),$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.o_.h(b)],null)})),a)});
$CLJS.o_.m(null,$CLJS.MH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);if($CLJS.wd(b))try{var d=n_(b),e=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MH,k_($CLJS.w_,c)],null);return $CLJS.n(d)?$CLJS.$d.g(e,d):e}catch(f){throw b=f,c=$CLJS.VW(b),c=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.$h(c,new $CLJS.h(null,1,[$CLJS.X,a],null),b);}else return a});
$CLJS.o_.m(null,$CLJS.aD,function(a){return $CLJS.vk.g($CLJS.o_,a)});$CLJS.o_.m(null,$CLJS.XH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=null==b||$CLJS.wd(b)?new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null):new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,$CLJS.o_.h(a),n_(b)],null)});
$CLJS.o_.m(null,$CLJS.oj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=n_($CLJS.bX(b,new $CLJS.h(null,3,[$CLJS.gD,$CLJS.TE,$CLJS.Ej,$CLJS.fQ,$CLJS.hG,$CLJS.yT],null)));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oj,a,b],null)});
$CLJS.o_.m(null,$CLJS.NM,function(a){var b=m_(a);a=0==$CLJS.KG.h(a).lastIndexOf("__join",0)?$CLJS.lk.g(b,$CLJS.KG):b;return $CLJS.Lk.l($CLJS.G([$CLJS.Eu($CLJS.lk.l(a,$CLJS.SM,$CLJS.G([$CLJS.VM])),$CLJS.o_),r_($CLJS.Ql(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.VM],null)),$CLJS.VM,$CLJS.sT),q_(a)]))});
$CLJS.o_.m(null,$CLJS.wX,function(a){var b=$CLJS.MH.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(f,k){$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);k=$CLJS.O(k);k=$CLJS.I.g(k,$CLJS.mG);return new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(f,k){$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);k=$CLJS.O(k);k=$CLJS.I.g(k,$CLJS.mG);return new $CLJS.Q(null,2,5,$CLJS.R,[k,f],null)}),b);var d=$CLJS.x_,e=$CLJS.w_;$CLJS.x_=c;$CLJS.w_=b;try{return $CLJS.ab(function(f,k){return $CLJS.uY(f,
k,$CLJS.o_)},r_($CLJS.uY($CLJS.uY($ja(m_(a)),$CLJS.MH,function(f){return $CLJS.vk.g(p_,f)}),$CLJS.BR,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.kd(u,A),J=$CLJS.o_.h(D);D=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aY(D),$CLJS.F.g($CLJS.oj,$CLJS.B(J))?$CLJS.hd(J):J],null);x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),
m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);u=$CLJS.o_.h(x);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aY(x),$CLJS.F.g($CLJS.oj,$CLJS.B(u))?$CLJS.hd(u):u],null),m($CLJS.Kc(t)))}return null}},null,null)}(f)}())}),$CLJS.LX,$CLJS.AH),$CLJS.mk.l(i_,$CLJS.MH,$CLJS.G([$CLJS.LX,$CLJS.BR])))}finally{$CLJS.w_=e,$CLJS.x_=d}});$CLJS.o_.m(null,$CLJS.DX,function(a){return $CLJS.Eu(m_(a),$CLJS.o_)});
$CLJS.o_.m(null,$CLJS.JX,function(a){try{var b=m_(a),c=$CLJS.XO.h(b),d=q_(b),e=$CLJS.F.g($CLJS.nv.h($CLJS.id($CLJS.SM.h(a))),$CLJS.DX)?$CLJS.LR:$CLJS.jO;return $CLJS.Lk.l($CLJS.G([$CLJS.Eu($CLJS.lk.l(b,$CLJS.SM,$CLJS.G([$CLJS.XO,$CLJS.u_])),$CLJS.o_),function(){var k=$CLJS.Ce([$CLJS.Yi,e,e,d]);return $CLJS.z(c)?$CLJS.S.j(k,$CLJS.XO,c):k}()]))}catch(k){var f=k;throw $CLJS.$h(function(){var l=$CLJS.VW(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.jO,a],null),f);}});
$CLJS.U_=function(){function a(d,e,f){f=$CLJS.e_(null,$CLJS.DB(f,$CLJS.G([$CLJS.oi,!0])));var k=$CLJS.hY(d,e);k=$CLJS.O(k);var l=$CLJS.I.g(k,$CLJS.MH);k=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(v,x){$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);x=$CLJS.O(x);x=$CLJS.I.g(x,$CLJS.mG);return new $CLJS.Q(null,2,5,$CLJS.R,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.Sl(function(v,x){$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);x=$CLJS.O(x);x=$CLJS.I.g(x,$CLJS.mG);return new $CLJS.Q(null,2,5,$CLJS.R,[x,v],null)}),l);var m=
$CLJS.x_,t=$CLJS.w_;$CLJS.x_=k;$CLJS.w_=l;try{try{return $CLJS.y_.h(f)}catch(v){var u=v;throw $CLJS.$h(function(){var x=$CLJS.VW(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.jO,d,$CLJS.nY,e,bka,f,aka,$CLJS.x_],null),u);}}finally{$CLJS.w_=t,$CLJS.x_=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
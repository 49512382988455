var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.reader.js");
'use strict';var dC=function(a,b,c,d,e){this.ab=a;this.qa=b;this.K=c;this.Na=d;this.J=e;this.C=2565220111;this.I=0},eC=new $CLJS.K("tailrecursion.priority-map","not-found","tailrecursion.priority-map/not-found",-436727517);$CLJS.g=dC.prototype;$CLJS.g.na=function(a,b){return $CLJS.I.g(this.qa,b)};$CLJS.g.$=function(a,b,c){return $CLJS.I.j(this.qa,b,c)};$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#tailrecursion.priority-map {",", ","}",c,this)};$CLJS.g.O=function(){return this.K};$CLJS.g.ha=function(){return $CLJS.E(this.qa)};
$CLJS.g.nc=function(){if(0===$CLJS.E(this.qa))return null;var a=$CLJS.B(this.ab),b=$CLJS.B($CLJS.zb(a));return $CLJS.n(this.Na)?new $CLJS.Q(null,2,5,$CLJS.R,[b,this.qa.h?this.qa.h(b):this.qa.call(null,b)],null):new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.yb(a)],null)};
$CLJS.g.oc=function(){if(0===$CLJS.E(this.qa))throw Error("Can't pop empty priority map");var a=$CLJS.B(this.ab),b=$CLJS.zb(a),c=$CLJS.B(b);a=$CLJS.yb(a);return $CLJS.F.g($CLJS.E(b),1)?new dC($CLJS.lk.g(this.ab,a),$CLJS.lk.g(this.qa,c),this.K,this.Na,null):new dC($CLJS.S.j(this.ab,a,$CLJS.mk.g(b,c)),$CLJS.lk.g(this.qa,c),this.K,this.Na,null)};
$CLJS.g.mc=function(){var a=this,b=this;return $CLJS.n(a.Na)?$CLJS.z(function(){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;){var k=$CLJS.z(f);if(k){var l=k,m=$CLJS.B(l),t=$CLJS.H(m,0,null),u=$CLJS.H(m,1,null);if(k=$CLJS.z(function(v,x,A,D,J,M,V){return function Da(fa){return new $CLJS.le(null,function(){return function(){for(;;){var Ha=$CLJS.z(fa);if(Ha){if($CLJS.zd(Ha)){var mb=$CLJS.kc(Ha),Qb=$CLJS.E(mb),Ab=$CLJS.oe(Qb);a:for(var jc=0;;)if(jc<Qb){var Tb=$CLJS.kd(mb,jc);
Tb=new $CLJS.Q(null,2,5,$CLJS.R,[Tb,a.qa.h?a.qa.h(Tb):a.qa.call(null,Tb)],null);Ab.add(Tb);jc+=1}else{mb=!0;break a}return mb?$CLJS.re($CLJS.te(Ab),Da($CLJS.lc(Ha))):$CLJS.re($CLJS.te(Ab),null)}Ab=$CLJS.B(Ha);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[Ab,a.qa.h?a.qa.h(Ab):a.qa.call(null,Ab)],null),Da($CLJS.Kc(Ha)))}return null}}}(v,x,A,D,J,M,V),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.Ye.g(k,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null}},null,null)}($CLJS.Zb(a.ab))}()):$CLJS.z(function(){return function e(d){return new $CLJS.le(null,
function(){for(var f=d;;){var k=$CLJS.z(f);if(k){var l=k,m=$CLJS.B(l),t=$CLJS.H(m,0,null),u=$CLJS.H(m,1,null);if(k=$CLJS.z(function(v,x,A,D,J,M,V){return function Da(fa){return new $CLJS.le(null,function(Ha,mb,Qb){return function(){for(;;){var Ab=$CLJS.z(fa);if(Ab){if($CLJS.zd(Ab)){var jc=$CLJS.kc(Ab),Tb=$CLJS.E(jc),rc=$CLJS.oe(Tb);a:for(var Vb=0;;)if(Vb<Tb){var Sb=$CLJS.kd(jc,Vb);rc.add(new $CLJS.Q(null,2,5,$CLJS.R,[Sb,Qb],null));Vb+=1}else{jc=!0;break a}return jc?$CLJS.re($CLJS.te(rc),Da($CLJS.lc(Ab))):
$CLJS.re($CLJS.te(rc),null)}rc=$CLJS.B(Ab);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[rc,Qb],null),Da($CLJS.Kc(Ab)))}return null}}}(v,x,A,D,J,M,V),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.Ye.g(k,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null}},null,null)}($CLJS.Zb(a.ab))}())};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=$CLJS.Pc(this)};$CLJS.g.V=function(a,b){return $CLJS.Nb(this.qa,b)};$CLJS.g.oa=function(){return $CLJS.od($CLJS.fC,this.K)};
$CLJS.g.Gb=function(a,b){a=this.qa.g?this.qa.g(b,eC):this.qa.call(null,b,eC);if($CLJS.F.g(a,eC))return this;a=this.Na.h?this.Na.h(a):this.Na.call(null,a);var c=this.ab.h?this.ab.h(a):this.ab.call(null,a);return $CLJS.F.g($CLJS.E(c),1)?new dC($CLJS.lk.g(this.ab,a),$CLJS.lk.g(this.qa,b),this.K,this.Na,null):new dC($CLJS.S.j(this.ab,a,$CLJS.mk.g(c,b)),$CLJS.lk.g(this.qa,b),this.K,this.Na,null)};
$CLJS.g.ma=function(a,b,c){var d=$CLJS.I.j(this.qa,b,null);if($CLJS.n(d)){if($CLJS.F.g(d,c))return this;a=this.Na.h?this.Na.h(c):this.Na.call(null,c);d=this.Na.h?this.Na.h(d):this.Na.call(null,d);var e=$CLJS.I.g(this.ab,d);return $CLJS.F.g($CLJS.E(e),1)?new dC($CLJS.S.j($CLJS.lk.g(this.ab,d),a,$CLJS.$d.g($CLJS.I.j(this.ab,a,$CLJS.Ug),b)),$CLJS.S.j(this.qa,b,c),this.K,this.Na,null):new dC($CLJS.S.l(this.ab,d,$CLJS.mk.g($CLJS.I.g(this.ab,d),b),$CLJS.G([a,$CLJS.$d.g($CLJS.I.j(this.ab,a,$CLJS.Ug),b)])),
$CLJS.S.j(this.qa,b,c),this.K,this.Na,null)}a=this.Na.h?this.Na.h(c):this.Na.call(null,c);return new dC($CLJS.S.j(this.ab,a,$CLJS.$d.g($CLJS.I.j(this.ab,a,$CLJS.Ug),b)),$CLJS.S.j(this.qa,b,c),this.K,this.Na,null)};$CLJS.g.Va=function(a,b){return $CLJS.Gd(this.qa,b)};
$CLJS.g.ga=function(){var a=this,b=this;return $CLJS.n(a.Na)?$CLJS.z(function(){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;){var k=$CLJS.z(f);if(k){var l=k,m=$CLJS.B(l),t=$CLJS.H(m,0,null),u=$CLJS.H(m,1,null);if(k=$CLJS.z(function(v,x,A,D,J,M,V){return function Da(fa){return new $CLJS.le(null,function(){return function(){for(;;){var Ha=$CLJS.z(fa);if(Ha){if($CLJS.zd(Ha)){var mb=$CLJS.kc(Ha),Qb=$CLJS.E(mb),Ab=$CLJS.oe(Qb);a:for(var jc=0;;)if(jc<Qb){var Tb=$CLJS.kd(mb,jc);
Tb=new $CLJS.Q(null,2,5,$CLJS.R,[Tb,a.qa.h?a.qa.h(Tb):a.qa.call(null,Tb)],null);Ab.add(Tb);jc+=1}else{mb=!0;break a}return mb?$CLJS.re($CLJS.te(Ab),Da($CLJS.lc(Ha))):$CLJS.re($CLJS.te(Ab),null)}Ab=$CLJS.B(Ha);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[Ab,a.qa.h?a.qa.h(Ab):a.qa.call(null,Ab)],null),Da($CLJS.Kc(Ha)))}return null}}}(v,x,A,D,J,M,V),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.Ye.g(k,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null}},null,null)}(a.ab)}()):$CLJS.z(function(){return function e(d){return new $CLJS.le(null,
function(){for(var f=d;;){var k=$CLJS.z(f);if(k){var l=k,m=$CLJS.B(l),t=$CLJS.H(m,0,null),u=$CLJS.H(m,1,null);if(k=$CLJS.z(function(v,x,A,D,J,M,V){return function Da(fa){return new $CLJS.le(null,function(Ha,mb,Qb){return function(){for(;;){var Ab=$CLJS.z(fa);if(Ab){if($CLJS.zd(Ab)){var jc=$CLJS.kc(Ab),Tb=$CLJS.E(jc),rc=$CLJS.oe(Tb);a:for(var Vb=0;;)if(Vb<Tb){var Sb=$CLJS.kd(jc,Vb);rc.add(new $CLJS.Q(null,2,5,$CLJS.R,[Sb,Qb],null));Vb+=1}else{jc=!0;break a}return jc?$CLJS.re($CLJS.te(rc),Da($CLJS.lc(Ab))):
$CLJS.re($CLJS.te(rc),null)}rc=$CLJS.B(Ab);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[rc,Qb],null),Da($CLJS.Kc(Ab)))}return null}}}(v,x,A,D,J,M,V),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.Ye.g(k,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null}},null,null)}(a.ab)}())};$CLJS.g.P=function(a,b){return new dC(this.ab,this.qa,b,this.Na,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};
$CLJS.g.call=function(a){switch(arguments.length-1){case 1:return this.h(arguments[1]);case 2:return this.g(arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length-1)].join(""));}};$CLJS.g.apply=function(a,b){return this.call.apply(this,[this].concat($CLJS.$a(b)))};$CLJS.g.h=function(a){return this.na(null,a)};$CLJS.g.g=function(a,b){return this.$(null,a,b)};
$CLJS.fC=new dC(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;a:for(b=$CLJS.z(0<b.length?new $CLJS.y(b.slice(0),0,null):null),d=$CLJS.Jk;;)if(b)c=$CLJS.C($CLJS.C(b)),d=$CLJS.S.j(d,$CLJS.B(b),$CLJS.hd(b)),b=c;else break a;return d}(),$CLJS.N,$CLJS.N,$CLJS.Rd,null);$CLJS.I.g($CLJS.q($CLJS.cC),"tailrecursion.priority-map");
$CLJS.Gh.v($CLJS.cC,$CLJS.S,"tailrecursion.priority-map",function(a){if($CLJS.wd(a))return $CLJS.Wf.g($CLJS.fC,a);throw Error("Priority map literal expects a map for its elements.");});
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var N4,P4,Q4,R4,bna;N4=function(a,b,c){var d=$CLJS.hY(a,b);d=$CLJS.O(d);d=$CLJS.I.g(d,$CLJS.MH);d=$CLJS.hJ($CLJS.tk.j($CLJS.Vg([c]),$CLJS.mG,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.ai($CLJS.WF("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.h(null,3,[$CLJS.vt,c,$CLJS.jO,a,$CLJS.nY,b],null));return d};$CLJS.O4=function(a){return $CLJS.e3($CLJS.jI,new $CLJS.Q(null,1,5,$CLJS.R,[a],null))};$CLJS.ana=new $CLJS.K(null,"aggregation-index","aggregation-index",-1057045587);
P4=new $CLJS.K("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);Q4=new $CLJS.K("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);R4=new $CLJS.K("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);bna=new $CLJS.K(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.T1.m(null,$CLJS.MH,function(a,b){var c=$CLJS.De($CLJS.MH.h($CLJS.hY(a,b)));return $CLJS.n(c)?$CLJS.f3($CLJS.yG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.s1.v(a,b,v,$CLJS.t1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);return $CLJS.ce($CLJS.s1.v(a,
b,t,$CLJS.t1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):null});$CLJS.W1.m(null,$CLJS.MH,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.O(d);d=$CLJS.I.g(e,$CLJS.gD);e=$CLJS.I.g(e,$CLJS.ti);c=$CLJS.H(c,2,null);c=N4(a,b,c);return $CLJS.Lk.l($CLJS.G([$CLJS.r1.j(a,b,c),new $CLJS.h(null,2,[$CLJS.tM,$CLJS.LM,$CLJS.O1,$CLJS.mG.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.gD,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.ti,e],null):null]))});
$CLJS.Q1.m(null,$CLJS.MH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.s1.v(a,b,N4(a,b,c),d)});$CLJS.fH(Q4,R4);
for(var S4=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Iz,$CLJS.YH],null)),T4=null,U4=0,V4=0;;)if(V4<U4){var cna=T4.X(null,V4);$CLJS.fH(cna,Q4);V4+=1}else{var W4=$CLJS.z(S4);if(W4){var X4=W4;if($CLJS.zd(X4)){var Y4=$CLJS.kc(X4),dna=$CLJS.lc(X4),ena=Y4,fna=$CLJS.E(Y4);S4=dna;T4=ena;U4=fna}else{var gna=$CLJS.B(X4);$CLJS.fH(gna,Q4);S4=$CLJS.C(X4);T4=null;U4=0}V4=0}else break}
$CLJS.Q1.m(null,Q4,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);if($CLJS.n(c))switch(a=$CLJS.s1.v(a,b,c,d),e=e instanceof $CLJS.K?e.T:null,e){case "count":return $CLJS.WF("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.WF("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.K?e.T:null,e){case "count":return $CLJS.yG("Count");case "cum-count":return $CLJS.yG("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.R1.m(null,Q4,function(a,b,c){a=$CLJS.H(c,0,null);a=a instanceof $CLJS.K?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.W1.m(null,Q4,function(a,b,c){var d=$CLJS.S.j,e=$CLJS.Uh($CLJS.W1,R4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.S,a,$CLJS.Ej,$CLJS.HE)});$CLJS.Q1.m(null,$CLJS.FH,function(){return $CLJS.yG("Case")});
$CLJS.R1.m(null,$CLJS.FH,function(){return"case"});$CLJS.fH(P4,R4);for(var Z4=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.RH,$CLJS.uI,$CLJS.BH,$CLJS.Rj,$CLJS.kI,$CLJS.Kn,$CLJS.nI,$CLJS.jI,$CLJS.CH],null)),$4=null,a5=0,b5=0;;)if(b5<a5){var hna=$4.X(null,b5);$CLJS.fH(hna,P4);b5+=1}else{var c5=$CLJS.z(Z4);if(c5){var d5=c5;if($CLJS.zd(d5)){var e5=$CLJS.kc(d5),ina=$CLJS.lc(d5),jna=e5,kna=$CLJS.E(e5);Z4=ina;$4=jna;a5=kna}else{var lna=$CLJS.B(d5);$CLJS.fH(lna,P4);Z4=$CLJS.C(d5);$4=null;a5=0}b5=0}else break}
$CLJS.R1.m(null,P4,function(a,b,c){a=$CLJS.H(c,0,null);$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=a instanceof $CLJS.K?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.Q1.m(null,P4,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.s1.v(a,b,c,d);e=e instanceof $CLJS.K?e.T:null;switch(e){case "avg":return $CLJS.WF("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.WF("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.WF("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.WF("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.WF("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.WF("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.WF("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.WF("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.WF("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.Q1.m(null,$CLJS.IH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);return $CLJS.WF("{0}th percentile of {1}",$CLJS.G([c,$CLJS.s1.v(a,b,e,d)]))});$CLJS.R1.m(null,$CLJS.IH,function(){return"percentile"});
$CLJS.fH($CLJS.IH,R4);$CLJS.Q1.m(null,$CLJS.sI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.WF("Sum of {0} matching condition",$CLJS.G([$CLJS.s1.v(a,b,e,d)]))});$CLJS.R1.m(null,$CLJS.sI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.S1.j(a,b,d))].join("")});$CLJS.fH($CLJS.sI,R4);$CLJS.Q1.m(null,$CLJS.dI,function(){return $CLJS.yG("Share of rows matching condition")});
$CLJS.R1.m(null,$CLJS.dI,function(){return"share"});$CLJS.fH($CLJS.dI,R4);$CLJS.Q1.m(null,$CLJS.fI,function(){return $CLJS.yG("Count of rows matching condition")});$CLJS.R1.m(null,$CLJS.fI,function(){return"count-where"});$CLJS.fH($CLJS.fI,R4);
$CLJS.W1.m(null,R4,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.Lk.l;d=$CLJS.n(d)?$CLJS.Ql($CLJS.r1.j(a,b,d),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.FM],null)):null;var f=$CLJS.Uh($CLJS.W1,$CLJS.Wh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Lk,$CLJS.G([d,a]))});
$CLJS.mna=function(){function a(d){return $CLJS.e3($CLJS.Iz,new $CLJS.Q(null,1,5,$CLJS.R,[d],null))}function b(){return $CLJS.e3($CLJS.Iz,$CLJS.tf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.RX.m(null,$CLJS.MH,function(a){return a});
$CLJS.f5=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.SD(f),$CLJS.xM))f=$CLJS.TX(f);else return $CLJS.O2(d,e,$CLJS.MH,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.g5=function(){function a(d,e){return $CLJS.De($CLJS.MH.h($CLJS.hY(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.h5=function(){function a(d,e){var f=$CLJS.De($CLJS.MH.h($CLJS.hY(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.tf,$CLJS.Ze.h(function(k){var l=$CLJS.r1.j(d,e,k),m=$CLJS.S.l,t=$CLJS.gD.h(l);return m.call($CLJS.S,$CLJS.c3(l,$CLJS.ti,$CLJS.n(t)?t:$CLJS.lj),$CLJS.tM,$CLJS.LM,$CLJS.G([$CLJS.O1,$CLJS.mG.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.Q1.m(null,$CLJS.GI,function(a,b,c){a=$CLJS.O(c);a=$CLJS.I.g(a,$CLJS.CI);return $CLJS.aG.h(a.o?a.o():a.call(null))});$CLJS.X1.m(null,$CLJS.GI,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.I.g(d,$CLJS.QH);b=$CLJS.I.g(d,$CLJS.CI);c=$CLJS.I.g(d,$CLJS.BI);d=$CLJS.I.g(d,$CLJS.k0);a=$CLJS.S.l(b.o?b.o():b.call(null),$CLJS.N1,$CLJS.NC(a),$CLJS.G([$CLJS.L1,c]));return null!=d?$CLJS.S.j(a,$CLJS.A1,d):a});
$CLJS.nna=function(){function a(d,e){var f=function(){var m=$CLJS.XB.h($CLJS.h2($CLJS.n1(d)));return $CLJS.n(m)?m:$CLJS.Ug}(),k=$CLJS.hY(d,e),l=$CLJS.a2.j(d,e,k);return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.tk.j($CLJS.af(function(m){m=$CLJS.EI.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Il(function(m){m=$CLJS.O(m);var t=$CLJS.I.g(m,$CLJS.BI),u=$CLJS.I.g(m,$CLJS.zI);if($CLJS.Sa(t))return m;if($CLJS.F.g(u,$CLJS.xt))return $CLJS.S.j(m,$CLJS.eY,l);t=$CLJS.De($CLJS.b3(function(v){return $CLJS.V0(u,
v)},l));return $CLJS.n(t)?$CLJS.S.j(m,$CLJS.eY,t):null}),$CLJS.Ze.h(function(m){return $CLJS.S.j(m,$CLJS.nv,$CLJS.GI)})),$CLJS.HI))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.ona=function(){function a(d,e){return $CLJS.SX(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QH.h(d),$CLJS.N,$CLJS.UX.h(e)],null))}function b(d){if($CLJS.Sa($CLJS.BI.h(d)))return $CLJS.SX(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QH.h(d),$CLJS.N],null));var e=$CLJS.QH.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.ai(e,new $CLJS.h(null,1,[bna,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
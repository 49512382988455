var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var sma,tma,uma,d4,vma,i4,j4,k4,l4,wma,xma,yma,zma,Ama,Bma,Cma,Dma,Ema,v4,w4,x4,y4,Fma,Gma,u4,t4,Hma;$CLJS.b4=function(a,b){$CLJS.ab(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
sma=function(a){return function(b){var c=$CLJS.Re(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.rl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};tma=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.y(b.slice(3),0,null):null;return $CLJS.Sa($CLJS.z($CLJS.qC(c,d)))?c:$CLJS.P.N($CLJS.XY,c,d,e,b)};$CLJS.c4=function(a,b){return $CLJS.$X(a)&&$CLJS.F.g($CLJS.B(a),b)};
uma=function(a,b){return $CLJS.B($CLJS.Ol(function(c){if($CLJS.n($CLJS.n(c)?b:c)){var d=$CLJS.aC.h(c);c=$CLJS.bC.h(c);d=$CLJS.n($CLJS.gh(d,b))?$CLJS.tC(b,d,c):null}else d=null;return d},a))};d4=function(a){a=$CLJS.C2(a);var b=$CLJS.uC(a);b||($CLJS.n(a)?(b=$CLJS.n(a)?!$CLJS.Gd($CLJS.q($CLJS.I2),$CLJS.C2(a).toLowerCase()):null,b=$CLJS.Sa(b)):b=null);return $CLJS.n(b)?a:uma($CLJS.Zb($CLJS.q($CLJS.E2)),a)};
$CLJS.e4=function(a,b,c,d){if($CLJS.z(c)){d=$CLJS.vk.g($CLJS.TX,d);var e=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h(function(f){return $CLJS.W2.v(a,b,f,c)}),d);return $CLJS.vk.g(function(f){return $CLJS.S.j(f,$CLJS.k0,$CLJS.Gd(e,f))},c)}return null};$CLJS.f4=function(a){a=$CLJS.j3.h(a);return $CLJS.n(a)?new $CLJS.h(null,2,[$CLJS.nv,$CLJS.SI,$CLJS.Sk,a],null):null};$CLJS.g4=function(a){return $CLJS.F.g($CLJS.SD(a),$CLJS.NM)};
vma=function(a,b){return $CLJS.n($CLJS.GG.h(a))?$CLJS.wk.j(a,$CLJS.GG,function(c){return $CLJS.vd(c)?$CLJS.vk.g(function(d){return $CLJS.h4.g?$CLJS.h4.g(d,b):$CLJS.h4.call(null,d,b)},c):c}):a};
i4=function(a){if($CLJS.n(a)){var b=$CLJS.bf;a:try{if($CLJS.yd(a)&&4===$CLJS.E(a))try{if($CLJS.bd(a,0)instanceof $CLJS.K)try{var c=$CLJS.bd(a,2);if($CLJS.yd(c)&&3===$CLJS.E(c))try{var d=$CLJS.bd(c,0);if($CLJS.fe(d,$CLJS.XH))try{var e=$CLJS.bd(a,3);if($CLJS.yd(e)&&3===$CLJS.E(e))try{var f=$CLJS.bd(e,0);if($CLJS.fe(f,$CLJS.XH)){$CLJS.bd(e,1);$CLJS.bd(e,2);$CLJS.bd(c,1);$CLJS.bd(c,2);$CLJS.bd(a,0);$CLJS.bd(a,1);var k=new $CLJS.Q(null,1,5,$CLJS.R,[!0],null)}else throw $CLJS.UW;}catch(m){if(m instanceof
Error){var l=m;if(l===$CLJS.UW)throw $CLJS.UW;throw l;}throw m;}else throw $CLJS.UW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.UW)throw $CLJS.UW;throw l;}throw m;}else throw $CLJS.UW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.UW)throw $CLJS.UW;throw l;}throw m;}else throw $CLJS.UW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.UW)throw $CLJS.UW;throw l;}throw m;}else throw $CLJS.UW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.UW)throw $CLJS.UW;throw l;}throw m;}else throw $CLJS.UW;
}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.UW){k=new $CLJS.Q(null,1,5,$CLJS.R,[!1],null);break a}throw l;}throw m;}a=$CLJS.B($CLJS.z(b($CLJS.Ta,k)))}else a=null;return a};j4=function(a){if($CLJS.n(i4(a))){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);$CLJS.H(a,3,null);return b}return null};k4=function(a){return $CLJS.n(i4(a))?($CLJS.H(a,0,null),$CLJS.H(a,1,null),$CLJS.H(a,2,null),$CLJS.H(a,3,null)):null};
l4=function(a,b){var c=$CLJS.h4;if($CLJS.Sa(i4(a)))return a;var d=$CLJS.H(a,0,null),e=$CLJS.H(a,1,null),f=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[d,e,f,$CLJS.P.j(c,a,b)],null)};
wma=function(a,b,c){return $CLJS.sd($CLJS.VM.h(a))?a:$CLJS.n(b)?tma(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.VM],null),function(d){return function l(f,k){try{if($CLJS.yd(k)&&3===$CLJS.E(k))try{var m=$CLJS.bd(k,0);if($CLJS.fe(m,$CLJS.XH))try{var t=$CLJS.bd(k,1);if(null!=t?t.C&256||$CLJS.r===t.kf||(t.C?0:$CLJS.Wa($CLJS.rb,t)):$CLJS.Wa($CLJS.rb,t))try{var u=$CLJS.I.j(t,$CLJS.WQ,$CLJS.eX);if($CLJS.F.g(u,b))return $CLJS.bd(k,2),$CLJS.h4.g?$CLJS.h4.g(k,c):$CLJS.h4.call(null,k,c);throw $CLJS.UW;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.UW)throw $CLJS.UW;throw v;}throw x;}else throw $CLJS.UW;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.UW)throw $CLJS.UW;throw v;}throw x;}else throw $CLJS.UW;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.UW)throw $CLJS.UW;throw v;}throw x;}else throw $CLJS.UW;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.UW)return $CLJS.zY(l,f,k);throw v;}throw x;}}($CLJS.tf,d)}):$CLJS.wk.j(a,$CLJS.VM,function(d){return $CLJS.vk.g(function(e){return l4(e,$CLJS.G([c]))},d)})};
$CLJS.h4=function(a,b){var c=$CLJS.SD(a);switch(c instanceof $CLJS.K?c.T:null){case "field":return $CLJS.vG(a,$CLJS.tG,$CLJS.G([$CLJS.WQ,b]));case "metadata/column":return $CLJS.tG(a,$CLJS.x0,b);case "mbql/join":return c=$CLJS.r2(a),wma(vma($CLJS.tG(a,$CLJS.KG,b),b),c,b);default:return b=$CLJS.zC($CLJS.tA),$CLJS.n($CLJS.yC("metabase.lib.join",b))&&$CLJS.xC("metabase.lib.join",b,$CLJS.fy.l($CLJS.G(["with-join-value should not be called with",$CLJS.Eh.l($CLJS.G([a]))])),null),a}};
$CLJS.m4=function(a,b,c){var d=$CLJS.S.l,e=$CLJS.pC($CLJS.X_,$CLJS.T)(c);a=$CLJS.s2($CLJS.KG.h(a),$CLJS.pC($CLJS.X_,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.S,c,$CLJS.X_,e,$CLJS.G([$CLJS.j0,b]))};xma=function(a,b){return $CLJS.Sa(b)?a:$CLJS.vk.g(function(c){var d=k4(c);d=$CLJS.n(d)?$CLJS.n($CLJS.r2(d))?null:l4(c,$CLJS.G([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.n4=function(a,b){b=xma($CLJS.vk.g($CLJS.UX,b),$CLJS.r2(a));return $CLJS.tG(a,$CLJS.VM,$CLJS.De(b))};
$CLJS.o4=function(a,b){b=b instanceof $CLJS.K?b:$CLJS.F.g(b,$CLJS.tf)?$CLJS.WB:$CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.tk.g($CLJS.Ze.h($CLJS.TX),function(){var c=$CLJS.r2(a);return $CLJS.n(c)?$CLJS.Ze.h(function(d){return $CLJS.h4(d,c)}):$CLJS.Rd}()),b));return $CLJS.tG(a,$CLJS.GG,b)};
yma=function(a,b){b=$CLJS.Ol(function(d){return $CLJS.W2.g(d,a)},b);var c=$CLJS.hJ($CLJS.pC($CLJS.a1,$CLJS.b1),b);if($CLJS.n(c))return c;b=$CLJS.B(b);if($CLJS.n(b))return b;b=$CLJS.hJ($CLJS.a1,a);if($CLJS.n(b))return b;b=$CLJS.hJ($CLJS.b1,a);return $CLJS.n(b)?b:$CLJS.B(a)};zma=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.tC(a,/ id$/i,"")):null};
Ama=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Bh(function(){return d4(b)});var d=new $CLJS.Bh(function(){return d4(a)});return $CLJS.F.g(a,b)||$CLJS.F.g(a,$CLJS.q(c))||$CLJS.F.g($CLJS.q(d),b)||$CLJS.F.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
Bma=function(a,b,c){var d=$CLJS.s1.g($CLJS.F.g($CLJS.nv.h(b),$CLJS.JX)?b:a,b),e=$CLJS.n(c)?zma($CLJS.s1.g(a,c)):null,f=Ama(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Sa($CLJS.fh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
Cma=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.c4(l,$CLJS.XH)&&$CLJS.Dd($CLJS.W2.v(a,b,l,e)))return $CLJS.h4(l,d);throw $CLJS.UW;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.UW)return $CLJS.zY(m,k,l);throw t;}throw u;}}($CLJS.tf,c)};
Dma=function(a,b,c,d,e,f){c=Cma(a,b,c,d,f);return function t(l,m){try{if($CLJS.yd(m)&&4===$CLJS.E(m))try{var u=$CLJS.bd(m,2);if($CLJS.c4(u,$CLJS.XH))try{var v=$CLJS.bd(m,3);if($CLJS.c4(v,$CLJS.XH)){var x=$CLJS.bd(m,3),A=$CLJS.bd(m,2),D=$CLJS.bd(m,0),J=$CLJS.bd(m,1),M=$CLJS.r2(A),V=$CLJS.r2(x);if($CLJS.Sa($CLJS.n(M)?M:V))return $CLJS.n($CLJS.W2.v(a,b,x,e))?new $CLJS.Q(null,4,5,$CLJS.R,[D,J,$CLJS.h4(A,d),x],null):new $CLJS.Q(null,4,5,$CLJS.R,[D,J,A,$CLJS.h4(x,d)],null);if($CLJS.F.g(M,d)&&$CLJS.F.g(V,
d)){var Z=$CLJS.vG(A,$CLJS.lk,$CLJS.G([$CLJS.WQ])),fa=$CLJS.vG(x,$CLJS.lk,$CLJS.G([$CLJS.WQ])),Da=null==$CLJS.W2.v(a,b,Z,e);var Ha=Da?$CLJS.W2.v(a,b,fa,e):Da;return $CLJS.n(Ha)?new $CLJS.Q(null,4,5,$CLJS.R,[D,J,A,fa],null):new $CLJS.Q(null,4,5,$CLJS.R,[D,J,Z,x],null)}return m}throw $CLJS.UW;}catch(Qb){if(Qb instanceof Error){var mb=Qb;if(mb===$CLJS.UW)throw $CLJS.UW;throw mb;}throw Qb;}else throw $CLJS.UW;}catch(Qb){if(Qb instanceof Error){mb=Qb;if(mb===$CLJS.UW)throw $CLJS.UW;throw mb;}throw Qb;
}else throw $CLJS.UW;}catch(Qb){if(Qb instanceof Error){mb=Qb;if(mb===$CLJS.UW)return $CLJS.zY(t,l,m);throw mb;}throw Qb;}}($CLJS.tf,c)};Ema=function(a,b){var c=$CLJS.kY();$CLJS.b4(c,b);return c(a)};
$CLJS.p4=function(a,b,c){if($CLJS.Gd(c,$CLJS.KG))return c;var d=$CLJS.hY(a,b),e=$CLJS.a2.j(a,b,d),f=$CLJS.z($CLJS.bf($CLJS.Ta,function v(t,u){try{if($CLJS.yd(u)&&1<=$CLJS.E(u))try{var x=$CLJS.Ak.j(u,0,1);if($CLJS.yd(x)&&1===$CLJS.E(x))try{var A=$CLJS.bd(x,0);if($CLJS.fe(A,$CLJS.XH))return new $CLJS.Q(null,1,5,$CLJS.R,[u],null);throw $CLJS.UW;}catch(J){if(J instanceof Error){var D=J;if(D===$CLJS.UW)throw $CLJS.UW;throw D;}throw J;}else throw $CLJS.UW;}catch(J){if(J instanceof Error){D=J;if(D===$CLJS.UW)throw $CLJS.UW;
throw D;}throw J;}else throw $CLJS.UW;}catch(J){if(J instanceof Error){D=J;if(D===$CLJS.UW)return $CLJS.cX(v,t,u);throw D;}throw J;}}($CLJS.tf,$CLJS.VM.h(c))));f=yma(e,f);var k=Ema(Bma(a,c,f),$CLJS.Ol($CLJS.KG,$CLJS.fP.h(d))),l=$CLJS.Z1.h($CLJS.l2.g(a,$CLJS.SM.h(c)));return $CLJS.h4($CLJS.wk.j(c,$CLJS.VM,function(t){return $CLJS.vk.g(function(u){return Dma(a,b,u,k,e,l)},t)}),k)};$CLJS.q4=function(a){return $CLJS.VM.h(a)};
$CLJS.r4=function(a){return $CLJS.Lk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.nv,$CLJS.WM,$CLJS.rG,a],null),$CLJS.F.g(a,$CLJS.QG)?new $CLJS.h(null,1,[$CLJS.Wh,!0],null):null]))};$CLJS.s4=function(a,b){b=$CLJS.B($CLJS.SM.h(b));return $CLJS.n($CLJS.pX.h(b))?$CLJS.q1(a,$CLJS.pX.h(b)):$CLJS.n($CLJS.HQ.h(b))?$CLJS.o1(a,$CLJS.HQ.h(b)):null};
v4=function(a){a=$CLJS.gJ(function(d){return $CLJS.b1(d)?t4:$CLJS.a1(d)?u4:$CLJS.fv},a);var b=$CLJS.O(a);a=$CLJS.I.g(b,t4);var c=$CLJS.I.g(b,u4);b=$CLJS.I.g(b,$CLJS.fv);return $CLJS.Ye.l(a,c,$CLJS.G([b]))};w4=function(a,b,c,d){return $CLJS.Sa(c)?d:$CLJS.vk.g(function(e){return $CLJS.n($CLJS.k0.h(e))?$CLJS.h3(e,$CLJS.f4(c)):e},$CLJS.e4(a,b,d,new $CLJS.Q(null,1,5,$CLJS.R,[c],null)))};x4=function(a,b,c,d){return $CLJS.hJ($CLJS.b1,$CLJS.a2.v(a,b,c,d))};
y4=function(a,b){var c=$CLJS.Ki.h(a);return $CLJS.n(c)?$CLJS.hJ(function(d){d=$CLJS.O(d);var e=$CLJS.I.g(d,$CLJS.jG);return $CLJS.a1(d)&&$CLJS.F.g(e,c)},b):null};Fma={};Gma=new $CLJS.K("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);u4=new $CLJS.K(null,"fk","fk",398442651);t4=new $CLJS.K(null,"pk","pk",-771936732);Hma=new $CLJS.w("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);$CLJS.Q1.m(null,$CLJS.NM,function(a,b,c){b=$CLJS.O(c);b=$CLJS.I.g(b,$CLJS.SM);b=$CLJS.H(b,0,null);c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.HQ);c=$CLJS.I.g(c,$CLJS.pX);a=$CLJS.n(b)?$CLJS.aG.h($CLJS.o1(a,b)):null;if($CLJS.n(a))return a;a=$CLJS.n(c)?$CLJS.w2(c):null;return $CLJS.n(a)?a:$CLJS.yG("Native Query")});$CLJS.X1.m(null,$CLJS.NM,function(a,b,c){a=$CLJS.s1.j(a,b,c);c=$CLJS.KG.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.aG,a],null)});
$CLJS.W1.m(null,$CLJS.NM,function(){throw $CLJS.ai("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.Q1.m(null,$CLJS.WM,function(a,b,c){a=$CLJS.O(c);a=$CLJS.I.g(a,$CLJS.rG);a=a instanceof $CLJS.K?a.T:null;switch(a){case "left-join":return $CLJS.yG("Left outer join");case "right-join":return $CLJS.yG("Right outer join");case "inner-join":return $CLJS.yG("Inner join");case "full-join":return $CLJS.yG("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.X1.m(null,$CLJS.WM,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.I.g(d,$CLJS.rG);c=$CLJS.I.g(d,$CLJS.Wh);a=new $CLJS.h(null,2,[$CLJS.N1,$CLJS.NC(e),$CLJS.aG,$CLJS.s1.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.S.j(a,$CLJS.Wh,!0):a});
$CLJS.Y1.m(null,$CLJS.NM,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.I.g(e,$CLJS.KG),k=$CLJS.I.j(e,$CLJS.GG,$CLJS.WB),l=$CLJS.I.g(e,$CLJS.SM);c=$CLJS.O(d);var m=$CLJS.I.g(c,$CLJS.K1);if($CLJS.F.g(k,$CLJS.WB))return null;var t="undefined"!==typeof $CLJS.GN&&"undefined"!==typeof Fma&&"undefined"!==typeof $CLJS.Ima&&"undefined"!==typeof $CLJS.z4?new $CLJS.Fc(function(){return $CLJS.z4},$CLJS.od(Hma,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),null):null,u=function(){var v=$CLJS.S.j(a,$CLJS.SM,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.F.g(k,$CLJS.nA)?$CLJS.Z1.v(u,-1,$CLJS.qd(l),c):function(){return function A(x){return new $CLJS.le(null,function(){for(;;){var D=$CLJS.z(x);if(D){if($CLJS.zd(D)){var J=$CLJS.kc(D),M=$CLJS.E(J),V=$CLJS.oe(M);a:for(var Z=0;;)if(Z<M){var fa=$CLJS.kd(J,Z);fa=$CLJS.vG(fa,$CLJS.lk,$CLJS.G([$CLJS.WQ]));fa=$CLJS.r1.j(u,-1,fa);V.add(fa);Z+=1}else{J=!0;break a}return J?$CLJS.re($CLJS.te(V),A($CLJS.lc(D))):$CLJS.re($CLJS.te(V),null)}V=$CLJS.B(D);V=$CLJS.vG(V,$CLJS.lk,
$CLJS.G([$CLJS.WQ]));return $CLJS.ce($CLJS.r1.j(u,-1,V),A($CLJS.Kc(D)))}return null}},null,null)}(k)}();return $CLJS.vk.g(function(v){v=$CLJS.S.j(v,$CLJS.v0,f);v=$CLJS.h4($CLJS.S.l(v,$CLJS.aG,$CLJS.s1.j(a,b,v),$CLJS.G([$CLJS.tM,$CLJS.g0])),f);return $CLJS.m4(e,m,v)},c)});$CLJS.$1.m(null,$CLJS.NM,function(a,b,c,d){return $CLJS.Z1.v(a,b,$CLJS.S.j(c,$CLJS.GG,$CLJS.nA),d)});
var A4,Jma=$CLJS.Oe($CLJS.N),Kma=$CLJS.Oe($CLJS.N),Lma=$CLJS.Oe($CLJS.N),Mma=$CLJS.Oe($CLJS.N),Nma=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.UD],null),$CLJS.Ti,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));A4=new $CLJS.Xh($CLJS.xh.g("metabase.lib.join","join-clause-method"),$CLJS.SD,Nma,Jma,Kma,Lma,Mma);A4.m(null,$CLJS.NM,function(a){return a});A4.m(null,$CLJS.JX,function(a){return $CLJS.SX(new $CLJS.h(null,2,[$CLJS.nv,$CLJS.NM,$CLJS.SM,$CLJS.SM.h($CLJS.fY(a))],null))});
A4.m(null,$CLJS.wX,function(a){return $CLJS.SX(new $CLJS.h(null,2,[$CLJS.nv,$CLJS.NM,$CLJS.SM,new $CLJS.Q(null,1,5,$CLJS.R,[a],null)],null))});A4.m(null,$CLJS.KM,function(a){return $CLJS.SX(new $CLJS.h(null,2,[$CLJS.nv,$CLJS.NM,$CLJS.SM,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.pX,$CLJS.Ki.h(a),$CLJS.nv,$CLJS.wX],null)],null)],null))});
A4.m(null,$CLJS.yM,function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.x0);a=$CLJS.I.g(b,Gma);b=A4.h(new $CLJS.h(null,3,[$CLJS.nv,$CLJS.wX,$CLJS.hD,new $CLJS.h(null,1,[$CLJS.mG,$CLJS.p.h($CLJS.xG())],null),$CLJS.HQ,$CLJS.Ki.h(b)],null));c=$CLJS.n(c)?$CLJS.h4(b,c):b;return $CLJS.n(a)?$CLJS.o4.g?$CLJS.o4.g(c,a):$CLJS.o4.call(null,c,a):c});
$CLJS.B4=function(){function a(d,e){return $CLJS.n4(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.c3(A4.h(d),$CLJS.GG,$CLJS.nA)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Oma=function(){function a(d,e,f){var k=$CLJS.B4.h(f);f=$CLJS.sd($CLJS.q4.h?$CLJS.q4.h(k):$CLJS.q4.call(null,k))?function(){var m=$CLJS.s4.g?$CLJS.s4.g(d,k):$CLJS.s4.call(null,d,k);return $CLJS.C4.j?$CLJS.C4.j(d,e,m):$CLJS.C4.call(null,d,e,m)}():null;f=$CLJS.n(f)?$CLJS.n4(k,new $CLJS.Q(null,1,5,$CLJS.R,[f],null)):k;var l=$CLJS.p4(d,e,f);return $CLJS.qY.l(d,e,$CLJS.wk,$CLJS.G([$CLJS.fP,function(m){return $CLJS.$d.g($CLJS.uf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}
var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.D4=function(){function a(d,e){return $CLJS.De($CLJS.I.g($CLJS.hY(d,e),$CLJS.fP))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Pma=function(){function a(d){d=$CLJS.h2($CLJS.n1(d));d=$CLJS.XB.h(d);return $CLJS.Wf.j($CLJS.tf,$CLJS.tk.g($CLJS.af($CLJS.Me($CLJS.Gd,d)),$CLJS.Ze.h($CLJS.r4)),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.QG,$CLJS.FG,$CLJS.UG,$CLJS.XG],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Qma=function(){function a(d,e,f,k){var l=$CLJS.g4(f)?$CLJS.r2(f):null,m=$CLJS.Wf.j($CLJS.Ug,$CLJS.tk.g($CLJS.Ze.h($CLJS.r2),sma(function(t){return $CLJS.rk.g(t,l)})),$CLJS.D4.g(d,e));f=$CLJS.n(k)?k:$CLJS.g4(f)?j4($CLJS.B($CLJS.q4(f))):null;return v4(w4(d,e,f,$CLJS.uk.g(function(t){t=$CLJS.r2(t);return $CLJS.n(t)?$CLJS.Gd(m,t):null},$CLJS.a2.v(d,e,$CLJS.hY(d,e),new $CLJS.h(null,1,[$CLJS.F1,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.Rma=function(){function a(d,e,f,k,l){k=$CLJS.g4(f)?$CLJS.s4(d,f):f;var m=$CLJS.g4(f)?$CLJS.r2(f):null;f=$CLJS.n(l)?l:$CLJS.g4(f)?k4($CLJS.B($CLJS.q4(f))):null;f=$CLJS.n(f)?$CLJS.Sa(m)?$CLJS.vG(f,$CLJS.lk,$CLJS.G([$CLJS.WQ])):f:null;return v4(w4(d,e,f,$CLJS.Ze.g(function(t){t=$CLJS.S.j(t,$CLJS.tM,$CLJS.g0);return $CLJS.n(m)?$CLJS.h4(t,m):t},$CLJS.a2.v(d,e,k,new $CLJS.h(null,1,[$CLJS.F1,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.Sma=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.a3}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.a3};return b}();
$CLJS.C4=function(){function a(d,e,f){function k(u,v){return $CLJS.a4.l($CLJS.Z2.h($CLJS.Fs),u,$CLJS.G([v]))}var l=new $CLJS.h(null,2,[$CLJS.F1,!1,$CLJS.M1,!1],null),m=function(){var u=x4(d,e,f,l);if($CLJS.n(u)){if($CLJS.n(u)){var v=$CLJS.a2.j(d,e,$CLJS.hY(d,e));v=y4(u,v)}else v=null;return $CLJS.n(v)?k(v,u):null}return null}();if($CLJS.n(m))return m;m=x4(d,e,$CLJS.hY(d,e),null);if($CLJS.n(m)){var t=y4(m,$CLJS.a2.v(d,e,f,l));return $CLJS.n(t)?k(m,t):null}return null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Tma=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.g4(k)?j4($CLJS.B($CLJS.q4(k))):null;$CLJS.n(l)?(l=$CLJS.v1.j(e,f,l),l=$CLJS.qC(l,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pY,$CLJS.aG],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.gY(e,f)){l=$CLJS.D4.g(e,f);var m=$CLJS.sd(l);k=m?m:$CLJS.g4(k)?$CLJS.F.g($CLJS.KG.h(k),$CLJS.KG.h($CLJS.B(l))):null;k=$CLJS.n(k)?$CLJS.j1(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.j1(e),k=$CLJS.o1(e,k),e=$CLJS.s1.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.yG("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Uma=function(){function a(d,e,f,k){f=$CLJS.UX.h(f);$CLJS.H(f,0,null);$CLJS.H(f,1,null);var l=$CLJS.H(f,2,null),m=$CLJS.H(f,3,null);k=k instanceof $CLJS.K?k:$CLJS.Sk.h(k);e=$CLJS.gY(d,e);l=$CLJS.m3.j(d,e,l);d=$CLJS.m3.j(d,e,m);m=null==k||$CLJS.Gd($CLJS.Yg($CLJS.Ze.g($CLJS.Sk,l)),k);d=null==k||$CLJS.Gd($CLJS.Yg($CLJS.Ze.g($CLJS.Sk,d)),k);f=m?$CLJS.wk.v(f,2,$CLJS.h3,k):f;return d?$CLJS.wk.v(f,3,$CLJS.h3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.T1.m(null,$CLJS.fP,function(a,b){var c=$CLJS.De($CLJS.D4.g(a,b));c=null==c?null:$CLJS.Ze.g(function(d){return $CLJS.s1.j(a,b,d)},c);return null==c?null:$CLJS.Fu(" + ",c)});
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Bqa,Cqa,N8,Dqa,Eqa,Fqa,Gqa,Hqa,Iqa,Jqa,O8,P8,Q8,Kqa,R8;
Bqa=function(a){return function(b){var c=$CLJS.Re($CLJS.Ug);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Gd($CLJS.q(c),t))return l;c.Cd(null,$CLJS.$d.g(c.Qb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};Cqa=function(a,b,c){return $CLJS.Wf.j($CLJS.tf,$CLJS.VF(function(d){return $CLJS.a2.v(a,b,d,new $CLJS.h(null,2,[$CLJS.K1,c,$CLJS.F1,!1],null))}),$CLJS.fP.h($CLJS.hY(a,b)))};N8=function(a,b,c){return $CLJS.Wf.j($CLJS.tf,$CLJS.VF(function(d){return $CLJS.Z1.v(a,b,d,c)}),$CLJS.fP.h($CLJS.hY(a,b)))};$CLJS.z4=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.qY.l(c,d,$CLJS.S,$CLJS.G([$CLJS.W7,$CLJS.Z1.j(c,d,$CLJS.hY(c,d))]))},a,$CLJS.Nv(0,$CLJS.gY(a,b)))};
Dqa=function(a,b){a=$CLJS.hY(a,b);a=$CLJS.O(a);var c=$CLJS.I.g(a,$CLJS.nv),d=$CLJS.I.g(a,$CLJS.pX);b=$CLJS.W7.h(a);if($CLJS.n(b))return b;var e=$CLJS.PW.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.F.g(c,$CLJS.DX);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.K?c.T:null;switch(k){case "mbql.stage/native":return $CLJS.s0;case "mbql.stage/mbql":return $CLJS.m0;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.De(function(){return function m(l){return new $CLJS.le(null,
function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.kd(u,A);D=$CLJS.Lk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.X_,$CLJS.T.h(D),$CLJS.j0,$CLJS.T.h(D)],null),D,new $CLJS.h(null,1,[$CLJS.tM,f],null)]));x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.Lk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.X_,$CLJS.T.h(x),$CLJS.j0,$CLJS.T.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.tM,f],null)])),m($CLJS.Kc(t)))}return null}},null,null)}($CLJS.eY.h(e))}())}return null};
Eqa=function(a,b,c){return $CLJS.De(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t,D=$CLJS.S,J=D.l,M=x,V=$CLJS.pC($CLJS.X_,$CLJS.T)(x);x=$CLJS.t2(a,x);x=c.h?c.h(x):c.call(null,x);D=J.call(D,M,$CLJS.tM,$CLJS.GM,$CLJS.G([$CLJS.X_,V,$CLJS.j0,x]));A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k);return $CLJS.ce($CLJS.S.l(u,$CLJS.tM,$CLJS.GM,$CLJS.G([$CLJS.X_,$CLJS.pC($CLJS.X_,$CLJS.T)(u),$CLJS.j0,function(){var v=$CLJS.t2(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.V5(a,b))}())};
Fqa=function(a,b,c){return $CLJS.De(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t,D=$CLJS.S,J=D.l,M=x,V=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);D=J.call(D,M,$CLJS.tM,$CLJS.LM,$CLJS.G([$CLJS.X_,V,$CLJS.j0,x]));A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}var u=
$CLJS.B(k);return $CLJS.ce($CLJS.S.l(u,$CLJS.tM,$CLJS.LM,$CLJS.G([$CLJS.X_,$CLJS.T.h(u),$CLJS.j0,function(){var v=$CLJS.T.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.h5.g(a,b))}())};
Gqa=function(a,b,c){var d=$CLJS.hY(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.I.g(d,$CLJS.GG);return $CLJS.De(function(){return function l(k){return new $CLJS.le(null,function(){for(;;){var m=$CLJS.z(k);if(m){if($CLJS.zd(m)){var t=$CLJS.kc(m),u=$CLJS.E(t),v=$CLJS.oe(u);return function(){for(var M=0;;)if(M<u){var V=$CLJS.kd(t,M),Z=$CLJS.H(V,0,null),fa=function(){var Ha=Z;Ha=Ha instanceof $CLJS.K?Ha.T:null;switch(Ha){case "field":return $CLJS.r0;case "expression":return $CLJS.d0;default:throw Error(["No matching clause: ",
$CLJS.p.h(Ha)].join(""));}}(),Da=$CLJS.r1.j(a,b,V);$CLJS.se(v,$CLJS.S.l(Da,$CLJS.tM,fa,$CLJS.G([$CLJS.X_,$CLJS.S1.j(a,b,Da),$CLJS.j0,function(){var Ha=$CLJS.t2(a,Da);return c.h?c.h(Ha):c.call(null,Ha)}()])));M+=1}else return!0}()?$CLJS.re($CLJS.te(v),l($CLJS.lc(m))):$CLJS.re($CLJS.te(v),null)}var x=$CLJS.B(m),A=$CLJS.H(x,0,null),D=function(){var M=A;M=M instanceof $CLJS.K?M.T:null;switch(M){case "field":return $CLJS.r0;case "expression":return $CLJS.d0;default:throw Error(["No matching clause: ",
$CLJS.p.h(M)].join(""));}}(),J=$CLJS.r1.j(a,b,x);return $CLJS.ce($CLJS.S.l(J,$CLJS.tM,D,$CLJS.G([$CLJS.X_,$CLJS.S1.j(a,b,J),$CLJS.j0,function(){var M=$CLJS.t2(a,J);return c.h?c.h(M):c.call(null,M)}()])),l($CLJS.Kc(m)))}return null}},null,null)}(e)}())}return null};Hqa=function(a,b,c){return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.VF(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.Q(null,2,5,$CLJS.R,[Eqa,Fqa],null)))};
Iqa=function(a,b,c){var d=$CLJS.N2(a,b);return $CLJS.n(d)?$CLJS.De(function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.oe(t);return function(){for(var A=0;;)if(A<t){var D=$CLJS.kd(m,A);var J=$CLJS.pC($CLJS.j0,$CLJS.X_)(D);J=$CLJS.n(J)?J:$CLJS.S1.j(a,b,D);D=$CLJS.lk.g($CLJS.Lk.l($CLJS.G([D,new $CLJS.h(null,3,[$CLJS.tM,$CLJS.Y_,$CLJS.X_,J,$CLJS.j0,c.h?c.h(J):c.call(null,J)],null),$CLJS.n($CLJS.y2.h(D))?
$CLJS.Ql(D,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.y2],null)):null])),$CLJS.Y7);u.add(D);A+=1}else return!0}()?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}var v=$CLJS.B(l),x=function(){var A=$CLJS.pC($CLJS.j0,$CLJS.X_)(v);return $CLJS.n(A)?A:$CLJS.S1.j(a,b,v)}();return $CLJS.ce($CLJS.lk.g($CLJS.Lk.l($CLJS.G([v,new $CLJS.h(null,3,[$CLJS.tM,$CLJS.Y_,$CLJS.X_,x,$CLJS.j0,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.y2.h(v))?$CLJS.Ql(v,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.y2],null)):null])),
$CLJS.Y7),k($CLJS.Kc(l)))}return null}},null,null)}($CLJS.Z1.j(a,d,$CLJS.hY(a,d)))}()):null};Jqa=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.q1(a,c),$CLJS.n(c)?$CLJS.De($CLJS.a2.v(a,b,c,d)):null):null};
O8=function(a,b,c){return $CLJS.De(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t;var D=$CLJS.gD.h(x);var J=$CLJS.S.l,M=x,V=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);D=$CLJS.c3(J.call($CLJS.S,M,$CLJS.tM,$CLJS.d0,$CLJS.G([$CLJS.X_,V,$CLJS.j0,x])),$CLJS.ti,$CLJS.n(D)?D:$CLJS.lj);A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),
f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}var u=$CLJS.B(k);return $CLJS.ce(function(){var v=$CLJS.gD.h(u),x=$CLJS.S.l,A=u,D=$CLJS.T.h(u);var J=$CLJS.T.h(u);J=c.h?c.h(J):c.call(null,J);return $CLJS.c3(x.call($CLJS.S,A,$CLJS.tM,$CLJS.d0,$CLJS.G([$CLJS.X_,D,$CLJS.j0,J])),$CLJS.ti,$CLJS.n(v)?v:$CLJS.lj)}(),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.Moa.g(a,b))}())};
P8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.I.g(d,$CLJS.K1);return $CLJS.vk.g(function(f){return $CLJS.lk.l(f,$CLJS.x0,$CLJS.G([$CLJS.Y7,$CLJS.X7,$CLJS.DM]))},function(){var f=Iqa(a,b,e);if($CLJS.n(f))return f;f=$CLJS.hY(a,b);f=$CLJS.O(f);var k=$CLJS.I.g(f,$CLJS.HQ),l=$CLJS.I.g(f,$CLJS.pX),m=$CLJS.n(k)?function(){var t=$CLJS.o1(a,k);return $CLJS.a2.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?Jqa(a,b,l,$CLJS.S.j(d,$CLJS.F1,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.le(null,
function(){for(;;){var x=$CLJS.z(u);if(x){if($CLJS.zd(x)){var A=$CLJS.kc(x),D=$CLJS.E(A),J=$CLJS.oe(D);return function(){for(var V=0;;)if(V<D){var Z=$CLJS.kd(A,V),fa=J,Da=$CLJS.S,Ha=Da.l,mb=Z,Qb=$CLJS.T.h(Z);Z=$CLJS.T.h(Z);Z=e.h?e.h(Z):e.call(null,Z);Da=Ha.call(Da,mb,$CLJS.tM,$CLJS.s0,$CLJS.G([$CLJS.X_,Qb,$CLJS.j0,Z]));fa.add(Da);V+=1}else return!0}()?$CLJS.re($CLJS.te(J),v($CLJS.lc(x))):$CLJS.re($CLJS.te(J),null)}var M=$CLJS.B(x);return $CLJS.ce($CLJS.S.l(M,$CLJS.tM,$CLJS.s0,$CLJS.G([$CLJS.X_,$CLJS.T.h(M),
$CLJS.j0,function(){var V=$CLJS.T.h(M);return e.h?e.h(V):e.call(null,V)}()])),v($CLJS.Kc(x)))}return null}},null,null)}($CLJS.eY.h($CLJS.PW.h(f)))}())};
Q8=function(a,b){var c=$CLJS.H(a,0,null);$CLJS.H(a,1,null);var d=$CLJS.H(a,2,null);switch(c instanceof $CLJS.K?c.T:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.F.g(d,$CLJS.Ki.h(b));throw $CLJS.ai("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.BT,a,$CLJS.Fi,b],null));case "expression":return $CLJS.F.g(d,$CLJS.T.h(b));default:throw $CLJS.ai("unknown clause in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.BT,a,$CLJS.Fi,b],null));}};
Kqa=function(a,b,c){var d=$CLJS.LH.h($CLJS.hY(a,b));return $CLJS.n(d)?function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){var m=l;if($CLJS.zd(m)){var t=$CLJS.kc(m),u=$CLJS.E(t),v=$CLJS.oe(u);return function(){for(var A=0;;)if(A<u){var D=$CLJS.kd(t,A);$CLJS.se(v,function(){var J=$CLJS.hJ(function(Z,fa){return function(Da){return Q8(Da,fa)}}(A,D,t,u,v,m,l,d,d),d);if($CLJS.n(J)){var M=$CLJS.Q7(J);J=$CLJS.f4(J);J=$CLJS.O(J);J=$CLJS.I.g(J,$CLJS.Sk);var V=D;M=$CLJS.n(M)?$CLJS.N5(V,
M):V;return $CLJS.n(J)?$CLJS.h3(M,J):M}return D}());A+=1}else return!0}()?$CLJS.re($CLJS.te(v),k($CLJS.lc(m))):$CLJS.re($CLJS.te(v),null)}var x=$CLJS.B(m);return $CLJS.ce(function(){var A=$CLJS.hJ(function(M){return function(V){return Q8(V,M)}}(x,m,l,d,d),d);if($CLJS.n(A)){var D=$CLJS.Q7(A);A=$CLJS.f4(A);A=$CLJS.O(A);A=$CLJS.I.g(A,$CLJS.Sk);var J=x;D=$CLJS.n(D)?$CLJS.N5(J,D):J;return $CLJS.n(A)?$CLJS.h3(D,A):D}return x}(),k($CLJS.Kc(m)))}return null}},null,null)}(c):c};
R8=new $CLJS.K("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.Ima={};$CLJS.fH($CLJS.wX,R8);$CLJS.fH($CLJS.DX,R8);$CLJS.e2.m(null,$CLJS.wX,function(a){return $CLJS.d2(a,new $CLJS.h(null,2,[$CLJS.MH,$CLJS.Me($CLJS.vk,$CLJS.e2),$CLJS.LX,$CLJS.Me($CLJS.vk,$CLJS.e2)],null))});$CLJS.W1.m(null,R8,function(){throw $CLJS.ai("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.$1.m(null,R8,function(a,b,c,d){d=$CLJS.O(d);c=$CLJS.I.g(d,$CLJS.K1);var e=$CLJS.I.g(d,$CLJS.F1);a=$CLJS.z4(a,b);var f=$CLJS.O(d);var k=$CLJS.I.g(f,$CLJS.K1),l=$CLJS.I.g(f,$CLJS.I1),m=$CLJS.I.g(f,$CLJS.P1);f=$CLJS.Ye.l(P8(a,b,f),$CLJS.n(m)?O8(a,b,k):null,$CLJS.G([$CLJS.n(l)?Cqa(a,b,k):null]));k=$CLJS.Ye.g;d=$CLJS.n(e)?(e=$CLJS.Sa($CLJS.pX.h($CLJS.hY(a,b))))?e:$CLJS.M1.h(d):e;return Kqa(a,b,k.call($CLJS.Ye,f,$CLJS.n(d)?$CLJS.u2(a,b,f,c):null))});
$CLJS.Y1.m(null,R8,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.I.g(c,$CLJS.K1);var e=Dqa(a,b);if($CLJS.n(e))return e;a=$CLJS.z4(a,b);e=Hqa(a,b,d);var f=Gqa(a,b,d);return $CLJS.n(e)?$CLJS.Wf.g(e,f):$CLJS.n(f)?($CLJS.eh(f),$CLJS.Wf.j($CLJS.tf,Bqa(function(k){return $CLJS.lk.l(k,$CLJS.v0,$CLJS.G([$CLJS.tM,$CLJS.O1,$CLJS.j0]))}),$CLJS.Ye.g(f,N8(a,b,c)))):$CLJS.Ye.l(P8(a,b,new $CLJS.h(null,2,[$CLJS.F1,!1,$CLJS.K1,d],null)),O8(a,b,d),$CLJS.G([N8(a,b,c)]))});$CLJS.Q1.m(null,$CLJS.DX,function(){return $CLJS.yG("Native query")});
var S8=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HQ,$CLJS.pX,$CLJS.fP],null),T8=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.MH,$CLJS.LH,$CLJS.LX,$CLJS.qT,$CLJS.NT],null);
$CLJS.Q1.m(null,$CLJS.wX,function(a,b,c,d){var e=$CLJS.z4(a,b);a=$CLJS.De(function(){var f=$CLJS.Wf.j($CLJS.N,$CLJS.tk.g($CLJS.eE,$CLJS.Ze.h(function(l){return new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.bla.j(e,b,l)],null)})),new $CLJS.Q(null,2,5,$CLJS.R,[S8,T8],null)),k=$CLJS.Fu(" + ",$CLJS.uk.g($CLJS.uC,$CLJS.Ze.g(f,S8)));f=$CLJS.Ze.g(f,T8);return $CLJS.Fu(", ",$CLJS.uk.g($CLJS.uC,$CLJS.ce(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.N2(e,b);return $CLJS.n(a)?$CLJS.s1.v(e,a,$CLJS.hY(e,a),d):null});
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var tK,vK,xK,JK,NK,VK;$CLJS.sK=new $CLJS.K(null,"get-month","get-month",-369374731);tK=new $CLJS.K("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.uK=new $CLJS.K(null,"convert-timezone","convert-timezone",-124803001);vK=new $CLJS.K("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.wK=new $CLJS.K(null,"iso","iso",-1366207543);xK=new $CLJS.K("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.yK=new $CLJS.K(null,"get-second","get-second",-2065946318);$CLJS.zK=new $CLJS.K(null,"get-year","get-year",-936011274);$CLJS.AK=new $CLJS.K(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.BK=new $CLJS.K("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.CK=new $CLJS.K(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.DK=new $CLJS.K(null,"datetime-diff","datetime-diff",1133112155);$CLJS.EK=new $CLJS.K(null,"interval","interval",1708495417);$CLJS.FK=new $CLJS.K(null,"us","us",746429226);$CLJS.GK=new $CLJS.K(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.HK=new $CLJS.K(null,"datetime","datetime",494675702);$CLJS.IK=new $CLJS.K(null,"now","now",-1650525531);JK=new $CLJS.K("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.KK=new $CLJS.K(null,"instance","instance",-2121349050);$CLJS.LK=new $CLJS.K(null,"get-day","get-day",127568857);$CLJS.MK=new $CLJS.K(null,"get-week","get-week",-888059349);
NK=new $CLJS.K("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.OK=new $CLJS.K(null,"datetime-add","datetime-add",209603411);$CLJS.PK=new $CLJS.K(null,"get-minute","get-minute",1468086343);$CLJS.QK=new $CLJS.K(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.RK=new $CLJS.K("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.SK=new $CLJS.K(null,"temporal-extract","temporal-extract",1781686405);$CLJS.TK=new $CLJS.K(null,"get-hour","get-hour",622714059);$CLJS.UK=new $CLJS.K(null,"datetime-subtract","datetime-subtract",275307697);VK=new $CLJS.K("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.WK=new $CLJS.K(null,"get-quarter","get-quarter",1327778593);$CLJS.XK=new $CLJS.K(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.YK=new $CLJS.K(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.ZK=new $CLJS.K(null,"target","target",253001721);$CLJS.mH($CLJS.EK,$CLJS.G([$CLJS.ku,$CLJS.RE,$CLJS.st,$CLJS.TF]));$CLJS.AG.m(null,$CLJS.BK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.BG(a);return $CLJS.ud(a)?(a=$CLJS.zv.g(a,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.GE,null,$CLJS.eF,null],null),null)),$CLJS.F.g($CLJS.E(a),1)?$CLJS.B(a):a):a});$CLJS.fH($CLJS.BK,$CLJS.SG);
for(var $K=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OK,$CLJS.UK],null)),aL=null,bL=0,cL=0;;)if(cL<bL){var dL=aL.X(null,cL);$CLJS.mH(dL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null),$CLJS.st,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TF],null)]));$CLJS.fH(dL,$CLJS.BK);cL+=1}else{var eL=$CLJS.z($K);if(eL){var fL=eL;if($CLJS.zd(fL)){var gL=$CLJS.kc(fL),Gea=$CLJS.lc(fL),Hea=gL,Iea=$CLJS.E(gL);$K=Gea;aL=Hea;bL=Iea}else{var hL=$CLJS.B(fL);$CLJS.mH(hL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.JG],null),$CLJS.st,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TF],null)]));$CLJS.fH(hL,$CLJS.BK);$K=$CLJS.C(fL);aL=null;bL=0}cL=0}else break}
for(var iL=$CLJS.z(new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.zK,$CLJS.sK,$CLJS.LK,$CLJS.TK,$CLJS.PK,$CLJS.yK,$CLJS.WK],null)),jL=null,kL=0,lL=0;;)if(lL<kL){var Jea=jL.X(null,lL);$CLJS.mH(Jea,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null)]));lL+=1}else{var mL=$CLJS.z(iL);if(mL){var nL=mL;if($CLJS.zd(nL)){var oL=$CLJS.kc(nL),Kea=$CLJS.lc(nL),Lea=oL,Mea=$CLJS.E(oL);iL=Kea;jL=Lea;kL=Mea}else{var Nea=$CLJS.B(nL);$CLJS.mH(Nea,$CLJS.G([$CLJS.ku,
$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null)]));iL=$CLJS.C(nL);jL=null;kL=0}lL=0}else break}$CLJS.mH($CLJS.DK,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PI],null)]));
for(var pL=$CLJS.z(new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.WK,null,$CLJS.PK,null,$CLJS.TK,null,$CLJS.QK,null,$CLJS.yK,null,$CLJS.sK,null,$CLJS.zK,null,$CLJS.LK,null],null),null)),qL=null,rL=0,sL=0;;)if(sL<rL){var Oea=qL.X(null,sL);$CLJS.mH(Oea,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null)]));sL+=1}else{var tL=$CLJS.z(pL);if(tL){var uL=tL;if($CLJS.zd(uL)){var vL=$CLJS.kc(uL),Pea=$CLJS.lc(uL),Qea=vL,Rea=$CLJS.E(vL);
pL=Pea;qL=Qea;rL=Rea}else{var Sea=$CLJS.B(uL);$CLJS.mH(Sea,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null)]));pL=$CLJS.C(uL);qL=null;rL=0}sL=0}else break}$CLJS.Y(VK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bt,$CLJS.wK,$CLJS.FK,$CLJS.KK],null));
$CLJS.oH($CLJS.MK,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ni,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ks,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VK],null)],null)],null)],null)]));
$CLJS.Y($CLJS.RK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,$CLJS.fG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,2,[$CLJS.uu,"valid timezone ID",$CLJS.wu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.oj);return["invalid timezone ID: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.JI($CLJS.Hd,$CLJS.rK.tz.names())),$CLJS.dK],null)],null));
$CLJS.oH($CLJS.uK,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.RK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RB,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ks,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.RK],null)],null)],null)],
null)]));$CLJS.fH($CLJS.uK,$CLJS.BK);$CLJS.mH($CLJS.IK,$CLJS.G([$CLJS.ku,$CLJS.JE]));$CLJS.Y(xK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Je(function(b){return $CLJS.fE(a,b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GE,$CLJS.eF],null))}],null)],null));
$CLJS.Y(tK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gD,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,xK],null)],null)],null)],null));
$CLJS.yI.g($CLJS.AK,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ss,new $CLJS.h(null,1,[$CLJS.uu,"valid :absolute-datetime clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.AK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Us,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ss,new $CLJS.h(null,1,[$CLJS.uu,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.jK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.bK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.RI],null)],null)],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ss,new $CLJS.h(null,1,[$CLJS.uu,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.vx],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MI],null)],null)],null)],null)],null)],null));
$CLJS.AG.m(null,$CLJS.AK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);a=$CLJS.F.g(b,$CLJS.vx)?$CLJS.F.g(a,$CLJS.Wh)?$CLJS.eF:$CLJS.n($CLJS.eH($CLJS.RI,a))?$CLJS.GE:$CLJS.eF:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.fh($CLJS.pK,b))?$CLJS.GE:$CLJS.n($CLJS.fh($CLJS.qK,b))?$CLJS.GE:null:null;if($CLJS.n(a))return a;b=$CLJS.BG(b);b=$CLJS.ud(b)?$CLJS.Wf.j($CLJS.Ug,$CLJS.af(function(c){return $CLJS.fE(c,$CLJS.Fj)}),b):b;return $CLJS.ud(b)&&
$CLJS.F.g($CLJS.E(b),1)?$CLJS.B(b):b});$CLJS.Y(NK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.vx],null),$CLJS.st],null));
$CLJS.oH($CLJS.GK,$CLJS.G([$CLJS.ku,$CLJS.eF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.My,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ks,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TF],null)],null)],null)],null)]));
$CLJS.mH($CLJS.wA,$CLJS.G([$CLJS.ku,$CLJS.EF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QI],null)]));$CLJS.Y(vK,new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.bt,$CLJS.OI,$CLJS.Uk,$CLJS.Yk,$CLJS.CK,$CLJS.XK,$CLJS.YK,$CLJS.Xk,$CLJS.Vk,$CLJS.Tk,$CLJS.Wk,$CLJS.LI],null));$CLJS.Y(JK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bt,$CLJS.wK,$CLJS.FK,$CLJS.KK],null));
$CLJS.oH($CLJS.SK,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ni,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ks,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JK],null)],null)],
null)],null)]));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var rN,sN,tN,wfa,xfa,xN,yfa,AN,BN,CN,DN,zfa,EN,FN,HN,IN,uN,JN,Afa,KN,Bfa,MN,NN,ON,Cfa,PN,QN,Dfa,RN,Efa,SN,TN,Ffa,VN,Gfa,Hfa,Ifa,XN,YN,ZN,$N,aO,bO,cO,dO,Jfa,eO,fO,gO,iO,kO,Kfa,lO,mO,nO,oO,Lfa,pO,qO,rO,sO,Mfa,tO,uO,vO,wO,Nfa,xO,Ofa,yO,zO,AO,Pfa,BO,CO,DO,EO,GO,HO,Qfa,IO,JO,Rfa,KO,LO,MO,NO,OO,Sfa,PO,Tfa,QO,RO,TO,Ufa,UO,VO,WO,Vfa,ZO,$O,Wfa,Xfa,aP,cP,dP,eP,Yfa,hP,iP,Zfa,$fa,aga,jP,kP,lP,mP,nP,oP,bga,pP,cga,qP,rP,tP,vP,wP,xP,yP,dga,AP,ega,fga,BP,CP,DP,EP,FP,gga,GP,hga,iga,HP,IP,JP,KP,LP,jga,
kga,lga,MP,mga,NP,nga,PP,QP,oga,SP,TP,UP,VP,pga,YP,qga,rga,ZP,$P,aQ,sga,bQ,cQ,dQ,eQ,tga,gQ,hQ,iQ,jQ,kQ,uga,lQ,mQ,vga,nQ,oQ,pQ,qQ,rQ,sQ,uQ,wga,vQ,xga,wQ,xQ,yQ,yga,zga,Aga,AQ,BQ,CQ,Bga,DQ,EQ,FQ,GQ,Cga,Dga,IQ,Ega,Fga,JQ,KQ,LQ,MQ,NQ,Gga,OQ,PQ,QQ,RQ,Hga,SQ,TQ,UQ,VQ,XQ,Iga,YQ,ZQ,Jga,$Q,aR,bR,Kga,cR,Lga,eR,Mga,fR,gR,hR,iR,jR,kR,lR,Nga,Oga,Pga,mR,Qga,nR,oR,Rga,Sga,pR,qR,rR,Tga,sR,Uga,tR,Vga,Wga,uR,Xga,Yga,Zga,xR,yR,$ga,zR,AR,CR,aha,DR,bha,ER,FR,GR,cha,dha,HR,IR,JR,KR,eha,MR,NR,fha,OR,PR,QR,RR,SR,TR,UR,VR,
gha,WR,YR,hha,ZR,iha,$R,aS,bS,jha,cS,dS,kha,lha,eS,fS,gS,mha,nha,hS,oha,pha,qha,jS,rha,lS,sha,tha,mS,nS,uha,vha,wha,oS,pS,qS,rS,sS,tS,uS,xha,vS,wS,yha,xS,zha,Aha,Bha,yS,zS,AS,Cha,BS,CS,Dha,DS,Eha,ES,Fha,Gha,GS,HS,IS,JS,KS,Hha,LS,MS,Iha,NS,OS,PS,QS,RS,SS,TS,US,Jha,VS,WS,YS,ZS,$S,aT,bT,cT,dT,eT,fT,gT,Kha,hT,iT,jT,kT,lT,Lha,mT,nT,oT,pT,Mha,rT,Nha,Oha,tT,Pha,uT,Qha,vT,Rha,Sha,wT,Tha,xT,zT,AT,Uha,ET,FT,Vha,Wha,GT,HT,IT,JT,KT,LT,MT,OT,PT,Xha;
$CLJS.pN=function(a,b){return function(){function c(l,m,t){return $CLJS.Dd(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Dd(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Dd(l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Dd(function(){var A=f.j(t,u,v);return $CLJS.n(A)?$CLJS.Ie(function(D){var J=a.h?a.h(D):a.call(null,D);return $CLJS.n(J)?
b.h?b.h(D):b.call(null,D):J},x):A}())}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Kc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};
$CLJS.qN=function(a){var b=$CLJS.jo.g(a,null),c=$CLJS.bH(b,$CLJS.Ft,function(d){return $CLJS.hn(d,$CLJS.tf)});return function(){function d(k,l,m){return(l=$CLJS.z(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.bl,b,$CLJS.oj,k,$CLJS.Mt,l],null):null}function e(k){return f.j(k,$CLJS.tf,$CLJS.tf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
rN=function(a,b){return $CLJS.wd(a)?$CLJS.I.g(a,b):a};sN=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.ln($CLJS.jo.g($CLJS.bl.h(a),null));if($CLJS.n(b)){var e=rN($CLJS.wu.h(b),c);$CLJS.n(e)?(e=$CLJS.sq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:rN($CLJS.uu.h(b),c)}return null};tN=function(a,b){return $CLJS.ud(a)||$CLJS.ul(a)?$CLJS.I.g(a,b):$CLJS.vd(a)?$CLJS.I.g($CLJS.uf(a),b):null};
wfa=function(a,b){a=$CLJS.O(a);var c=$CLJS.I.g(a,$CLJS.bl),d=$CLJS.I.g(a,$CLJS.Yi);b=$CLJS.O(b);var e=$CLJS.I.j(b,$CLJS.Mt,$CLJS.sca),f=$CLJS.I.j(b,$CLJS.nca,!0),k=$CLJS.I.g(b,$CLJS.RD),l=$CLJS.I.j(b,$CLJS.PD,$CLJS.ND),m=sN(a,$CLJS.Eq.h(c),k,b);if($CLJS.n(m))return m;m=sN(a,$CLJS.au.h(c),k,b);if($CLJS.n(m))return m;m=sN(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=sN(a,function(){var t=$CLJS.Qn.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=sN(a,$CLJS.Eq.h(c),
l,b);if($CLJS.n(m))return m;m=sN(a,$CLJS.au.h(c),l,b);if($CLJS.n(m))return m;d=sN(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=sN(a,function(){var t=$CLJS.Qn.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?sN(a,e.h?e.h($CLJS.LD):e.call(null,$CLJS.LD),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?sN(a,e.h?e.h($CLJS.LD):e.call(null,$CLJS.LD),l,b):f};
xfa=function(a,b,c){a=$CLJS.R;var d=$CLJS.Wf.g;var e=$CLJS.Zk.h(b);var f=$CLJS.O(b);var k=$CLJS.I.g(f,$CLJS.bl),l=$CLJS.O(c);f=$CLJS.I.g(l,$CLJS.RD);l=$CLJS.I.j(l,$CLJS.PD,$CLJS.ND);k=$CLJS.Eq.h(k);f=rN($CLJS.OD.h(k),f);f=$CLJS.n(f)?f:rN($CLJS.OD.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.Q(null,2,5,a,[d,wfa(b,c)],null)};
$CLJS.vN=function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.oj);a=$CLJS.I.g(b,$CLJS.Mt);var d=$CLJS.O(null),e=$CLJS.I.j(d,$CLJS.oca,$CLJS.Di),f=$CLJS.I.j(d,$CLJS.qca,xfa);return $CLJS.n(a)?$CLJS.ab(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);l=$CLJS.S.j(l,$CLJS.Di,m);l=e.h?e.h(l):e.call(null,l);return uN(k,c,t,l)},null,a):null};
xN=function(a,b,c){var d=$CLJS.qC($CLJS.q($CLJS.PF),new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Gh.v($CLJS.PF,$CLJS.wN,new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null),c);return c};$CLJS.yN=function(a){return xN($CLJS.bk,a,function(){return $CLJS.cH.h(a)})};yfa=function(){var a=zN;return xN($CLJS.Ft,a,function(){var b=$CLJS.cH.h(a),c=$CLJS.qN(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
AN=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,$CLJS.ke(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,a],null):a],null)};BN=function(a){if($CLJS.yd(a)){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);switch(b instanceof $CLJS.K?b.T:null){case "optional":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ks,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,AN(c)],null)],null);case "rest":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,AN(c)],null);default:return AN(a)}}else return AN(a)};
CN=function(a,b){var c=$CLJS.yd(b);return c?(c=$CLJS.B(b)instanceof $CLJS.K)?$CLJS.td(a)?(b=$CLJS.B(b),a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c:c};
DN=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Me(CN,a)],null),$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Vs,new $CLJS.Q(null,2,5,$CLJS.R,["tag",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,a],null)],null)],null),function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=$CLJS.oe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.Q(null,2,5,$CLJS.R,[v,BN(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[m,BN(k)],null),e($CLJS.Kc(f)))}return null}},null,null)}($CLJS.du(2,2,b))}())],null)};zfa=function(a){return $CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K?$CLJS.B(a):null};
EN=function(a){return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.Bi,zfa,$CLJS.uu,["valid instance of one of these MBQL clauses: ",$CLJS.Fu(", ",$CLJS.Ze.g($CLJS.B,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,
$CLJS.ke(t)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.ke(f)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,f],null):f],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())};
FN=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oN],null)],null)};$CLJS.GN={};HN={};IN={};$CLJS.wN=function wN(a,b,c){var e=$CLJS.z(b);b=$CLJS.B(e);var f=$CLJS.C(e);if(f){e=$CLJS.S.j;var k=$CLJS.I.g(a,b);c=wN.j?wN.j(k,f,c):wN.call(null,k,f,c);a=e.call($CLJS.S,a,b,c)}else a=$CLJS.S.j(a,b,c);return a};
uN=function uN(a,b,c,d){var f=$CLJS.z(c);c=$CLJS.B(f);var k=$CLJS.C(f),l=tN(b,c);f=$CLJS.n(a)?a:$CLJS.vd(b)?$CLJS.tf:$CLJS.xd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.MD.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=tN(f,c),d=uN.v?uN.v(b,l,k,d):uN.call(null,b,l,k,d),$CLJS.zl(c)&&$CLJS.vd(f)&&c>$CLJS.E(f)&&(b=$CLJS.Ve(c-$CLJS.E(f),null),b=$CLJS.Ye.g(f,b),f=null==f||$CLJS.Cd(f)?b:$CLJS.Wf.g($CLJS.jd(f),b)),null==f||$CLJS.ul(f)?$CLJS.S.j(f,c,d):$CLJS.ud(f)?$CLJS.$d.g(f,d):$CLJS.P.g($CLJS.W,$CLJS.S.j($CLJS.uf(f),
c,d))):$CLJS.wd(a)?(c=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.pca],null),uN.v?uN.v(f,b,c,d):uN.call(null,f,b,c,d)):$CLJS.n($CLJS.MD.h($CLJS.pd(f)))?$CLJS.$d.g(f,d):$CLJS.yd($CLJS.De(f))?f:$CLJS.od(new $CLJS.Q(null,1,5,$CLJS.R,[d],null),new $CLJS.h(null,1,[$CLJS.MD,!0],null))};JN=new $CLJS.K("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Afa=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
KN=new $CLJS.K("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);$CLJS.LN=new $CLJS.K(null,"dimension","dimension",543254198);Bfa=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);MN=new $CLJS.K("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);NN=new $CLJS.K("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);
ON=new $CLJS.K("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);Cfa=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);PN=new $CLJS.K("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);QN=new $CLJS.K("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Dfa=new $CLJS.K(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
RN=new $CLJS.K("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);Efa=new $CLJS.K(null,"from","from",1815293044);SN=new $CLJS.K("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);TN=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);Ffa=new $CLJS.K(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.UN=new $CLJS.K(null,"snippet-name","snippet-name",819240328);
VN=new $CLJS.K("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);Gfa=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Hfa=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.WN=new $CLJS.K(null,"aggregation-options","aggregation-options",-1904917550);Ifa=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);
XN=new $CLJS.K("location","zip_code","location/zip_code",1641155222);YN=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);ZN=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);$N=new $CLJS.K("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);aO=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);bO=new $CLJS.K("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
cO=new $CLJS.K("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);dO=new $CLJS.K("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Jfa=new $CLJS.K(null,"lon-max","lon-max",1590224717);eO=new $CLJS.K("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);fO=new $CLJS.w(null,"stddev","stddev",775056588,null);gO=new $CLJS.K("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.hO=new $CLJS.K(null,"snippet","snippet",953581994);iO=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.jO=new $CLJS.K(null,"query","query",-1288509510);kO=new $CLJS.K(null,"string-expression","string-expression",-1395337766);Kfa=new $CLJS.K(null,"lat-field","lat-field",-830652957);lO=new $CLJS.K("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);mO=new $CLJS.K("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
nO=new $CLJS.K("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);oO=new $CLJS.K("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Lfa=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);pO=new $CLJS.K("location","country","location/country",1666636202);qO=new $CLJS.K("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
rO=new $CLJS.K("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);sO=new $CLJS.K("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Mfa=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);tO=new $CLJS.K(null,"unary","unary",-989314568);uO=new $CLJS.K("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
vO=new $CLJS.K("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);wO=new $CLJS.w(null,"ceil","ceil",-184398425,null);Nfa=new $CLJS.K(null,"lon-min","lon-min",-787291357);xO=new $CLJS.w(null,"NonBlankString","NonBlankString",-719244809,null);Ofa=new $CLJS.K(null,"match","match",1220059550);yO=new $CLJS.w(null,"count-where","count-where",2025939247,null);zO=new $CLJS.K("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
AO=new $CLJS.K(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Pfa=new $CLJS.K(null,"embedded-question","embedded-question",-2146473954);BO=new $CLJS.K("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);CO=new $CLJS.w(null,"sum","sum",1777518341,null);DO=new $CLJS.K("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);EO=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.FO=new $CLJS.K("date","range","date/range",1647265776);GO=new $CLJS.w(null,"between","between",-1523336493,null);HO=new $CLJS.K(null,"clause-form","clause-form",1820463737);Qfa=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);IO=new $CLJS.K("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);JO=new $CLJS.w(null,"field","field",338095027,null);Rfa=new $CLJS.K(null,"segment-id","segment-id",1810133590);
KO=new $CLJS.K("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);LO=new $CLJS.w(null,"not-null","not-null",313812992,null);MO=new $CLJS.K("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);NO=new $CLJS.K("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);OO=new $CLJS.K("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Sfa=new $CLJS.K(null,"template-tag","template-tag",310841038);
PO=new $CLJS.K(null,"invalid","invalid",412869516);Tfa=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);QO=new $CLJS.K("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);RO=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.SO=new $CLJS.K(null,"context","context",-830191113);TO=new $CLJS.w(null,"get-year","get-year",704520253,null);
Ufa=new $CLJS.K(null,"format-rows?","format-rows?",992129486);UO=new $CLJS.K("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);VO=new $CLJS.K("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);WO=new $CLJS.K("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.XO=new $CLJS.K(null,"parameters","parameters",-1229919748);Vfa=new $CLJS.K(null,"json-download","json-download",-971130133);
$CLJS.YO=new $CLJS.K(null,"card","card",-1430355152);ZO=new $CLJS.K(null,"string-or-field","string-or-field",-1970678542);$O=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);Wfa=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Xfa=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);aP=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.bP=new $CLJS.K("date","month-year","date/month-year",1948031290);
cP=new $CLJS.K("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);dP=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);eP=new $CLJS.K("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.fP=new $CLJS.K(null,"joins","joins",1033962699);Yfa=new $CLJS.K(null,"slug","slug",2029314850);$CLJS.gP=new $CLJS.K(null,"source-field","source-field",933829534);hP=new $CLJS.w(null,"Field","Field",430385967,null);
iP=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);Zfa=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);$fa=new $CLJS.K(null,"disable-max-results?","disable-max-results?",857693204);aga=new $CLJS.K(null,"items","items",1031954938);jP=new $CLJS.K(null,"datetime-expression","datetime-expression",391782195);kP=new $CLJS.K("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
lP=new $CLJS.K(null,"more","more",-2058821800);mP=new $CLJS.K(null,"first-clause","first-clause",-20953491);nP=new $CLJS.K("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);oP=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);bga=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);pP=new $CLJS.w(null,"contains","contains",-1977535957,null);
cga=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);qP=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);rP=new $CLJS.K("number","\x3d","number/\x3d",-2094581309);$CLJS.sP=new $CLJS.K(null,"widget-type","widget-type",1836256899);tP=new $CLJS.K("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.uP=new $CLJS.K(null,"fingerprint","fingerprint",598613022);
vP=new $CLJS.w(null,"is-null","is-null",-356519403,null);wP=new $CLJS.K(null,"other-clauses","other-clauses",1570511021);xP=new $CLJS.K("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);yP=new $CLJS.K("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);dga=new $CLJS.K(null,"map-tiles","map-tiles",1961865797);$CLJS.zP=new $CLJS.K(null,"required","required",1807647006);AP=new $CLJS.K(null,"unnamed-aggregation","unnamed-aggregation",-93854280);
ega=new $CLJS.K(null,"datetime-x","datetime-x",1519265947);fga=new $CLJS.K(null,"datetime-y","datetime-y",-1666955771);BP=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);CP=new $CLJS.K(null,"date-arithmetics","date-arithmetics",-1832808309);DP=new $CLJS.K("string","contains","string/contains",1602423827);EP=new $CLJS.K("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);FP=new $CLJS.K("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);
gga=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);GP=new $CLJS.K("string","!\x3d","string/!\x3d",-1083772573);hga=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);iga=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);HP=new $CLJS.K("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);
IP=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);JP=new $CLJS.K("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);KP=new $CLJS.K("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);LP=new $CLJS.w(null,"share","share",1051097594,null);jga=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);
kga=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);lga=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);MP=new $CLJS.K("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);mga=new $CLJS.K(null,"xlsx-download","xlsx-download",-1622892009);NP=new $CLJS.K("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);
nga=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.OP=new $CLJS.K(null,"collection","collection",-683361892);PP=new $CLJS.K("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);QP=new $CLJS.K("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.RP=new $CLJS.K("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);
oga=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);SP=new $CLJS.K("number","\x3c\x3d","number/\x3c\x3d",-1499316353);TP=new $CLJS.w(null,"metric","metric",2049329604,null);UP=new $CLJS.w(null,"concat","concat",-467652465,null);VP=new $CLJS.K(null,"variable","variable",-281346492);$CLJS.WP=new $CLJS.K("date","quarter-year","date/quarter-year",-1453950150);pga=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);
$CLJS.XP=new $CLJS.K("date","relative","date/relative",25987732);YP=new $CLJS.K("location","city","location/city",-1746973325);qga=new $CLJS.K(null,"public-dashboard","public-dashboard",645968405);rga=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);ZP=new $CLJS.K("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);$P=new $CLJS.K("number","\x3e\x3d","number/\x3e\x3d",-1670691032);aQ=new $CLJS.K("number","between","number/between",97700581);
sga=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);bQ=new $CLJS.K("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);cQ=new $CLJS.w(null,"sqrt","sqrt",370479598,null);dQ=new $CLJS.K("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);eQ=new $CLJS.K("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.fQ=new $CLJS.K(null,"semantic_type","semantic_type",272485089);
tga=new $CLJS.K(null,"metric-id","metric-id",-686486942);gQ=new $CLJS.w(null,"*","*",345799209,null);hQ=new $CLJS.w(null,"+","+",-740910886,null);iQ=new $CLJS.w(null,"-","-",-471816912,null);jQ=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);kQ=new $CLJS.K(null,"allowed-for","allowed-for",122724334);uga=new $CLJS.K(null,"question","question",-1411720117);lQ=new $CLJS.w(null,"asc","asc",1997386096,null);
mQ=new $CLJS.K("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);vga=new $CLJS.K(null,"pulse-id","pulse-id",1331432237);nQ=new $CLJS.K("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);oQ=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);pQ=new $CLJS.w(null,"\x3c","\x3c",993667236,null);qQ=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);rQ=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);
sQ=new $CLJS.K("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.tQ=new $CLJS.K(null,"source-metadata","source-metadata",-477816085);uQ=new $CLJS.K("string","ends-with","string/ends-with",302681156);wga=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);vQ=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);xga=new $CLJS.w(null,"MetricID","MetricID",-2128635641,null);
wQ=new $CLJS.w(null,"and","and",668631710,null);xQ=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);yQ=new $CLJS.w(null,"round","round",-645002441,null);yga=new $CLJS.K(null,"to","to",192099007);$CLJS.zQ=new $CLJS.K("date","single","date/single",1554682003);zga=new $CLJS.K(null,"action-id","action-id",-1727958578);Aga=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);AQ=new $CLJS.w(null,"exp","exp",1378825265,null);
BQ=new $CLJS.w(null,"Filter","Filter",-424893332,null);CQ=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);Bga=new $CLJS.K(null,"dashboard-id","dashboard-id",1965414288);DQ=new $CLJS.K("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);EQ=new $CLJS.K("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);FQ=new $CLJS.K("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
GQ=new $CLJS.K(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.HQ=new $CLJS.K(null,"source-table","source-table",-225307692);Cga=new $CLJS.K(null,"embedded-dashboard","embedded-dashboard",-485078014);Dga=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);IQ=new $CLJS.w(null,"floor","floor",-772394748,null);Ega=new $CLJS.K(null,"middleware","middleware",1462115504);
Fga=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);JQ=new $CLJS.K(null,"requires-features","requires-features",-101116256);KQ=new $CLJS.K("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);LQ=new $CLJS.K(null,"clause-name","clause-name",-996419059);MQ=new $CLJS.K("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);NQ=new $CLJS.w(null,"now","now",-9994004,null);
Gga=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);OQ=new $CLJS.w(null,"not","not",1044554643,null);PQ=new $CLJS.w(null,"avg","avg",1837937727,null);QQ=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);RQ=new $CLJS.K(null,"max-results","max-results",-32858165);Hga=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);SQ=new $CLJS.w(null,"case","case",-1510733573,null);
TQ=new $CLJS.w(null,"distinct","distinct",-148347594,null);UQ=new $CLJS.w(null,"get-second","get-second",-425414791,null);VQ=new $CLJS.K("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.WQ=new $CLJS.K(null,"join-alias","join-alias",1454206794);XQ=new $CLJS.w(null,"is-empty","is-empty",600228619,null);Iga=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
YQ=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);ZQ=new $CLJS.K("number","!\x3d","number/!\x3d",-673025509);Jga=new $CLJS.K(null,"original","original",-445386197);$Q=new $CLJS.K("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);aR=new $CLJS.w(null,"abs","abs",1394505050,null);bR=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);Kga=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
cR=new $CLJS.K("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Lga=new $CLJS.w(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.dR=new $CLJS.K(null,"date","date",-1463434462);eR=new $CLJS.K(null,"second-clause","second-clause",-461435645);Mga=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);fR=new $CLJS.w(null,"rtrim","rtrim",979195078,null);
gR=new $CLJS.K("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);hR=new $CLJS.K("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);iR=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);jR=new $CLJS.K("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);kR=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);lR=new $CLJS.w(null,"or","or",1876275696,null);
Nga=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Oga=new $CLJS.K(null,"constraints","constraints",422775616);Pga=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);mR=new $CLJS.K("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Qga=new $CLJS.K(null,"csv-download","csv-download",2141432084);nR=new $CLJS.K("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
oR=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);Rga=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Sga=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);pR=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);qR=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
rR=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Tga=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);sR=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);Uga=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);tR=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Vga=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Wga=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);uR=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);Xga=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Yga=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Zga=new $CLJS.K(null,"display_name","display_name",-1494335013);$CLJS.vR=new $CLJS.K(null,"snippet-id","snippet-id",1987785841);
$CLJS.wR=new $CLJS.K(null,"database","database",1849087575);xR=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);yR=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);$ga=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);zR=new $CLJS.K("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
AR=new $CLJS.K("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.BR=new $CLJS.K(null,"expressions","expressions",255689909);CR=new $CLJS.K("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);aha=new $CLJS.K(null,"ad-hoc","ad-hoc",-2033634036);DR=new $CLJS.K("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
bha=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);ER=new $CLJS.K("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);FR=new $CLJS.K("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);GR=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);cha=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);dha=new $CLJS.K(null,"action","action",-811238024);
HR=new $CLJS.w(null,"get-day","get-day",1768100384,null);IR=new $CLJS.K("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);JR=new $CLJS.K("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);KR=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.LR=new $CLJS.K(null,"native","native",-613060878);eha=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);MR=new $CLJS.K(null,"page","page",849072397);
NR=new $CLJS.w(null,"length","length",-2065447907,null);fha=new $CLJS.K(null,"dashboard","dashboard",-631747508);OR=new $CLJS.w(null,"get-week","get-week",752472178,null);PR=new $CLJS.w(null,"get-month","get-month",1271156796,null);QR=new $CLJS.w(null,"dimension","dimension",-2111181571,null);RR=new $CLJS.K(null,"boolean-expression","boolean-expression",-394924008);SR=new $CLJS.K("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
TR=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);UR=new $CLJS.K("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);VR=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);gha=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);WR=new $CLJS.K("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);YR=new $CLJS.w(null,"substring","substring",-1513569493,null);
hha=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);ZR=new $CLJS.K(null,"internal","internal",-854870097);iha=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);$R=new $CLJS.K("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);aS=new $CLJS.K("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
bS=new $CLJS.K("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);jha=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);cS=new $CLJS.K(null,"more-values-or-fields","more-values-or-fields",-886177554);dS=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);kha=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
lha=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);eS=new $CLJS.K("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);fS=new $CLJS.K("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);gS=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
mha=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);nha=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);hS=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.iS=new $CLJS.K(null,"template-tags","template-tags",1853115685);oha=new $CLJS.K(null,"public-question","public-question",629369976);pha=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
qha=new $CLJS.K(null,"binary","binary",-1802232288);jS=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);rha=new $CLJS.K(null,"userland-query?","userland-query?",-123699383);$CLJS.kS=new $CLJS.K(null,"source-query","source-query",198004422);lS=new $CLJS.K("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);sha=new $CLJS.w(null,"SegmentID","SegmentID",-1773652704,null);
tha=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);mS=new $CLJS.K("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);nS=new $CLJS.K("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);uha=new $CLJS.K(null,"executed-by","executed-by",-739811161);vha=new $CLJS.w("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
wha=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);oS=new $CLJS.K(null,"amount","amount",364489504);pS=new $CLJS.w(null,"percentile","percentile",1039342775,null);qS=new $CLJS.K("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);rS=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);sS=new $CLJS.K("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
tS=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);uS=new $CLJS.w(null,"trim","trim",-1880116002,null);xha=new $CLJS.w(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);vS=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);wS=new $CLJS.K("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);yha=new $CLJS.K("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
xS=new $CLJS.K("string","\x3d","string/\x3d",983744235);zha=new $CLJS.K(null,"skip-results-metadata?","skip-results-metadata?",251010463);Aha=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Bha=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);yS=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);zS=new $CLJS.K(null,"value-or-field","value-or-field",-1387286309);
AS=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Cha=new $CLJS.K(null,"lat-min","lat-min",1630784161);BS=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);CS=new $CLJS.w(null,"inside","inside",-681932758,null);Dha=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);DS=new $CLJS.K("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Eha=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);ES=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);Fha=new $CLJS.K(null,"process-viz-settings?","process-viz-settings?",-173592315);Gha=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.FS=new $CLJS.K(null,"card-id","card-id",-1770060179);GS=new $CLJS.K(null,"variadic","variadic",882626057);
HS=new $CLJS.w(null,"upper","upper",1886775433,null);IS=new $CLJS.K(null,"field-or-expression","field-or-expression",-1409494368);JS=new $CLJS.K("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);KS=new $CLJS.w(null,"optional","optional",-600484260,null);Hha=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);LS=new $CLJS.K("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
MS=new $CLJS.K(null,"sugar","sugar",-73788488);Iha=new $CLJS.K(null,"lat-max","lat-max",841568226);NS=new $CLJS.w(null,"power","power",702679448,null);OS=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);PS=new $CLJS.K("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);QS=new $CLJS.K("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
RS=new $CLJS.K("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);SS=new $CLJS.w(null,"median","median",-2084869638,null);TS=new $CLJS.K("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);US=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);Jha=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);VS=new $CLJS.K(null,"y","y",-1757859776);WS=new $CLJS.w(null,"aggregation","aggregation",-1056959073,null);
$CLJS.XS=new $CLJS.K(null,"binning","binning",1709835866);YS=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);ZS=new $CLJS.K("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);$S=new $CLJS.K("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);aT=new $CLJS.K("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);bT=new $CLJS.w(null,"Reference","Reference",2024574086,null);cT=new $CLJS.K(null,"b","b",1482224470);
dT=new $CLJS.K(null,"a","a",-2123407586);eT=new $CLJS.K("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);fT=new $CLJS.w(null,"replace","replace",853943757,null);gT=new $CLJS.K("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Kha=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);hT=new $CLJS.K("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
iT=new $CLJS.K("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);jT=new $CLJS.K("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);kT=new $CLJS.K("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);lT=new $CLJS.w(null,"segment","segment",675610331,null);Lha=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);mT=new $CLJS.K("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
nT=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);oT=new $CLJS.K("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);pT=new $CLJS.K("string","starts-with","string/starts-with",1266861170);Mha=new $CLJS.w(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.qT=new $CLJS.K(null,"order-by","order-by",1527318070);rT=new $CLJS.K(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Nha=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Oha=new $CLJS.K("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.sT=new $CLJS.K(null,"condition","condition",1668437652);tT=new $CLJS.K("string","does-not-contain","string/does-not-contain",-1536178964);Pha=new $CLJS.K(null,"report-timezone","report-timezone",-2053796389);uT=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Qha=new $CLJS.K(null,"card-name","card-name",-2035606807);vT=new $CLJS.K("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Rha=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Sha=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);wT=new $CLJS.w(null,"log","log",45015523,null);Tha=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
xT=new $CLJS.w(null,"time","time",-1268547887,null);$CLJS.yT=new $CLJS.K(null,"database_type","database_type",-54700895);zT=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);AT=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.BT=new $CLJS.K(null,"clause","clause",1479668060);$CLJS.CT=new $CLJS.K(null,"expression-name","expression-name",-1798450709);$CLJS.DT=new $CLJS.K("date","all-options","date/all-options",549325958);
Uha=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);ET=new $CLJS.K("location","state","location/state",-114378652);FT=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);Vha=new $CLJS.K(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Wha=new $CLJS.K(null,"lon-field","lon-field",517872067);GT=new $CLJS.K("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);
HT=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);IT=new $CLJS.K(null,"numeric","numeric",-1495594714);JT=new $CLJS.w(null,"variable","variable",1359185035,null);KT=new $CLJS.w(null,"lower","lower",-1534114948,null);LT=new $CLJS.K("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);MT=new $CLJS.K("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.NT=new $CLJS.K(null,"limit","limit",-1355822363);
OT=new $CLJS.K("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);PT=new $CLJS.w(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Xha=new $CLJS.K(null,"pulse","pulse",-244494476);var QT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.fG],null),RT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null),ST=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cG],null),TT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YF],null),UT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.kG],null),VT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oG],null),Yha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.BM],null),Zha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.wM],null),WT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,
$CLJS.hK],null),XT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lK],null),YT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cK],null),ZT=new $CLJS.Tg(null,new $CLJS.h(null,12,[$CLJS.gi,null,$CLJS.pv,null,$CLJS.$i,null,$CLJS.Wh,null,$CLJS.Vk,null,$CLJS.ij,null,$CLJS.Yk,null,$CLJS.Xk,null,$CLJS.Rk,null,$CLJS.pj,null,$CLJS.hi,null,$CLJS.Uk,null],null),null),$T=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.ov,null,$CLJS.KI,null,$CLJS.Wh,null,$CLJS.Tk,null,$CLJS.NI,null,$CLJS.Wk,null,$CLJS.mv,null],null),null),
aU=$CLJS.qv.g(ZT,$T),$ha=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"date bucketing unit"],null)],null),ZT),aia=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"time bucketing unit"],null)],null),$T),bU=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"datetime bucketing unit"],null)],null),aU),cU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.RK],null),bia=new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,
1,[$CLJS.uu,"temporal extract unit"],null),$CLJS.OI,$CLJS.Uk,$CLJS.Yk,$CLJS.CK,$CLJS.XK,$CLJS.YK,$CLJS.Xk,$CLJS.Vk,$CLJS.Tk,$CLJS.Wk,$CLJS.LI],null),cia=new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"datetime-diff unit"],null),$CLJS.KI,$CLJS.mv,$CLJS.ov,$CLJS.pv,$CLJS.$i,$CLJS.ij,$CLJS.gi,$CLJS.Rk],null),dU=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"temporal-extract week extraction mode"],null),$CLJS.wK,$CLJS.FK,$CLJS.KK],null),eU=new $CLJS.Q(null,
10,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"relative-datetime unit"],null),$CLJS.Wh,$CLJS.mv,$CLJS.ov,$CLJS.pv,$CLJS.$i,$CLJS.ij,$CLJS.gi,$CLJS.Rk],null),lU,eia,tU,uU,vU,wU,xU,yU,zU,kW,gia,lW,hia,iia,mW,jia,kia,lia;$CLJS.Y(vO,DN($CLJS.GK,$CLJS.G(["n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.vx],null),$CLJS.st],null),"unit",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,eU],null)])));var fU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vO],null);
$CLJS.Y($Q,DN($CLJS.EK,$CLJS.G(["n",$CLJS.st,"unit",eU])));var dia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$Q],null);
$CLJS.Y(QO,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.uu,"valid :absolute-datetime clause",$CLJS.Bi,function(a){if($CLJS.Sa(CN($CLJS.AK,a)))a=PO;else{a=$CLJS.hd(a);var b=$CLJS.yN(WT);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.dR:$CLJS.HK}return a}],null),new $CLJS.Q(null,2,5,$CLJS.R,[PO,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"not an :absolute-datetime clause"],null),$CLJS.Le(!1)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dR,DN($CLJS.AK,
$CLJS.G(["date",WT,"unit",$ha]))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HK,DN($CLJS.AK,$CLJS.G(["datetime",XT,"unit",bU]))],null)],null));var gU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QO],null);$CLJS.Y(eP,DN($CLJS.wA,$CLJS.G(["time",YT,"unit",aia])));var hU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eP],null),iU=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ft,new $CLJS.h(null,1,[$CLJS.uu,"date or datetime literal"],null),gU,XT,WT],null);
$CLJS.Y(PN,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ft,new $CLJS.h(null,1,[$CLJS.uu,"time literal"],null),hU,YT],null));$CLJS.Y(GT,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ft,new $CLJS.h(null,1,[$CLJS.uu,"temporal literal"],null),iU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PN],null)],null));var jU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GT],null);
$CLJS.Y(yha,EN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof gU?new $CLJS.Fc(function(){return gU},$CLJS.od(Zfa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,ZR,$CLJS.Zi,$CLJS.ck,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GR,"metabase/mbql/schema.cljc",69,$CLJS.AK,1,!0,168,168,$CLJS.Jc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:GR}(),gU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof fU?new $CLJS.Fc(function(){return fU},$CLJS.od(Uha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,
$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",29,$CLJS.GK,1,131,131,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.GK,$CLJS.My,$CLJS.W($CLJS.zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.vx],null),$CLJS.st],null)),$CLJS.Sk,$CLJS.W($CLJS.zj,$CLJS.W(KS,vQ))],null),$CLJS.Jc,"Schema for a valid relative-datetime clause.",
$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:YQ}(),fU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof hU?new $CLJS.Fc(function(){return hU},$CLJS.od(Wga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,ZR,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xT,"metabase/mbql/schema.cljc",27,$CLJS.wA,1,!0,175,175,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.wA,$CLJS.wA,$CLJS.W($CLJS.zj,xha),$CLJS.Sk,$CLJS.W($CLJS.zj,pga)],null),$CLJS.Jc,"Schema for a valid time clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:xT}(),hU],null)])));
$CLJS.Y(vT,DN($CLJS.oj,$CLJS.G(["value",$CLJS.xt,"type-info",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yT,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,QT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TE,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,RT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fQ,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.et,ST],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Sk,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,bU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,QT],null)],null)],null)],null)])));var kU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vT],null);$CLJS.Y(eQ,DN($CLJS.dD,$CLJS.G(["expression-name",QT,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,$CLJS.Qj],null)])));
lU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eQ],null);
eia=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.rG);c=$CLJS.I.g(c,$CLJS.ZF);return $CLJS.F.g(b,$CLJS.ZF)?c:!0}],null)],null)}(function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.rG);c=$CLJS.I.g(c,$CLJS.qG);return $CLJS.F.g(b,$CLJS.qG)?c:!0}],null)],null)}(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Qj,new $CLJS.h(null,1,[$CLJS.uu,"binning options"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rG,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"binning strategy"],null),$CLJS.ZF,$CLJS.qG,$CLJS.Wh],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZF,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),TT],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.qG,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,$CLJS.ml,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"bin width must be \x3e\x3d 0."],null),$CLJS.Ke($CLJS.Hl)],null)],null)],null)],null)));$CLJS.mU=function mU(a){switch(arguments.length){case 1:return mU.h(arguments[0]);case 2:return mU.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.mU.h=function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.gD);b=$CLJS.I.g(b,$CLJS.cI);return $CLJS.mU.g(a,b)};$CLJS.mU.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.fE(a,$CLJS.GE)?ZT:$CLJS.fE(a,$CLJS.EF)?$T:$CLJS.fE(a,$CLJS.eF)?aU:null:null;return $CLJS.n(a)?$CLJS.Gd(a,b):!0};$CLJS.mU.A=2;
$CLJS.Y(LS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Qj,new $CLJS.h(null,1,[$CLJS.uu,"field options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gD,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,RT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gP,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,VT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cI,new $CLJS.h(null,
1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,bU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WQ,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,QT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XS,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,eia],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.mU],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Ke($CLJS.rG)],null)],null));
$CLJS.Y(mT,function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);b=$CLJS.O(b);b=$CLJS.I.g(b,$CLJS.gD);return"string"===typeof c?b:!0}],null)],null)}(DN($CLJS.XH,$CLJS.G(["id-or-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,VT,QT],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LS],null)],null)]))));var nU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mT],null);
$CLJS.Y(mR,EN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof lU?new $CLJS.Fc(function(){return lU},$CLJS.od(qP,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aP,"metabase/mbql/schema.cljc",60,$CLJS.dD,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.dD,$CLJS.CT,$CLJS.W($CLJS.zj,xO),$CLJS.Ri,$CLJS.W($CLJS.zj,$CLJS.W(KS,$CLJS.Qj))],null),$CLJS.Jc,"Schema for a valid expression clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:aP}(),lU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof nU?new $CLJS.Fc(function(){return nU},
$CLJS.od(IP,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hr,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,$CLJS.ki,$CLJS.U,$CLJS.Uj],["0.39.0",$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JO,"metabase/mbql/schema.cljc",51,$CLJS.XH,1,382,382,$CLJS.Jc,"Schema for a `:field` clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:JO}(),nU],null)])));
$CLJS.oU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mR],null);$CLJS.Y(JN,DN($CLJS.MH,$CLJS.G(["aggregation-clause-index",$CLJS.st,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,$CLJS.Qj],null)])));var pU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JN],null);
$CLJS.Y(rO,EN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof pU?new $CLJS.Fc(function(){return pU},$CLJS.od(vha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WS,"metabase/mbql/schema.cljc",23,$CLJS.MH,1,418,418,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.MH,Vha,$CLJS.W($CLJS.zj,$CLJS.st),$CLJS.Ri,$CLJS.W($CLJS.zj,$CLJS.W(KS,$CLJS.Qj))],null),$CLJS.Jc,"Schema for a valid aggregation clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:WS}(),pU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof lU?new $CLJS.Fc(function(){return lU},
$CLJS.od(qP,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",60,$CLJS.dD,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.dD,$CLJS.CT,$CLJS.W($CLJS.zj,xO),$CLJS.Ri,
$CLJS.W($CLJS.zj,$CLJS.W(KS,$CLJS.Qj))],null),$CLJS.Jc,"Schema for a valid expression clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:aP}(),lU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof nU?new $CLJS.Fc(function(){return nU},$CLJS.od(IP,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hr,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,$CLJS.ki,
$CLJS.U,$CLJS.Uj],["0.39.0",$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JO,"metabase/mbql/schema.cljc",51,$CLJS.XH,1,382,382,$CLJS.Jc,"Schema for a `:field` clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:JO}(),nU],null)])));
var qU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rO],null),rU=new $CLJS.Tg(null,new $CLJS.h(null,11,[$CLJS.Iu,null,$CLJS.qI,null,$CLJS.qH,null,$CLJS.wI,null,$CLJS.vH,null,$CLJS.$H,null,$CLJS.FH,null,$CLJS.Qu,null,$CLJS.oI,null,$CLJS.ZH,null,$CLJS.xI,null],null),null),sU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,oO],null);
$CLJS.Y(RN,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Bi,function(a){return"string"===typeof a?$CLJS.Ij:$CLJS.n(CN(rU,a))?kO:$CLJS.n(CN($CLJS.oj,a))?$CLJS.oj:$CLJS.Ku}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ij,$CLJS.Ij],null),new $CLJS.Q(null,2,5,$CLJS.R,[kO,sU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oj,kU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ku,$CLJS.oU],null)],null));tU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,RN],null);
uU=new $CLJS.Tg(null,new $CLJS.h(null,26,[$CLJS.sH,null,$CLJS.WK,null,$CLJS.Js,null,$CLJS.SK,null,$CLJS.PK,null,$CLJS.KH,null,$CLJS.ku,null,$CLJS.pH,null,$CLJS.TK,null,$CLJS.MK,null,$CLJS.$H,null,$CLJS.tI,null,$CLJS.QK,null,$CLJS.bI,null,$CLJS.tH,null,$CLJS.yK,null,$CLJS.Is,null,$CLJS.wH,null,$CLJS.FH,null,$CLJS.sK,null,$CLJS.Gz,null,$CLJS.zK,null,$CLJS.LK,null,$CLJS.DK,null,$CLJS.vI,null,$CLJS.VH,null],null),null);
vU=new $CLJS.Tg(null,new $CLJS.h(null,9,[$CLJS.Es,null,$CLJS.ys,null,$CLJS.ft,null,$CLJS.ht,null,$CLJS.As,null,$CLJS.pI,null,$CLJS.mt,null,$CLJS.Fs,null,$CLJS.Cs,null],null),null);wU=new $CLJS.Tg(null,new $CLJS.h(null,17,[$CLJS.Kn,null,$CLJS.nI,null,$CLJS.fI,null,$CLJS.YH,null,$CLJS.sI,null,$CLJS.uI,null,$CLJS.BH,null,$CLJS.IH,null,$CLJS.WN,null,$CLJS.CH,null,$CLJS.kI,null,$CLJS.dI,null,$CLJS.Rj,null,$CLJS.Iz,null,$CLJS.RH,null,$CLJS.SH,null,$CLJS.jI,null],null),null);
xU=new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.IK,null,$CLJS.uK,null,$CLJS.UK,null,$CLJS.Is,null,$CLJS.OK,null],null),null);yU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DS],null);zU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cP],null);$CLJS.AU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eS],null);$CLJS.BU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JS],null);
$CLJS.Y(QS,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.uu,"numeric expression argument",$CLJS.Bi,function(a){return"number"===typeof a?$CLJS.dv:$CLJS.n(CN(uU,a))?GQ:$CLJS.n(CN(wU,a))?$CLJS.MH:$CLJS.n(CN($CLJS.oj,a))?$CLJS.oj:$CLJS.XH}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dv,$CLJS.ml],null),new $CLJS.Q(null,2,5,$CLJS.R,[GQ,yU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MH,$CLJS.BU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oj,kU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XH,
$CLJS.oU],null)],null));var CU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QS],null);
$CLJS.Y($R,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.uu,"datetime expression argument",$CLJS.Bi,function(a){return $CLJS.n(CN(wU,a))?$CLJS.MH:$CLJS.n(CN($CLJS.oj,a))?$CLJS.oj:$CLJS.n(CN(xU,a))?jP:$CLJS.Ku}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MH,$CLJS.BU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oj,kU],null),new $CLJS.Q(null,2,5,$CLJS.R,[jP,$CLJS.AU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ku,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,iU,$CLJS.oU],null)],null)],null));
var DU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$R],null);
$CLJS.Y(CR,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.uu,"expression argument",$CLJS.Bi,function(a){return"number"===typeof a?$CLJS.dv:$CLJS.Bd(a)?$CLJS.wt:$CLJS.n(CN(vU,a))?RR:$CLJS.n(CN(uU,a))?GQ:$CLJS.n(CN(xU,a))?jP:"string"===typeof a?$CLJS.Ij:$CLJS.n(CN(rU,a))?kO:$CLJS.n(CN($CLJS.oj,a))?$CLJS.oj:$CLJS.Ku}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dv,$CLJS.ml],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wt,$CLJS.wt],null),new $CLJS.Q(null,2,5,$CLJS.R,[RR,zU],null),new $CLJS.Q(null,
2,5,$CLJS.R,[GQ,yU],null),new $CLJS.Q(null,2,5,$CLJS.R,[jP,$CLJS.AU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ij,$CLJS.Ij],null),new $CLJS.Q(null,2,5,$CLJS.R,[kO,sU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oj,kU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ku,$CLJS.oU],null)],null));var EU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CR],null);$CLJS.Y(AR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ft,new $CLJS.h(null,1,[$CLJS.uu,"numeric expression arg or interval"],null),dia,CU],null));
var FU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,AR],null);$CLJS.Y(bO,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.uu,"int greater than zero or numeric expression",$CLJS.Bi,function(a){return"number"===typeof a?$CLJS.dv:$CLJS.Ku}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dv,TT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ku,yU],null)],null));var fia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bO],null);
$CLJS.Y(kP,DN($CLJS.$H,$CLJS.G(["a",EU,"b",EU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ci,EU],null)])));var GU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kP],null);$CLJS.Y(ON,DN($CLJS.qH,$CLJS.G(["s",tU,"start",fia,"length",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,CU],null)])));var HU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ON],null);$CLJS.Y(SR,DN($CLJS.Gz,$CLJS.G(["s",tU])));var IU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,SR],null);$CLJS.Y(qO,DN($CLJS.oI,$CLJS.G(["s",tU])));
var JU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,qO],null);$CLJS.Y(uO,DN($CLJS.xI,$CLJS.G(["s",tU])));var KU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uO],null);$CLJS.Y(NO,DN($CLJS.qI,$CLJS.G(["s",tU])));var LU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NO],null);$CLJS.Y(yP,DN($CLJS.Iu,$CLJS.G(["s",tU])));var MU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,yP],null);$CLJS.Y(gO,DN($CLJS.Qu,$CLJS.G(["s",tU])));var NU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gO],null);
$CLJS.Y(PS,DN($CLJS.wI,$CLJS.G(["s",tU,"match",$CLJS.Ij,"replacement",$CLJS.Ij])));var OU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PS],null);$CLJS.Y(LT,DN($CLJS.vH,$CLJS.G(["a",tU,"b",tU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ci,tU],null)])));var PU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LT],null);$CLJS.Y(nQ,DN($CLJS.ZH,$CLJS.G(["s",tU,"pattern",$CLJS.Ij])));var QU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nQ],null);
$CLJS.Y(kT,DN($CLJS.Is,$CLJS.G(["x",FU,"y",FU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ci,FU],null)])));var RU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kT],null);$CLJS.Y(iT,DN($CLJS.ku,$CLJS.G(["x",CU,"y",FU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ci,FU],null)])));var SU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iT],null);$CLJS.Y(hT,DN($CLJS.tI,$CLJS.G(["x",CU,"y",CU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ci,CU],null)])));var TU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hT],null);
$CLJS.Y(jT,DN($CLJS.Js,$CLJS.G(["x",CU,"y",CU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ci,CU],null)])));var UU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jT],null);$CLJS.Y(zR,DN($CLJS.VH,$CLJS.G(["x",CU])));var VU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zR],null);$CLJS.Y(sS,DN($CLJS.sH,$CLJS.G(["x",CU])));var WU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,sS],null);$CLJS.Y(OT,DN($CLJS.bI,$CLJS.G(["x",CU])));var XU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OT],null);$CLJS.Y(EQ,DN($CLJS.wH,$CLJS.G(["x",CU])));
var YU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EQ],null);$CLJS.Y(KQ,DN($CLJS.tH,$CLJS.G(["x",CU,"y",CU])));var ZU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KQ],null);$CLJS.Y(gR,DN($CLJS.KH,$CLJS.G(["x",CU])));var $U=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gR],null);$CLJS.Y(cO,DN($CLJS.pH,$CLJS.G(["x",CU])));var aV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cO],null);$CLJS.Y(FR,DN($CLJS.vI,$CLJS.G(["x",CU])));var bV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FR],null);
$CLJS.Y(DO,DN($CLJS.DK,$CLJS.G(["datetime-x",DU,"datetime-y",DU,"unit",cia])));var cV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DO],null);$CLJS.Y(VO,DN($CLJS.SK,$CLJS.G(["datetime",DU,"unit",bia,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,dU],null)])));var dV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VO],null);$CLJS.Y(MQ,DN($CLJS.zK,$CLJS.G(["date",DU])));var eV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MQ],null);$CLJS.Y(nR,DN($CLJS.WK,$CLJS.G(["date",DU])));
var fV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nR],null);$CLJS.Y(QN,DN($CLJS.sK,$CLJS.G(["date",DU])));var gV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QN],null);$CLJS.Y(MT,DN($CLJS.MK,$CLJS.G(["date",DU,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,dU],null)])));var hV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MT],null);$CLJS.Y(ZP,DN($CLJS.LK,$CLJS.G(["date",DU])));var iV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ZP],null);$CLJS.Y(hR,DN($CLJS.QK,$CLJS.G(["date",DU])));
var jV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hR],null);$CLJS.Y(zO,DN($CLJS.TK,$CLJS.G(["datetime",DU])));var kV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zO],null);$CLJS.Y(dQ,DN($CLJS.PK,$CLJS.G(["datetime",DU])));var lV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dQ],null);$CLJS.Y(sO,DN($CLJS.yK,$CLJS.G(["datetime",DU])));var mV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,sO],null);$CLJS.Y(JP,DN($CLJS.uK,$CLJS.G(["datetime",DU,"to",cU,"from",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,cU],null)])));
var nV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JP],null),oV=new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"datetime arithmetic unit"],null),$CLJS.NI,$CLJS.KI,$CLJS.mv,$CLJS.ov,$CLJS.pv,$CLJS.$i,$CLJS.ij,$CLJS.gi,$CLJS.Rk],null);$CLJS.Y(NN,DN($CLJS.OK,$CLJS.G(["datetime",DU,"amount",CU,"unit",oV])));var pV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NN],null);
$CLJS.Y(lO,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return DN(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)}($CLJS.IK));var qV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lO],null);$CLJS.Y(MN,DN($CLJS.UK,$CLJS.G(["datetime",DU,"amount",CU,"unit",oV])));var rV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MN],null);
$CLJS.Y(eS,EN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof RU?new $CLJS.Fc(function(){return RU},$CLJS.od(tR,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hQ,"metabase/mbql/schema.cljc",51,$CLJS.Is,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Is,$CLJS.nG,$CLJS.W($CLJS.zj,xR),VS,$CLJS.W($CLJS.zj,xR),lP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,xR))],null),$CLJS.Jc,"Schema for a valid + clause.",$CLJS.n(RU)?RU.H:null])):null));return $CLJS.n(a)?a:hQ}(),RU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof pV?new $CLJS.Fc(function(){return pV},
$CLJS.od(Gga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[CP,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oR,"metabase/mbql/schema.cljc",67,$CLJS.OK,1,703,703,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.OK,$CLJS.HK,$CLJS.W($CLJS.zj,HT),oS,$CLJS.W($CLJS.zj,
tS),$CLJS.Sk,$CLJS.W($CLJS.zj,BS)],null),$CLJS.Jc,"Schema for a valid datetime-add clause.",$CLJS.n(pV)?pV.H:null])):null));return $CLJS.n(a)?a:oR}(),pV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof rV?new $CLJS.Fc(function(){return rV},$CLJS.od(Afa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,
$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[CP,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FT,"metabase/mbql/schema.cljc",72,$CLJS.UK,1,710,710,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.UK,$CLJS.HK,$CLJS.W($CLJS.zj,HT),oS,$CLJS.W($CLJS.zj,tS),$CLJS.Sk,$CLJS.W($CLJS.zj,BS)],null),$CLJS.Jc,"Schema for a valid datetime-subtract clause.",$CLJS.n(rV)?rV.H:null])):null));
return $CLJS.n(a)?a:FT}(),rV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof nV?new $CLJS.Fc(function(){return nV},$CLJS.od(Eha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.uK,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",71,$CLJS.uK,1,695,695,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.uK,$CLJS.HK,$CLJS.W($CLJS.zj,HT),yga,$CLJS.W($CLJS.zj,YS),Efa,$CLJS.W($CLJS.zj,$CLJS.W(KS,YS))],null),$CLJS.Jc,"Schema for a valid convert-timezone clause.",$CLJS.n(nV)?nV.H:null])):null));return $CLJS.n(a)?a:iP}(),nV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&
"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof qV?new $CLJS.Fc(function(){return qV},$CLJS.od(gha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.IK,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NQ,"metabase/mbql/schema.cljc",
45,$CLJS.IK,1,708,708,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.IK],null),$CLJS.Jc,"Schema for a valid now clause.",$CLJS.n(qV)?qV.H:null])):null));return $CLJS.n(a)?a:NQ}(),qV],null)])));$CLJS.sV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jR],null);$CLJS.Y(SN,DN($CLJS.mt,$CLJS.G(["first-clause",$CLJS.sV,"second-clause",$CLJS.sV,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ci,$CLJS.sV],null)])));var tV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,SN],null);
$CLJS.Y(FP,DN($CLJS.ft,$CLJS.G(["first-clause",$CLJS.sV,"second-clause",$CLJS.sV,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ci,$CLJS.sV],null)])));var uV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FP],null);$CLJS.Y(nO,DN($CLJS.ht,$CLJS.G(["clause",$CLJS.sV])));
var vV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nO],null),wV=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,3,[$CLJS.uu,":field or :expression reference or :relative-datetime",$CLJS.wu,$CLJS.Le(":field or :expression reference or :relative-datetime"),$CLJS.Bi,function(a){return $CLJS.n(CN($CLJS.GK,a))?$CLJS.GK:$CLJS.Ku}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GK,fU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ku,$CLJS.oU],null)],null);
$CLJS.Y(bS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.uu,"equality comparable"],null),new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.ft,$CLJS.wt,$CLJS.ml,$CLJS.Ij,jU,wV,EU,kU],null)],null));var xV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bS],null);
$CLJS.Y(sQ,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.uu,"order comparable",$CLJS.Bi,function(a){return $CLJS.n(CN($CLJS.oj,a))?$CLJS.oj:$CLJS.Ku}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oj,kU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ku,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.ft,$CLJS.ml,$CLJS.Ij,jU,EU,wV],null)],null)],null));var yV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,sQ],null);
$CLJS.Y(aT,DN($CLJS.Fs,$CLJS.G(["field",xV,"value-or-field",xV,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ci,xV],null)])));var zV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aT],null);$CLJS.Y(eT,DN($CLJS.pI,$CLJS.G(["field",xV,"value-or-field",xV,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ci,xV],null)])));var AV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eT],null);$CLJS.Y($S,DN($CLJS.Cs,$CLJS.G(["field",yV,"value-or-field",yV])));
var BV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$S],null);$CLJS.Y(ZS,DN($CLJS.ys,$CLJS.G(["field",yV,"value-or-field",yV])));var CV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ZS],null);$CLJS.Y(OO,DN($CLJS.Es,$CLJS.G(["field",yV,"value-or-field",yV])));var DV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OO],null);$CLJS.Y(UO,DN($CLJS.As,$CLJS.G(["field",yV,"value-or-field",yV])));var EV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,UO],null);$CLJS.Y(JR,DN($CLJS.yH,$CLJS.G(["field",yV,"min",yV,"max",yV])));
var FV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JR],null);$CLJS.Y(lS,DN($CLJS.EH,$CLJS.G(["lat-field",yV,"lon-field",yV,"lat-max",yV,"lon-min",yV,"lat-min",yV,"lon-max",yV])));var GV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lS],null);$CLJS.Y(xP,DN($CLJS.xH,$CLJS.G(["field",$CLJS.oU])));var HV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,xP],null);$CLJS.Y(mQ,DN($CLJS.JH,$CLJS.G(["field",$CLJS.oU])));var IV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mQ],null);$CLJS.Y(PP,DN($CLJS.zH,$CLJS.G(["field",$CLJS.oU])));
var JV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PP],null);$CLJS.Y(VN,DN($CLJS.GH,$CLJS.G(["field",$CLJS.oU])));var KV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VN],null),LV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eI,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.wt],null)],null);$CLJS.Y(bQ,DN($CLJS.OH,$CLJS.G(["field",tU,"string-or-field",tU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,LV],null)])));var MV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bQ],null);
$CLJS.Y(VQ,DN($CLJS.hI,$CLJS.G(["field",tU,"string-or-field",tU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,LV],null)])));var NV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VQ],null);$CLJS.Y(NP,DN($CLJS.WH,$CLJS.G(["field",tU,"string-or-field",tU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,LV],null)])));var OV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NP],null);$CLJS.Y(DR,DN($CLJS.PH,$CLJS.G(["field",tU,"string-or-field",tU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,LV],null)])));
var PV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DR],null);$CLJS.Y(dO,DN($CLJS.iI,$CLJS.G(["field",$CLJS.oU,"n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,$CLJS.st,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bt,$CLJS.vx,$CLJS.Mz,$CLJS.DH],null)],null),"unit",eU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mI,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.wt],null)],null)],null)])));var QV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dO],null);
$CLJS.Y(UR,DN($CLJS.uH,$CLJS.G(["segment-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.uM],null),QT],null)])));var RV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,UR],null);
$CLJS.Y(cP,EN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof tV?new $CLJS.Fc(function(){return tV},$CLJS.od(OS,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wQ,"metabase/mbql/schema.cljc",15,$CLJS.mt,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.mt,mP,$CLJS.W($CLJS.zj,BQ),eR,$CLJS.W($CLJS.zj,BQ),wP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,BQ))],null),$CLJS.Jc,"Schema for a valid and clause.",$CLJS.n(tV)?tV.H:null])):null));return $CLJS.n(a)?a:wQ}(),tV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof uV?new $CLJS.Fc(function(){return uV},
$CLJS.od(iR,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lR,"metabase/mbql/schema.cljc",14,$CLJS.ft,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ft,mP,$CLJS.W($CLJS.zj,BQ),eR,$CLJS.W($CLJS.zj,BQ),wP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,BQ))],null),$CLJS.Jc,
"Schema for a valid or clause.",$CLJS.n(uV)?uV.H:null])):null));return $CLJS.n(a)?a:lR}(),uV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof vV?new $CLJS.Fc(function(){return vV},$CLJS.od(VR,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OQ,"metabase/mbql/schema.cljc",15,$CLJS.ht,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,$CLJS.BT,$CLJS.W($CLJS.zj,BQ)],null),$CLJS.Jc,"Schema for a valid not clause.",$CLJS.n(vV)?vV.H:null])):null));return $CLJS.n(a)?a:OQ}(),vV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof BV?new $CLJS.Fc(function(){return BV},$CLJS.od(rR,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,
$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",13,$CLJS.Cs,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Cs,$CLJS.XH,$CLJS.W($CLJS.zj,oP),zS,$CLJS.W($CLJS.zj,oP)],null),$CLJS.Jc,"Schema for a valid \x3c clause.",$CLJS.n(BV)?BV.H:null])):null));return $CLJS.n(a)?a:pQ}(),BV],null),
new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof DV?new $CLJS.Fc(function(){return DV},$CLJS.od(uT,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oQ,"metabase/mbql/schema.cljc",14,$CLJS.Es,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Es,$CLJS.XH,$CLJS.W($CLJS.zj,oP),zS,$CLJS.W($CLJS.zj,oP)],null),$CLJS.Jc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(DV)?DV.H:null])):null));return $CLJS.n(a)?a:oQ}(),DV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof CV?new $CLJS.Fc(function(){return CV},$CLJS.od(pR,new $CLJS.h(null,
1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",13,$CLJS.ys,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ys,$CLJS.XH,$CLJS.W($CLJS.zj,oP),zS,$CLJS.W($CLJS.zj,oP)],null),$CLJS.Jc,"Schema for a valid \x3e clause.",$CLJS.n(CV)?CV.H:null])):null));
return $CLJS.n(a)?a:qQ}(),CV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof EV?new $CLJS.Fc(function(){return EV},$CLJS.od(nT,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xQ,"metabase/mbql/schema.cljc",14,$CLJS.As,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.As,$CLJS.XH,$CLJS.W($CLJS.zj,oP),zS,$CLJS.W($CLJS.zj,oP)],null),$CLJS.Jc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(EV)?EV.H:null])):null));return $CLJS.n(a)?a:xQ}(),EV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof zV?new $CLJS.Fc(function(){return zV},$CLJS.od(qR,new $CLJS.h(null,
1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",13,$CLJS.Fs,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Fs,$CLJS.XH,$CLJS.W($CLJS.zj,zT),zS,$CLJS.W($CLJS.zj,zT),cS,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,zT))],null),$CLJS.Jc,"Schema for a valid \x3d clause.",
$CLJS.n(zV)?zV.H:null])):null));return $CLJS.n(a)?a:rQ}(),zV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof AV?new $CLJS.Fc(function(){return AV},$CLJS.od(EO,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$O,"metabase/mbql/schema.cljc",14,$CLJS.pI,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.pI,$CLJS.XH,$CLJS.W($CLJS.zj,zT),zS,$CLJS.W($CLJS.zj,zT),cS,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,zT))],null),$CLJS.Jc,"Schema for a valid !\x3d clause.",$CLJS.n(AV)?AV.H:null])):null));return $CLJS.n(a)?a:$O}(),AV],null)])));
$CLJS.Y(jR,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.uu,"valid filter expression",$CLJS.Bi,function(a){return $CLJS.n(CN(xU,a))?$CLJS.HK:$CLJS.n(CN(uU,a))?IT:$CLJS.n(CN(rU,a))?$CLJS.Ij:$CLJS.n(CN(vU,a))?$CLJS.wt:$CLJS.Ku}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HK,$CLJS.AU],null),new $CLJS.Q(null,2,5,$CLJS.R,[IT,yU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ij,sU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wt,zU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ku,EN($CLJS.G([new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof tV?new $CLJS.Fc(function(){return tV},$CLJS.od(OS,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wQ,"metabase/mbql/schema.cljc",
15,$CLJS.mt,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.mt,mP,$CLJS.W($CLJS.zj,BQ),eR,$CLJS.W($CLJS.zj,BQ),wP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,BQ))],null),$CLJS.Jc,"Schema for a valid and clause.",$CLJS.n(tV)?tV.H:null])):null));return $CLJS.n(a)?a:wQ}(),tV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof uV?new $CLJS.Fc(function(){return uV},$CLJS.od(iR,new $CLJS.h(null,1,[$CLJS.Kj,
!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lR,"metabase/mbql/schema.cljc",14,$CLJS.ft,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ft,mP,$CLJS.W($CLJS.zj,BQ),eR,$CLJS.W($CLJS.zj,BQ),wP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,BQ))],null),$CLJS.Jc,"Schema for a valid or clause.",$CLJS.n(uV)?
uV.H:null])):null));return $CLJS.n(a)?a:lR}(),uV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof vV?new $CLJS.Fc(function(){return vV},$CLJS.od(VR,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OQ,"metabase/mbql/schema.cljc",15,$CLJS.ht,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,$CLJS.BT,$CLJS.W($CLJS.zj,BQ)],null),$CLJS.Jc,"Schema for a valid not clause.",$CLJS.n(vV)?vV.H:null])):null));return $CLJS.n(a)?a:OQ}(),vV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof zV?new $CLJS.Fc(function(){return zV},$CLJS.od(qR,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,
$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",13,$CLJS.Fs,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Fs,$CLJS.XH,$CLJS.W($CLJS.zj,zT),zS,$CLJS.W($CLJS.zj,zT),cS,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,zT))],null),$CLJS.Jc,"Schema for a valid \x3d clause.",$CLJS.n(zV)?zV.H:null])):null));
return $CLJS.n(a)?a:rQ}(),zV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof AV?new $CLJS.Fc(function(){return AV},$CLJS.od(EO,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$O,"metabase/mbql/schema.cljc",14,$CLJS.pI,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.pI,$CLJS.XH,$CLJS.W($CLJS.zj,zT),zS,$CLJS.W($CLJS.zj,zT),cS,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,zT))],null),$CLJS.Jc,"Schema for a valid !\x3d clause.",$CLJS.n(AV)?AV.H:null])):null));return $CLJS.n(a)?a:$O}(),AV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof BV?new $CLJS.Fc(function(){return BV},
$CLJS.od(rR,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",13,$CLJS.Cs,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Cs,$CLJS.XH,$CLJS.W($CLJS.zj,oP),zS,$CLJS.W($CLJS.zj,oP)],null),$CLJS.Jc,"Schema for a valid \x3c clause.",
$CLJS.n(BV)?BV.H:null])):null));return $CLJS.n(a)?a:pQ}(),BV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof CV?new $CLJS.Fc(function(){return CV},$CLJS.od(pR,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qQ,"metabase/mbql/schema.cljc",13,$CLJS.ys,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ys,$CLJS.XH,$CLJS.W($CLJS.zj,oP),zS,$CLJS.W($CLJS.zj,oP)],null),$CLJS.Jc,"Schema for a valid \x3e clause.",$CLJS.n(CV)?CV.H:null])):null));return $CLJS.n(a)?a:qQ}(),CV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof DV?new $CLJS.Fc(function(){return DV},$CLJS.od(uT,new $CLJS.h(null,
1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",14,$CLJS.Es,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Es,$CLJS.XH,$CLJS.W($CLJS.zj,oP),zS,$CLJS.W($CLJS.zj,oP)],null),$CLJS.Jc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(DV)?DV.H:null])):
null));return $CLJS.n(a)?a:oQ}(),DV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof EV?new $CLJS.Fc(function(){return EV},$CLJS.od(nT,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xQ,"metabase/mbql/schema.cljc",14,$CLJS.As,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.As,$CLJS.XH,$CLJS.W($CLJS.zj,oP),zS,$CLJS.W($CLJS.zj,oP)],null),$CLJS.Jc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(EV)?EV.H:null])):null));return $CLJS.n(a)?a:xQ}(),EV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof FV?new $CLJS.Fc(function(){return FV},$CLJS.od(Yga,new $CLJS.h(null,
1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GO,"metabase/mbql/schema.cljc",19,$CLJS.yH,1,804,804,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.yH,$CLJS.XH,$CLJS.W($CLJS.zj,oP),$CLJS.Kn,$CLJS.W($CLJS.zj,oP),$CLJS.Rj,$CLJS.W($CLJS.zj,oP)],null),$CLJS.Jc,"Schema for a valid between clause.",
$CLJS.n(FV)?FV.H:null])):null));return $CLJS.n(a)?a:GO}(),FV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof MV?new $CLJS.Fc(function(){return MV},$CLJS.od($ga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ES,"metabase/mbql/schema.cljc",23,$CLJS.OH,1,829,829,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.OH,$CLJS.XH,$CLJS.W($CLJS.zj,yS),ZO,$CLJS.W($CLJS.zj,yS),$CLJS.Ri,$CLJS.W($CLJS.zj,$CLJS.W(KS,TR))],null),$CLJS.Jc,"Schema for a valid starts-with clause.",$CLJS.n(MV)?MV.H:null])):null));return $CLJS.n(a)?a:ES}(),MV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof NV?new $CLJS.Fc(function(){return NV},
$CLJS.od(bga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",21,$CLJS.hI,1,830,830,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.hI,$CLJS.XH,$CLJS.W($CLJS.zj,yS),ZO,$CLJS.W($CLJS.zj,yS),$CLJS.Ri,$CLJS.W($CLJS.zj,$CLJS.W(KS,TR))],null),
$CLJS.Jc,"Schema for a valid ends-with clause.",$CLJS.n(NV)?NV.H:null])):null));return $CLJS.n(a)?a:dP}(),NV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof OV?new $CLJS.Fc(function(){return OV},$CLJS.od(nga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",20,$CLJS.WH,1,831,831,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.WH,$CLJS.XH,$CLJS.W($CLJS.zj,yS),ZO,$CLJS.W($CLJS.zj,yS),$CLJS.Ri,$CLJS.W($CLJS.zj,$CLJS.W(KS,TR))],null),$CLJS.Jc,"Schema for a valid contains clause.",$CLJS.n(OV)?OV.H:null])):null));return $CLJS.n(a)?a:pP}(),OV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==
typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof PV?new $CLJS.Fc(function(){return PV},$CLJS.od(Sha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TN,"metabase/mbql/schema.cljc",36,$CLJS.PH,1,834,834,!0,new $CLJS.Q(null,
7,5,$CLJS.R,[$CLJS.PH,$CLJS.XH,$CLJS.W($CLJS.zj,yS),ZO,$CLJS.W($CLJS.zj,yS),$CLJS.Ri,$CLJS.W($CLJS.zj,$CLJS.W(KS,TR))],null),$CLJS.Jc,"Schema for a valid does-not-contain clause.",$CLJS.n(PV)?PV.H:null])):null));return $CLJS.n(a)?a:TN}(),PV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof GV?new $CLJS.Fc(function(){return GV},$CLJS.od(Bha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),
$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CS,"metabase/mbql/schema.cljc",26,$CLJS.EH,1,807,807,!0,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.EH,Kfa,$CLJS.W($CLJS.zj,oP),Wha,$CLJS.W($CLJS.zj,oP),Iha,$CLJS.W($CLJS.zj,oP),Nfa,$CLJS.W($CLJS.zj,oP),Cha,$CLJS.W($CLJS.zj,oP),Jfa,$CLJS.W($CLJS.zj,
oP)],null),$CLJS.Jc,"Schema for a valid inside clause.",$CLJS.n(GV)?GV.H:null])):null));return $CLJS.n(a)?a:CS}(),GV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof JV?new $CLJS.Fc(function(){return JV},$CLJS.od(oga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",28,$CLJS.zH,1,821,821,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zH,$CLJS.XH,$CLJS.W($CLJS.zj,hP)],null),$CLJS.Jc,"Schema for a valid is-empty clause.",$CLJS.n(JV)?JV.H:null])):null));return $CLJS.n(a)?a:XQ}(),JV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&
"undefined"!==typeof KV?new $CLJS.Fc(function(){return KV},$CLJS.od(mha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KR,"metabase/mbql/schema.cljc",29,$CLJS.GH,1,822,822,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GH,$CLJS.XH,$CLJS.W($CLJS.zj,hP)],null),
$CLJS.Jc,"Schema for a valid not-empty clause.",$CLJS.n(KV)?KV.H:null])):null));return $CLJS.n(a)?a:KR}(),KV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof HV?new $CLJS.Fc(function(){return HV},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",27,$CLJS.xH,1,816,816,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xH,$CLJS.XH,$CLJS.W($CLJS.zj,hP)],null),$CLJS.Jc,"Schema for a valid is-null clause.",$CLJS.n(HV)?HV.H:null])):null));return $CLJS.n(a)?a:vP}(),HV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&
"undefined"!==typeof IV?new $CLJS.Fc(function(){return IV},$CLJS.od(Aga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LO,"metabase/mbql/schema.cljc",28,$CLJS.JH,1,817,817,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JH,$CLJS.XH,$CLJS.W($CLJS.zj,hP)],null),
$CLJS.Jc,"Schema for a valid not-null clause.",$CLJS.n(IV)?IV.H:null])):null));return $CLJS.n(a)?a:LO}(),IV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof QV?new $CLJS.Fc(function(){return QV},$CLJS.od(Bfa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jS,"metabase/mbql/schema.cljc",33,$CLJS.iI,1,856,856,!0,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.iI,$CLJS.XH,$CLJS.W($CLJS.zj,hP),$CLJS.My,$CLJS.W($CLJS.zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,$CLJS.st,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bt,$CLJS.vx,$CLJS.Mz,$CLJS.DH],null)],null)),$CLJS.Sk,$CLJS.W($CLJS.zj,vQ),$CLJS.Ri,$CLJS.W($CLJS.zj,$CLJS.W(KS,cha))],null),$CLJS.Jc,"Schema for a valid time-interval clause.",
$CLJS.n(QV)?QV.H:null])):null));return $CLJS.n(a)?a:jS}(),QV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof RV?new $CLJS.Fc(function(){return RV},$CLJS.od(Iga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),lT,"metabase/mbql/schema.cljc",27,$CLJS.uH,1,872,872,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uH,Rfa,$CLJS.W($CLJS.zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,sha,xO],null))],null),$CLJS.Jc,"Schema for a valid segment clause.",$CLJS.n(RV)?RV.H:null])):null));return $CLJS.n(a)?a:lT}(),RV],null)]))],null)],null));
$CLJS.Y(WR,DN($CLJS.FH,$CLJS.G(["clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.uu,":case subclause"],null),$CLJS.sV,EU],null)],null)],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qj,new $CLJS.h(null,1,[$CLJS.uu,":case options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),EU],null)],null)],null)])));
var SV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,WR],null);
$CLJS.Y(DS,EN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof RU?new $CLJS.Fc(function(){return RU},$CLJS.od(tR,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hQ,"metabase/mbql/schema.cljc",51,$CLJS.Is,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Is,$CLJS.nG,$CLJS.W($CLJS.zj,xR),VS,$CLJS.W($CLJS.zj,xR),lP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,xR))],null),$CLJS.Jc,"Schema for a valid + clause.",$CLJS.n(RU)?RU.H:null])):null));return $CLJS.n(a)?a:hQ}(),RU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof SU?new $CLJS.Fc(function(){return SU},
$CLJS.od(Uga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iQ,"metabase/mbql/schema.cljc",51,$CLJS.ku,1,615,615,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ku,$CLJS.nG,$CLJS.W($CLJS.zj,tS),
VS,$CLJS.W($CLJS.zj,xR),lP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,xR))],null),$CLJS.Jc,"Schema for a valid - clause.",$CLJS.n(SU)?SU.H:null])):null));return $CLJS.n(a)?a:iQ}(),SU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN?new $CLJS.Fc(function(){return TU},$CLJS.od(Tga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,LQ,$CLJS.Fi,$CLJS.Zi,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,
new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.VB,"metabase/mbql/schema.cljc",$CLJS.tI,1,618,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.tI,$CLJS.nG,$CLJS.W($CLJS.zj,tS),VS,$CLJS.W($CLJS.zj,tS),lP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,tS))],null),$CLJS.Jc,"Schema for a valid / clause.",$CLJS.n(TU)?TU.H:null])):null));return $CLJS.n(a)?a:$CLJS.VB}(),TU],
null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof UU?new $CLJS.Fc(function(){return UU},$CLJS.od(Vga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gQ,"metabase/mbql/schema.cljc",51,$CLJS.Js,1,620,620,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Js,$CLJS.nG,$CLJS.W($CLJS.zj,tS),VS,$CLJS.W($CLJS.zj,tS),lP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,tS))],null),$CLJS.Jc,"Schema for a valid * clause.",$CLJS.n(UU)?UU.H:null])):null));return $CLJS.n(a)?a:gQ}(),UU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof GU?new $CLJS.Fc(function(){return GU},
$CLJS.od(gS,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vS,"metabase/mbql/schema.cljc",58,$CLJS.$H,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.$H,dT,$CLJS.W($CLJS.zj,YN),cT,$CLJS.W($CLJS.zj,
YN),lP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,YN))],null),$CLJS.Jc,"Schema for a valid coalesce clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:vS}(),GU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof IU?new $CLJS.Fc(function(){return IU},$CLJS.od(lha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,
$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NR,"metabase/mbql/schema.cljc",56,$CLJS.Gz,1,585,585,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Gz,$CLJS.cA,$CLJS.W($CLJS.zj,yS)],null),$CLJS.Jc,"Schema for a valid length clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:NR}(),IU],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof VU?new $CLJS.Fc(function(){return VU},$CLJS.od(cga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IQ,"metabase/mbql/schema.cljc",55,$CLJS.VH,1,622,622,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VH,$CLJS.nG,$CLJS.W($CLJS.zj,tS)],null),$CLJS.Jc,"Schema for a valid floor clause.",$CLJS.n(VU)?VU.H:null])):null));return $CLJS.n(a)?a:IQ}(),VU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof WU?new $CLJS.Fc(function(){return WU},$CLJS.od(Xfa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),
$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",54,$CLJS.sH,1,625,625,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sH,$CLJS.nG,$CLJS.W($CLJS.zj,tS)],null),$CLJS.Jc,"Schema for a valid ceil clause.",$CLJS.n(WU)?
WU.H:null])):null));return $CLJS.n(a)?a:wO}(),WU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof XU?new $CLJS.Fc(function(){return XU},$CLJS.od(kga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yQ,"metabase/mbql/schema.cljc",55,$CLJS.bI,1,628,628,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bI,$CLJS.nG,$CLJS.W($CLJS.zj,tS)],null),$CLJS.Jc,"Schema for a valid round clause.",$CLJS.n(XU)?XU.H:null])):null));return $CLJS.n(a)?a:yQ}(),XU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&
"undefined"!==typeof YU?new $CLJS.Fc(function(){return YU},$CLJS.od(Tha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aR,"metabase/mbql/schema.cljc",53,$CLJS.wH,1,631,631,new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.wH,$CLJS.nG,$CLJS.W($CLJS.zj,tS)],null),$CLJS.Jc,"Schema for a valid abs clause.",$CLJS.n(YU)?YU.H:null])):null));return $CLJS.n(a)?a:aR}(),YU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof ZU?new $CLJS.Fc(function(){return ZU},$CLJS.od(Gha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,
HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[rT,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NS,"metabase/mbql/schema.cljc",69,$CLJS.tH,1,634,634,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.tH,$CLJS.nG,$CLJS.W($CLJS.zj,tS),VS,$CLJS.W($CLJS.zj,tS)],null),$CLJS.Jc,"Schema for a valid power clause.",$CLJS.n(ZU)?ZU.H:null])):null));return $CLJS.n(a)?a:NS}(),ZU],
null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof $U?new $CLJS.Fc(function(){return $U},$CLJS.od(Dga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[rT,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cQ,"metabase/mbql/schema.cljc",68,$CLJS.KH,1,637,637,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KH,$CLJS.nG,$CLJS.W($CLJS.zj,tS)],null),$CLJS.Jc,"Schema for a valid sqrt clause.",$CLJS.n($U)?$U.H:null])):null));return $CLJS.n(a)?a:cQ}(),$U],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof aV?new $CLJS.Fc(function(){return aV},$CLJS.od(Hha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),
$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[rT,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AQ,"metabase/mbql/schema.cljc",67,$CLJS.pH,1,640,640,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pH,$CLJS.nG,$CLJS.W($CLJS.zj,tS)],null),$CLJS.Jc,"Schema for a valid exp clause.",$CLJS.n(aV)?
aV.H:null])):null));return $CLJS.n(a)?a:AQ}(),aV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof bV?new $CLJS.Fc(function(){return bV},$CLJS.od(Qfa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[rT,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wT,"metabase/mbql/schema.cljc",67,$CLJS.vI,1,643,643,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vI,$CLJS.nG,$CLJS.W($CLJS.zj,tS)],null),$CLJS.Jc,"Schema for a valid log clause.",$CLJS.n(bV)?bV.H:null])):null));return $CLJS.n(a)?a:wT}(),bV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&
"undefined"!==typeof SV?new $CLJS.Fc(function(){return SV},$CLJS.od(iO,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",61,$CLJS.FH,1,909,909,new $CLJS.Q(null,
5,5,$CLJS.R,[$CLJS.FH,$CLJS.Py,$CLJS.W($CLJS.zj,BP),$CLJS.Ri,$CLJS.W($CLJS.zj,$CLJS.W(KS,kR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(SV)?SV.H:null])):null));return $CLJS.n(a)?a:SQ}(),SV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof cV?new $CLJS.Fc(function(){return cV},$CLJS.od(Tfa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,
$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.DK,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bR,"metabase/mbql/schema.cljc",65,$CLJS.DK,1,656,656,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.DK,ega,$CLJS.W($CLJS.zj,HT),fga,$CLJS.W($CLJS.zj,HT),$CLJS.Sk,$CLJS.W($CLJS.zj,Lga)],null),$CLJS.Jc,"Schema for a valid datetime-diff clause.",
$CLJS.n(cV)?cV.H:null])):null));return $CLJS.n(a)?a:bR}(),cV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof dV?new $CLJS.Fc(function(){return dV},$CLJS.od(pha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.SK,"null"],null),null),$CLJS.od(rS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",71,$CLJS.SK,1,661,661,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.SK,$CLJS.HK,$CLJS.W($CLJS.zj,HT),$CLJS.Sk,$CLJS.W($CLJS.zj,Mha),$CLJS.ni,$CLJS.W($CLJS.zj,$CLJS.W(KS,PT))],null),$CLJS.Jc,"Schema for a valid temporal-extract clause.",$CLJS.n(dV)?dV.H:null])):null));return $CLJS.n(a)?a:uR}(),dV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof eV?new $CLJS.Fc(function(){return eV},$CLJS.od(wga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.SK,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TO,"metabase/mbql/schema.cljc",71,$CLJS.zK,1,667,667,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zK,$CLJS.dR,$CLJS.W($CLJS.zj,HT)],null),$CLJS.Jc,"Schema for a valid get-year clause.",$CLJS.n(eV)?eV.H:null])):null));return $CLJS.n(a)?a:TO}(),eV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof fV?new $CLJS.Fc(function(){return fV},$CLJS.od(lga,new $CLJS.h(null,1,[$CLJS.Kj,!0],
null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.SK,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sR,"metabase/mbql/schema.cljc",74,$CLJS.WK,1,670,670,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WK,$CLJS.dR,$CLJS.W($CLJS.zj,HT)],null),$CLJS.Jc,"Schema for a valid get-quarter clause.",
$CLJS.n(fV)?fV.H:null])):null));return $CLJS.n(a)?a:sR}(),fV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof gV?new $CLJS.Fc(function(){return gV},$CLJS.od(Sga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.SK,"null"],null),null),
$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PR,"metabase/mbql/schema.cljc",72,$CLJS.sK,1,673,673,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sK,$CLJS.dR,$CLJS.W($CLJS.zj,HT)],null),$CLJS.Jc,"Schema for a valid get-month clause.",$CLJS.n(gV)?gV.H:null])):null));return $CLJS.n(a)?a:PR}(),gV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==
typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof hV?new $CLJS.Fc(function(){return hV},$CLJS.od(Lha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.SK,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OR,"metabase/mbql/schema.cljc",
71,$CLJS.MK,1,676,676,!0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.MK,$CLJS.dR,$CLJS.W($CLJS.zj,HT),$CLJS.ni,$CLJS.W($CLJS.zj,$CLJS.W(KS,PT))],null),$CLJS.Jc,"Schema for a valid get-week clause.",$CLJS.n(hV)?hV.H:null])):null));return $CLJS.n(a)?a:OR}(),hV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof iV?new $CLJS.Fc(function(){return iV},$CLJS.od(Hfa,new $CLJS.h(null,1,[$CLJS.Kj,
!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.SK,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HR,"metabase/mbql/schema.cljc",70,$CLJS.LK,1,680,680,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LK,$CLJS.dR,$CLJS.W($CLJS.zj,HT)],null),$CLJS.Jc,"Schema for a valid get-day clause.",
$CLJS.n(iV)?iV.H:null])):null));return $CLJS.n(a)?a:HR}(),iV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof jV?new $CLJS.Fc(function(){return jV},$CLJS.od(Mga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.SK,"null"],null),null),
$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yR,"metabase/mbql/schema.cljc",78,$CLJS.QK,1,683,683,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QK,$CLJS.dR,$CLJS.W($CLJS.zj,HT)],null),$CLJS.Jc,"Schema for a valid get-day-of-week clause.",$CLJS.n(jV)?jV.H:null])):null));return $CLJS.n(a)?a:yR}(),jV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==
typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof kV?new $CLJS.Fc(function(){return kV},$CLJS.od(Kga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.SK,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZN,"metabase/mbql/schema.cljc",
71,$CLJS.TK,1,686,686,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TK,$CLJS.HK,$CLJS.W($CLJS.zj,HT)],null),$CLJS.Jc,"Schema for a valid get-hour clause.",$CLJS.n(kV)?kV.H:null])):null));return $CLJS.n(a)?a:ZN}(),kV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof lV?new $CLJS.Fc(function(){return lV},$CLJS.od(tha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,
$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.SK,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AT,"metabase/mbql/schema.cljc",73,$CLJS.PK,1,689,689,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PK,$CLJS.HK,$CLJS.W($CLJS.zj,HT)],null),$CLJS.Jc,"Schema for a valid get-minute clause.",$CLJS.n(lV)?lV.H:null])):null));
return $CLJS.n(a)?a:AT}(),lV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof mV?new $CLJS.Fc(function(){return mV},$CLJS.od(Hga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.SK,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UQ,"metabase/mbql/schema.cljc",73,$CLJS.yK,1,692,692,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yK,$CLJS.HK,$CLJS.W($CLJS.zj,HT)],null),$CLJS.Jc,"Schema for a valid get-second clause.",$CLJS.n(mV)?mV.H:null])):null));return $CLJS.n(a)?a:UQ}(),mV],null)])));
$CLJS.Y(oO,EN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof HU?new $CLJS.Fc(function(){return HU},$CLJS.od(jha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YR,"metabase/mbql/schema.cljc",59,$CLJS.qH,1,582,582,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qH,$CLJS.cA,$CLJS.W($CLJS.zj,yS),$CLJS.ax,$CLJS.W($CLJS.zj,hga),$CLJS.Gz,$CLJS.W($CLJS.zj,$CLJS.W(KS,tS))],null),$CLJS.Jc,"Schema for a valid substring clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:YR}(),HU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof JU?new $CLJS.Fc(function(){return JU},
$CLJS.od(Jha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uS,"metabase/mbql/schema.cljc",54,$CLJS.oI,1,588,588,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oI,$CLJS.cA,$CLJS.W($CLJS.zj,yS)],
null),$CLJS.Jc,"Schema for a valid trim clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:uS}(),JU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof LU?new $CLJS.Fc(function(){return LU},$CLJS.od(Nga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,
1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dS,"metabase/mbql/schema.cljc",55,$CLJS.qI,1,594,594,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,$CLJS.cA,$CLJS.W($CLJS.zj,yS)],null),$CLJS.Jc,"Schema for a valid ltrim clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:dS}(),LU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&
"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof KU?new $CLJS.Fc(function(){return KU},$CLJS.od(kha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fR,"metabase/mbql/schema.cljc",
55,$CLJS.xI,1,591,591,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xI,$CLJS.cA,$CLJS.W($CLJS.zj,yS)],null),$CLJS.Jc,"Schema for a valid rtrim clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:fR}(),KU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof OU?new $CLJS.Fc(function(){return OU},$CLJS.od(Cfa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,
$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fT,"metabase/mbql/schema.cljc",57,$CLJS.wI,1,603,603,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.wI,$CLJS.cA,$CLJS.W($CLJS.zj,yS),Ofa,$CLJS.W($CLJS.zj,$CLJS.Ij),$CLJS.bC,$CLJS.W($CLJS.zj,$CLJS.Ij)],null),$CLJS.Jc,"Schema for a valid replace clause.",
$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:fT}(),OU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof NU?new $CLJS.Fc(function(){return NU},$CLJS.od(nha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KT,"metabase/mbql/schema.cljc",55,$CLJS.Qu,1,600,600,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qu,$CLJS.cA,$CLJS.W($CLJS.zj,yS)],null),$CLJS.Jc,"Schema for a valid lower clause.",$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:KT}(),NU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==
typeof IN&&"undefined"!==typeof MU?new $CLJS.Fc(function(){return MU},$CLJS.od(Kha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HS,"metabase/mbql/schema.cljc",55,$CLJS.Iu,1,597,597,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Iu,$CLJS.cA,$CLJS.W($CLJS.zj,yS)],null),$CLJS.Jc,"Schema for a valid upper clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:HS}(),MU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof PU?new $CLJS.Fc(function(){return PU},$CLJS.od(bha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,
$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",56,$CLJS.vH,1,606,606,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.vH,dT,$CLJS.W($CLJS.zj,yS),cT,$CLJS.W($CLJS.zj,yS),lP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,yS))],null),$CLJS.Jc,"Schema for a valid concat clause.",$CLJS.n(PU)?
PU.H:null])):null));return $CLJS.n(a)?a:UP}(),PU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof QU?new $CLJS.Fc(function(){return QU},$CLJS.od(iha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.BR,"null",$CLJS.Tba,"null"],null),null),
$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",74,$CLJS.ZH,1,609,609,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ZH,$CLJS.cA,$CLJS.W($CLJS.zj,yS),$CLJS.aC,$CLJS.W($CLJS.zj,$CLJS.Ij)],null),$CLJS.Jc,"Schema for a valid regex-match-first clause.",$CLJS.n(QU)?QU.H:null])):null));return $CLJS.n(a)?a:RO}(),QU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==
typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof GU?new $CLJS.Fc(function(){return GU},$CLJS.od(gS,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.BR,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
vS,"metabase/mbql/schema.cljc",58,$CLJS.$H,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.$H,dT,$CLJS.W($CLJS.zj,YN),cT,$CLJS.W($CLJS.zj,YN),lP,$CLJS.W($CLJS.zj,$CLJS.W($CLJS.zi,YN))],null),$CLJS.Jc,"Schema for a valid coalesce clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:vS}(),GU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof SV?new $CLJS.Fc(function(){return SV},
$CLJS.od(iO,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",61,$CLJS.FH,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.FH,$CLJS.Py,$CLJS.W($CLJS.zj,BP),$CLJS.Ri,
$CLJS.W($CLJS.zj,$CLJS.W(KS,kR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(SV)?SV.H:null])):null));return $CLJS.n(a)?a:SQ}(),SV],null)])));
$CLJS.TV=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.uu,":field or :expression reference or expression",$CLJS.Bi,function(a){return $CLJS.n(CN(uU,a))?IT:$CLJS.n(CN(rU,a))?$CLJS.Ij:$CLJS.n(CN(vU,a))?$CLJS.wt:$CLJS.n(CN(xU,a))?$CLJS.HK:$CLJS.n(CN($CLJS.FH,a))?$CLJS.FH:$CLJS.Ku}],null),new $CLJS.Q(null,2,5,$CLJS.R,[IT,yU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ij,sU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wt,zU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HK,$CLJS.AU],null),
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FH,SV],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ku,$CLJS.oU],null)],null);$CLJS.Y(oT,DN($CLJS.Iz,$CLJS.G(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,$CLJS.oU],null)])));var UV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,oT],null);$CLJS.Y(cR,DN($CLJS.YH,$CLJS.G(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dt,$CLJS.oU],null)])));var VV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cR],null);$CLJS.Y(mO,DN($CLJS.RH,$CLJS.G(["field-or-expression",$CLJS.TV])));
var WV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mO],null);$CLJS.Y(gT,DN($CLJS.uI,$CLJS.G(["field-or-expression",$CLJS.TV])));var XV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gT],null);$CLJS.Y(MP,DN($CLJS.BH,$CLJS.G(["field-or-expression",$CLJS.TV])));var YV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MP],null);$CLJS.Y(wS,DN($CLJS.jI,$CLJS.G(["field-or-expression",$CLJS.TV])));var ZV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wS],null);$CLJS.Y(HP,DN($CLJS.Kn,$CLJS.G(["field-or-expression",$CLJS.TV])));
var $V=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HP],null);$CLJS.Y(tP,DN($CLJS.Rj,$CLJS.G(["field-or-expression",$CLJS.TV])));var aW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tP],null);$CLJS.Y(nP,DN($CLJS.sI,$CLJS.G(["field-or-expression",$CLJS.TV,"pred",$CLJS.sV])));var bW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nP],null);$CLJS.Y(EP,DN($CLJS.fI,$CLJS.G(["pred",$CLJS.sV])));var cW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EP],null);$CLJS.Y(nS,DN($CLJS.dI,$CLJS.G(["pred",$CLJS.sV])));
var dW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nS],null);$CLJS.Y(KO,DN($CLJS.nI,$CLJS.G(["field-or-expression",$CLJS.TV])));var eW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KO],null);$CLJS.Y(KN,DN($CLJS.CH,$CLJS.G(["field-or-expression",$CLJS.TV])));var fW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KN],null);$CLJS.Y(QP,DN($CLJS.kI,$CLJS.G(["field-or-expression",$CLJS.TV])));var gW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QP],null);$CLJS.Y(DQ,DN($CLJS.IH,$CLJS.G(["field-or-expression",$CLJS.TV,"percentile",CU])));
var hW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DQ],null);$CLJS.Y(qS,DN($CLJS.SH,$CLJS.G(["metric-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sM],null),QT],null)])));var iW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,qS],null);
$CLJS.Y(mS,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.uu,"unnamed aggregation clause or numeric expression",$CLJS.Bi,function(a){return $CLJS.n(CN(uU,a))?GQ:$CLJS.Ku}],null),new $CLJS.Q(null,2,5,$CLJS.R,[GQ,yU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ku,EN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof WV?new $CLJS.Fc(function(){return WV},$CLJS.od(hha,
new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",60,$CLJS.RH,1,957,957,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RH,IS,$CLJS.W($CLJS.zj,AS)],null),$CLJS.Jc,"Schema for a valid avg clause.",
$CLJS.n(WV)?WV.H:null])):null));return $CLJS.n(a)?a:PQ}(),WV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof XV?new $CLJS.Fc(function(){return XV},$CLJS.od(sga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),US,"metabase/mbql/schema.cljc",64,$CLJS.uI,1,958,958,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uI,IS,$CLJS.W($CLJS.zj,AS)],null),$CLJS.Jc,"Schema for a valid cum-sum clause.",$CLJS.n(XV)?XV.H:null])):null));return $CLJS.n(a)?a:US}(),XV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==
typeof IN&&"undefined"!==typeof YV?new $CLJS.Fc(function(){return YV},$CLJS.od(jga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",65,$CLJS.BH,1,959,959,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BH,IS,$CLJS.W($CLJS.zj,AS)],null),$CLJS.Jc,"Schema for a valid distinct clause.",$CLJS.n(YV)?YV.H:null])):null));return $CLJS.n(a)?a:TQ}(),YV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof eW?new $CLJS.Fc(function(){return eW},$CLJS.od(Dha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,
$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.gI,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fO,"metabase/mbql/schema.cljc",76,$CLJS.nI,1,973,973,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nI,IS,$CLJS.W($CLJS.zj,AS)],null),$CLJS.Jc,"Schema for a valid stddev clause.",$CLJS.n(eW)?eW.H:null])):null));return $CLJS.n(a)?a:fO}(),eW],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof ZV?new $CLJS.Fc(function(){return ZV},$CLJS.od(Xga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CO,"metabase/mbql/schema.cljc",60,$CLJS.jI,1,960,960,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jI,IS,$CLJS.W($CLJS.zj,AS)],null),$CLJS.Jc,"Schema for a valid sum clause.",$CLJS.n(ZV)?ZV.H:null])):null));return $CLJS.n(a)?a:CO}(),ZV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof $V?new $CLJS.Fc(function(){return $V},$CLJS.od(Lfa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,
$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.St,"metabase/mbql/schema.cljc",60,$CLJS.Kn,1,961,961,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Kn,IS,$CLJS.W($CLJS.zj,AS)],null),$CLJS.Jc,"Schema for a valid min clause.",$CLJS.n($V)?$V.H:
null])):null));return $CLJS.n(a)?a:$CLJS.St}(),$V],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof aW?new $CLJS.Fc(function(){return aW},$CLJS.od(Gfa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Xt,"metabase/mbql/schema.cljc",60,$CLJS.Rj,1,962,962,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rj,IS,$CLJS.W($CLJS.zj,AS)],null),$CLJS.Jc,"Schema for a valid max clause.",$CLJS.n(aW)?aW.H:null])):null));return $CLJS.n(a)?a:$CLJS.Xt}(),aW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&
"undefined"!==typeof iW?new $CLJS.Fc(function(){return iW},$CLJS.od(gga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",18,$CLJS.SH,1,994,994,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SH,tga,$CLJS.W($CLJS.zj,new $CLJS.Q(null,3,5,
$CLJS.R,[$CLJS.ft,xga,xO],null))],null),$CLJS.Jc,"Schema for a valid metric clause.",$CLJS.n(iW)?iW.H:null])):null));return $CLJS.n(a)?a:TP}(),iW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof dW?new $CLJS.Fc(function(){return dW},$CLJS.od(Fga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,
$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",62,$CLJS.dI,1,970,970,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dI,$CLJS.bo,$CLJS.W($CLJS.zj,BQ)],null),$CLJS.Jc,"Schema for a valid share clause.",$CLJS.n(dW)?dW.H:null])):null));return $CLJS.n(a)?a:LP}(),dW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof cW?new $CLJS.Fc(function(){return cW},$CLJS.od(Mfa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yO,"metabase/mbql/schema.cljc",68,$CLJS.fI,1,967,967,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fI,$CLJS.bo,$CLJS.W($CLJS.zj,BQ)],null),$CLJS.Jc,"Schema for a valid count-where clause.",$CLJS.n(cW)?cW.H:null])):null));return $CLJS.n(a)?a:yO}(),cW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof bW?new $CLJS.Fc(function(){return bW},$CLJS.od(Rha,new $CLJS.h(null,1,[$CLJS.Kj,!0],
null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QQ,"metabase/mbql/schema.cljc",66,$CLJS.sI,1,964,964,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.sI,IS,$CLJS.W($CLJS.zj,AS),$CLJS.bo,$CLJS.W($CLJS.zj,BQ)],null),$CLJS.Jc,"Schema for a valid sum-where clause.",
$CLJS.n(bW)?bW.H:null])):null));return $CLJS.n(a)?a:QQ}(),bW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof SV?new $CLJS.Fc(function(){return SV},$CLJS.od(iO,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",61,$CLJS.FH,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.FH,$CLJS.Py,$CLJS.W($CLJS.zj,BP),$CLJS.Ri,$CLJS.W($CLJS.zj,$CLJS.W(KS,kR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(SV)?SV.H:null])):null));return $CLJS.n(a)?a:SQ}(),SV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&
"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof gW?new $CLJS.Fc(function(){return gW},$CLJS.od(wha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.TH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SS,"metabase/mbql/schema.cljc",
68,$CLJS.kI,1,979,979,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kI,IS,$CLJS.W($CLJS.zj,AS)],null),$CLJS.Jc,"Schema for a valid median clause.",$CLJS.n(gW)?gW.H:null])):null));return $CLJS.n(a)?a:SS}(),gW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof hW?new $CLJS.Fc(function(){return hW},$CLJS.od(Rga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,
LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.TH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pS,"metabase/mbql/schema.cljc",72,$CLJS.IH,1,982,982,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.IH,IS,$CLJS.W($CLJS.zj,AS),$CLJS.IH,$CLJS.W($CLJS.zj,tS)],null),$CLJS.Jc,"Schema for a valid percentile clause.",$CLJS.n(hW)?hW.H:null])):
null));return $CLJS.n(a)?a:pS}(),hW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof fW?new $CLJS.Fc(function(){return fW},$CLJS.od(Wfa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aO,"metabase/mbql/schema.cljc",77,$CLJS.CH,1,976,976,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.CH,IS,$CLJS.W($CLJS.zj,AS)],null),$CLJS.Jc,"Schema for a valid var clause.",$CLJS.n(fW)?fW.H:null])):null));return $CLJS.n(a)?a:aO}(),fW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof VV?new $CLJS.Fc(function(){return VV},$CLJS.od(Ifa,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,
$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CQ,"metabase/mbql/schema.cljc",74,$CLJS.YH,1,947,947,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,$CLJS.XH,$CLJS.W($CLJS.zj,$CLJS.W(KS,hP))],null),$CLJS.Jc,"Schema for a valid cum-count clause.",
$CLJS.n(VV)?VV.H:null])):null));return $CLJS.n(a)?a:CQ}(),VV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof UV?new $CLJS.Fc(function(){return UV},$CLJS.od(iga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([JQ,$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,MS,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.rH,"null"],null),null),
$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.mi,"metabase/mbql/schema.cljc",70,$CLJS.Iz,1,946,946,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Iz,$CLJS.XH,$CLJS.W($CLJS.zj,$CLJS.W(KS,hP))],null),$CLJS.Jc,"Schema for a valid count clause.",$CLJS.n(UV)?UV.H:null])):null));return $CLJS.n(a)?a:$CLJS.mi}(),UV],null)]))],null)],null));
$CLJS.Y(ER,DN($CLJS.WN,$CLJS.G(["aggregation",mS,"options",new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Qj,new $CLJS.h(null,1,[$CLJS.uu,":aggregation-options options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),QT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aG,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),QT],null)],null)])));
$CLJS.Y(JS,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.uu,"aggregation clause or numeric expression",$CLJS.Bi,function(a){return $CLJS.n(CN($CLJS.WN,a))?$CLJS.WN:AP}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ER],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[AP,mS],null)],null));$CLJS.Y(eO,DN($CLJS.UH,$CLJS.G(["field",qU])));var jW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eO],null);$CLJS.Y(FQ,DN($CLJS.NH,$CLJS.G(["field",qU])));
kW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FQ],null);
gia=EN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof jW?new $CLJS.Fc(function(){return jW},$CLJS.od(Aha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
lQ,"metabase/mbql/schema.cljc",15,$CLJS.UH,1,1049,1049,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UH,$CLJS.XH,$CLJS.W($CLJS.zj,bT)],null),$CLJS.Jc,"Schema for a valid asc clause.",$CLJS.n(jW)?jW.H:null])):null));return $CLJS.n(a)?a:lQ}(),jW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof kW?new $CLJS.Fc(function(){return kW},$CLJS.od(rga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,
$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hS,"metabase/mbql/schema.cljc",16,$CLJS.NH,1,1050,1050,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NH,$CLJS.XH,$CLJS.W($CLJS.zj,bT)],null),$CLJS.Jc,"Schema for a valid desc clause.",$CLJS.n(kW)?kW.H:null])):null));return $CLJS.n(a)?a:hS}(),kW],null)]));
lW=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.bt,$CLJS.hO,$CLJS.YO,$CLJS.LN,$CLJS.dv,$CLJS.kD,$CLJS.dR],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,QT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aG,QT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),QT],null)],null);
hia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,lW,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.hO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UN,QT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vR,TT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wR,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),TT],null)],null)],null);
iia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,lW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.YO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FS,TT],null)],null)],null);
mW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,lW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.xt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zP,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.wt],null)],null)],null);jia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TS],null);
kia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,mW,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.LN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.LN,nU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sP,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BO],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.Ii,$CLJS.xt],null)],null)],null)],null);
$CLJS.nW=new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.dR,null,$CLJS.dv,null,$CLJS.wt,null,$CLJS.kD,null],null),null);lia=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.bt],null),$CLJS.nW);
$CLJS.Y(aS,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Bi,$CLJS.Yi],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.LN,kia],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hO,hia],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YO,iia],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Lo,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,mW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,lia],null)],null)],null)],null)],null));
var oW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iS,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,QT,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aS],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Ie(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OP,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,QT],null)],null)],null),mia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,oW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jO,$CLJS.xt],null)],null)],null),pW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IR],null),qW=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Bi,function(a){return $CLJS.n($CLJS.pN($CLJS.wd,
$CLJS.LR)(a))?$CLJS.LR:$CLJS.$F}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.LR,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,oW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.LR,$CLJS.xt],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$F,pW],null)],null),rW=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,QT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TE,RT],null),new $CLJS.Q(null,2,5,$CLJS.R,[Zga,QT],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.fQ,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,ST],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uP,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,$CLJS.Qj],null)],null)],null),sW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,Zha,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.uu,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),nia=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.bt],null),
new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.XG,null,$CLJS.FG,null,$CLJS.QG,null,$CLJS.UG,null],null),null)),tW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,RS],null);
$CLJS.Y(KP,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HQ,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),sW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kS,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),qW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sT,$CLJS.sV],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rG,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),nia],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GG,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.ft,new $CLJS.h(null,1,[$CLJS.uu,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bt,$CLJS.nA,$CLJS.WB],null),tW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KG,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),QT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DM,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,VT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tQ,new $CLJS.h(null,
1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kj,rW],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.pN($CLJS.pC($CLJS.HQ,$CLJS.kS),$CLJS.Ke($CLJS.pN($CLJS.HQ,$CLJS.kS)))],null)],null));var oia=$CLJS.R,uW;var vW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KP],null)],null);
if($CLJS.vd(vW)&&$CLJS.F.g($CLJS.B(vW),$CLJS.kj)){var wW=$CLJS.z(vW);$CLJS.B(wW);var xW=$CLJS.C(wW),pia=$CLJS.wd($CLJS.B(xW))?xW:$CLJS.ce(null,xW),yW=$CLJS.z(pia),qia=$CLJS.B(yW),ria=$CLJS.C(yW);uW=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kj,$CLJS.S.j(qia,$CLJS.Kn,1)],null),ria)}else uW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,vW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"non-empty"],null),$CLJS.z],null)],null);
$CLJS.Y(MO,new $CLJS.Q(null,3,5,oia,[$CLJS.mt,uW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"All join aliases must be unique."],null),function(a){return $CLJS.nN($CLJS.bf($CLJS.Ta,$CLJS.Ze.g($CLJS.KG,a)))}],null)],null));var sia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MO],null);
$CLJS.Y(RS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bl,new $CLJS.h(null,1,[$CLJS.uu,"Distinct, non-empty sequence of Field clauses"],null),FN(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.Kn,1],null),$CLJS.oU],null))],null));
$CLJS.Y(IR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kS,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),qW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HQ,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),sW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MH,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.Kn,1],null),$CLJS.BU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LH,new $CLJS.h(null,1,
[$CLJS.Dt,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.Kn,1],null),$CLJS.oU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BR,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,QT,$CLJS.TV],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GG,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),tW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AH,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.sV],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NT,new $CLJS.h(null,
1,[$CLJS.Dt,!0],null),UT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qT,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),FN(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.Kn,1],null),gia],null))],null),new $CLJS.Q(null,3,5,$CLJS.R,[MR,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[MR,TT],null),new $CLJS.Q(null,2,5,$CLJS.R,[aga,TT],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fP,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),sia],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tQ,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kj,rW],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.F.g(1,$CLJS.E($CLJS.Ql(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kS,$CLJS.HQ],null))))}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,
new $CLJS.h(null,1,[$CLJS.uu,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.LH);b=$CLJS.I.g(b,$CLJS.GG);return $CLJS.sd($CLJS.zv.g($CLJS.Yg(a),$CLJS.Yg(b)))}],null)],null));
$CLJS.zW=$CLJS.Ag([$CLJS.rI,$CLJS.dR,GP,rP,uQ,ET,aQ,$CLJS.DT,$CLJS.dv,$P,pO,xS,tT,$CLJS.FO,pT,DP,$CLJS.zQ,YP,$CLJS.Ki,$CLJS.XP,XN,$CLJS.bP,$CLJS.WP,ZQ,$CLJS.wt,$CLJS.kD,SP],[new $CLJS.h(null,1,[kQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.rI,null,$CLJS.dR,null,$CLJS.dv,null,$CLJS.wt,null,$CLJS.kD,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.dR,kQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.rI,null,$CLJS.dR,null,$CLJS.DT,null,$CLJS.zQ,null,$CLJS.Ki,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Yi,$CLJS.Ij,$CLJS.iG,GS,kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[GP,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Yi,IT,$CLJS.iG,GS,kQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.rI,null,rP,null,$CLJS.dv,null,$CLJS.Ki,null,XN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Yi,$CLJS.Ij,$CLJS.iG,tO,kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[uQ,null],null),null)],null),new $CLJS.h(null,1,[kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[ET,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Yi,
IT,$CLJS.iG,qha,kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[aQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.dR,kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.DT,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Yi,IT,kQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.rI,null,rP,null,$CLJS.dv,null,$CLJS.Ki,null,XN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Yi,IT,$CLJS.iG,tO,kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[$P,null],null),null)],null),new $CLJS.h(null,1,[kQ,new $CLJS.Tg(null,
new $CLJS.h(null,1,[pO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Yi,$CLJS.Ij,$CLJS.iG,GS,kQ,new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.rI,null,ET,null,pO,null,xS,null,YP,null,$CLJS.Ki,null,XN,null,$CLJS.kD,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Yi,$CLJS.Ij,$CLJS.iG,tO,kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[tT,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.dR,kQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.DT,null,$CLJS.FO,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Yi,$CLJS.Ij,$CLJS.iG,tO,kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[pT,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Yi,$CLJS.Ij,$CLJS.iG,tO,kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[DP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.dR,kQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.rI,null,$CLJS.dR,null,$CLJS.DT,null,$CLJS.zQ,null,$CLJS.Ki,null],null),null)],null),new $CLJS.h(null,1,[kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[YP,null],null),null)],null),new $CLJS.h(null,1,[kQ,
new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.Ki,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.dR,kQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.DT,null,$CLJS.XP,null],null),null)],null),new $CLJS.h(null,1,[kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[XN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.dR,kQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.DT,null,$CLJS.bP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.dR,kQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.DT,
null,$CLJS.WP,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Yi,IT,$CLJS.iG,GS,kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[ZQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.wt,kQ,new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.rI,null,$CLJS.Ki,null,$CLJS.wt,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.Ij,kQ,new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.rI,null,ET,null,pO,null,xS,null,YP,null,$CLJS.Ki,null,XN,null,$CLJS.kD,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Yi,
IT,$CLJS.iG,tO,kQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[SP,null],null),null)],null)]);$CLJS.Y(TS,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"valid parameter type"],null)],null),$CLJS.Pg($CLJS.zW)));$CLJS.Y(BO,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"valid template tag widget type"],null),$CLJS.WB],null),$CLJS.Pg($CLJS.zW)));
$CLJS.Y(fS,DN(Sfa,$CLJS.G(["tag-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,QT,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ki,QT],null)],null)],null)])));var AW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fS],null);$CLJS.Y($N,DN($CLJS.LN,$CLJS.G(["target",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,$CLJS.oU,AW],null)])));var BW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$N],null);$CLJS.Y(WO,DN(VP,$CLJS.G(["target",AW])));
var CW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,WO],null),tia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,$CLJS.oU,EN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof BW?new $CLJS.Fc(function(){return BW},$CLJS.od(Pga,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QR,"metabase/mbql/schema.cljc",21,$CLJS.LN,1,1612,1612,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LN,$CLJS.ZK,$CLJS.W($CLJS.zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,hP,jQ],null))],null),$CLJS.Jc,"Schema for a valid dimension clause.",$CLJS.n(BW)?BW.H:null])):null));return $CLJS.n(a)?a:QR}(),BW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=LQ.h($CLJS.pd("undefined"!==typeof $CLJS.GN&&
"undefined"!==typeof HN&&"undefined"!==typeof IN&&"undefined"!==typeof CW?new $CLJS.Fc(function(){return CW},$CLJS.od(eha,new $CLJS.h(null,1,[$CLJS.Kj,!0],null)),$CLJS.Ag([$CLJS.Hi,$CLJS.T,$CLJS.Vj,$CLJS.li,LQ,$CLJS.Fi,$CLJS.Zi,$CLJS.ck,HO,$CLJS.ki,$CLJS.U,$CLJS.Uj],[$CLJS.od(rS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JT,"metabase/mbql/schema.cljc",20,VP,1,1615,1615,new $CLJS.Q(null,3,5,$CLJS.R,[VP,$CLJS.ZK,
$CLJS.W($CLJS.zj,jQ)],null),$CLJS.Jc,"Schema for a valid variable clause.",$CLJS.n(CW)?CW.H:null])):null));return $CLJS.n(a)?a:JT}(),CW],null)]))],null),zN=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IO],null);
$CLJS.Y(IO,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.mt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.pN($CLJS.pC($CLJS.LR,$CLJS.jO),$CLJS.Ke($CLJS.pN($CLJS.LR,$CLJS.jO)))],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.LR);var d=$CLJS.I.g(c,$CLJS.jO);c=$CLJS.I.g(c,$CLJS.Yi);c=c instanceof $CLJS.K?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ft,new $CLJS.h(null,1,[$CLJS.uu,"valid Database ID"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.HM],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,$CLJS.CM],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bt,$CLJS.jO,$CLJS.LR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LR,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),mia],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jO,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),pW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XO,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kj,new $CLJS.Q(null,
8,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,jia],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),QT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZK,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),tia],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oj,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.xt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),QT],null),new $CLJS.Q(null,3,5,$CLJS.R,[Yfa,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),
QT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.xt],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FM,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[Pha,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),cU],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Oga,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[RQ,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),UT],null),new $CLJS.Q(null,3,5,$CLJS.R,[AO,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),UT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,RQ);b=$CLJS.I.g(b,AO);return $CLJS.Sa($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Ega,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[zha,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.wt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Ufa,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.wt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Dfa,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.wt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$fa,new $CLJS.h(null,1,[$CLJS.Dt,
!0],null),$CLJS.wt],null),new $CLJS.Q(null,3,5,$CLJS.R,[rha,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,$CLJS.wt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Ffa,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,$CLJS.wt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Fha,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,$CLJS.wt],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xA,new $CLJS.h(null,
1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SO,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.bt,dha,aha,$CLJS.OP,Qga,fha,Cga,Pfa,Vfa,dga,qga,oha,Xha,uga,mga],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[uha,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,TT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[zga,
new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,TT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FS,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,Yha],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Qha,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,QT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Bga,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,TT],null)],null),new $CLJS.Q(null,
3,5,$CLJS.R,[Oha,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.xt,$CLJS.xt],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[vga,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,TT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RP,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.xt,
$CLJS.xt],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qM,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,$CLJS.xt],null)],null)],null)],null)],null)],null)),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Ke($CLJS.tQ)],null)],null));var DW=$CLJS.yN(zN);
(function(){var a=yfa();return function(b){if($CLJS.n(DW.h?DW.h(b):DW.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.vN(b);throw $CLJS.ai($CLJS.WF("Invalid query: {0}",$CLJS.G([$CLJS.Eh.l($CLJS.G([c]))])),new $CLJS.h(null,2,[$CLJS.tA,c,Jga,b],null));}})();
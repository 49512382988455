var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var VX,Qia,Ria,cY,Sia,Tia,Via,ZX,Wia,Uia;VX=function(a){return a+1};$CLJS.WX=function(a){if("string"===typeof a){var b=$CLJS.fh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.Vl(a));};
Qia=function(a){var b=new $CLJS.Ea,c=$CLJS.Rv;$CLJS.Rv=new $CLJS.sc(b);try{var d=$CLJS.Rv,e=$CLJS.Sa($CLJS.hx(d)),f=$CLJS.Rv;$CLJS.Rv=e?$CLJS.Zw(d):d;try{d=$CLJS.jx;$CLJS.jx=!0;try{$CLJS.lx(a)}finally{$CLJS.jx=d}$CLJS.F.g(0,$CLJS.Zv($CLJS.Rv,$CLJS.aw))||$CLJS.$b($CLJS.Rv,"\n");$CLJS.Yv()}finally{$CLJS.Rv=f}$CLJS.mh($CLJS.p.h(b))}finally{$CLJS.Rv=c}};$CLJS.XX=function(a,b){return $CLJS.P.j($CLJS.ha,a,b)};
$CLJS.YX=function(a){var b=new $CLJS.Ea,c=$CLJS.Oa,d=$CLJS.Pa;$CLJS.Oa=!0;$CLJS.Pa=function(e){return b.append(e)};try{Qia(a)}finally{$CLJS.Pa=d,$CLJS.Oa=c}return $CLJS.p.h(b)};
Ria=function(a){a=$CLJS.O(a);var b=$CLJS.I.j(a,$CLJS.fX,$CLJS.Rd),c=$CLJS.I.j(a,$CLJS.dX,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Oe($CLJS.N),e=$CLJS.Oe($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null),D=$CLJS.q(d);return D.h?D.h(A):D.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.I.g($CLJS.Gh.v(e,$CLJS.wk,u,$CLJS.GF(VX,0)),u);if($CLJS.F.g(x,1))return $CLJS.Gh.v(d,$CLJS.S,
new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null),t),t;t=function(){var A=t,D=$CLJS.p.h(x);return c.g?c.g(A,D):c.call(null,A,D)}()}}function k(m){var t=l,u=t.g;null==ZX&&(ZX=$CLJS.Oe(0));var v=$CLJS.xh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Gh.g(ZX,VX))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.$X=function(a){return $CLJS.yd(a)&&1<$CLJS.E(a)&&$CLJS.B(a)instanceof $CLJS.K&&$CLJS.wd($CLJS.hd(a))&&$CLJS.Gd($CLJS.hd(a),$CLJS.mG)};$CLJS.aY=function(a){return $CLJS.$X(a)?$CLJS.qC(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,$CLJS.OG],null)):null};$CLJS.bY=function(a,b){return $CLJS.wN($CLJS.$X(a)?a:new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oj,new $CLJS.h(null,2,[$CLJS.mG,$CLJS.p.h($CLJS.xG()),$CLJS.ti,$CLJS.BG(a)],null),a],null),new $CLJS.Q(null,2,5,$CLJS.R,[1,$CLJS.OG],null),b)};
cY=function(a,b,c){var d=$CLJS.Gd(a,b)?$CLJS.wk.j(a,b,function(e){return $CLJS.yd(e)&&$CLJS.F.g($CLJS.B(e),$CLJS.mt)?$CLJS.uf($CLJS.Te(1,e)):new $CLJS.Q(null,1,5,$CLJS.R,[e],null)}):a;return $CLJS.Gd(a,b)?$CLJS.bX(d,$CLJS.Ce([b,c])):d};Sia=function(a){var b=$CLJS.Ql(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HQ,$CLJS.kS],null));b=dY.h?dY.h(b):dY.call(null,b);return $CLJS.SX($CLJS.S.l(cY($CLJS.lk.l(a,$CLJS.HQ,$CLJS.G([$CLJS.kS])),$CLJS.sT,$CLJS.VM),$CLJS.nv,$CLJS.NM,$CLJS.G([$CLJS.SM,b])))};
Tia=function(a){return $CLJS.vk.g(Sia,a)};Via=function(a){return $CLJS.n(a)?$CLJS.S.j($CLJS.wk.j($CLJS.xl(a)?new $CLJS.h(null,1,[$CLJS.eY,a],null):a,$CLJS.eY,function(b){return $CLJS.vk.g(function(c){return $CLJS.S.j($CLJS.rC(c,$CLJS.SC),$CLJS.nv,$CLJS.rM)},b)}),$CLJS.nv,Uia):null};
$CLJS.fY=function(a){if($CLJS.F.g($CLJS.nv.h(a),$CLJS.JX))return a;var b=$CLJS.Yi.h(a);b=b instanceof $CLJS.K?b.T:null;switch(b){case "native":return $CLJS.Lk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.nv,$CLJS.JX,$CLJS.SM,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Lk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.nv,$CLJS.DX],null),$CLJS.bX($CLJS.LR.h(a),new $CLJS.h(null,1,[$CLJS.jO,$CLJS.LR],null))]))],null)],null),$CLJS.lk.l(a,$CLJS.Yi,$CLJS.G([$CLJS.LR]))]));case "query":return $CLJS.Lk.l($CLJS.G([new $CLJS.h(null,
2,[$CLJS.nv,$CLJS.JX,$CLJS.SM,dY($CLJS.jO.h(a))],null),$CLJS.lk.l(a,$CLJS.Yi,$CLJS.G([$CLJS.jO]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.gY=function(a,b){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.SM);var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.ai($CLJS.WF("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.h(null,1,[Wia,$CLJS.E(a)],null));return c};
$CLJS.hY=function(a,b){a=$CLJS.fY(a);a=$CLJS.O(a);var c=$CLJS.I.g(a,$CLJS.SM);return $CLJS.I.g($CLJS.uf(c),$CLJS.gY(a,b))};$CLJS.iY=function(a){return"string"===typeof a&&(a=$CLJS.gh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.H(a,0,null),a=$CLJS.H(a,1,null),$CLJS.WX(a)):null};$CLJS.kY=function(){return $CLJS.tk.g(jY,Ria($CLJS.G([$CLJS.fX,$CLJS.OC,$CLJS.dX,function(a,b){return jY.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};ZX=null;
$CLJS.lY=new $CLJS.K("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.mY=new $CLJS.K(null,"join","join",-758861890);$CLJS.nY=new $CLJS.K(null,"stage-number","stage-number",-1752729638);$CLJS.oY=new $CLJS.K(null,"stage","stage",1843544772);Wia=new $CLJS.K(null,"num-stages","num-stages",1426797535);$CLJS.eY=new $CLJS.K(null,"columns","columns",1998437288);$CLJS.pY=new $CLJS.K(null,"table","table",-564943036);
Uia=new $CLJS.K("metadata","results","metadata/results",330329298);var dY,jY;
dY=function dY(a){a=$CLJS.O(a);var c=$CLJS.I.g(a,$CLJS.kS),d=$CLJS.I.g(a,$CLJS.tQ);c=$CLJS.n(c)?dY.h?dY.h(c):dY.call(null,c):$CLJS.tf;d=Via(d);var e=$CLJS.E(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.wN(c,new $CLJS.Q(null,2,5,$CLJS.R,[e,$CLJS.PW],null),d):c;d=$CLJS.n($CLJS.LR.h(a))?$CLJS.DX:$CLJS.wX;a=$CLJS.Lk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.nv,d],null),$CLJS.lk.l(a,$CLJS.kS,$CLJS.G([$CLJS.tQ]))]));a=$CLJS.z($CLJS.fP.h(a))?$CLJS.wk.j(a,$CLJS.fP,Tia):a;a=cY(a,$CLJS.AH,$CLJS.LX);return $CLJS.$d.g(c,a)};
$CLJS.qY=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.y(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.fY(c);c=$CLJS.O(c);var k=$CLJS.I.g(c,$CLJS.SM);d=$CLJS.gY(c,d);e=$CLJS.P.N($CLJS.wk,$CLJS.uf(k),d,e,f);return $CLJS.S.j(c,$CLJS.SM,e)}a.A=3;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.C(c);var f=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,f,c)};a.l=b;
return a}();
jY=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.QX.str(d,0)>>>0).toString(16);;)if(8>$CLJS.E(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.rY=RegExp(" id$","i");
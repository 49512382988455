var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var Tla,Ula,r3,s3,Vla,t3,u3;
$CLJS.n3=function(a){return $CLJS.n($CLJS.V0.g?$CLJS.V0.g($CLJS.O0,a):$CLJS.V0.call(null,$CLJS.O0,a))?$CLJS.$2:$CLJS.n($CLJS.V0.g?$CLJS.V0.g($CLJS.K0,a):$CLJS.V0.call(null,$CLJS.K0,a))?$CLJS.$2:$CLJS.n($CLJS.V0.g?$CLJS.V0.g($CLJS.H0,a):$CLJS.V0.call(null,$CLJS.H0,a))?$CLJS.Dla:$CLJS.n($CLJS.V0.g?$CLJS.V0.g($CLJS.R0,a):$CLJS.V0.call(null,$CLJS.R0,a))?$CLJS.Ela:$CLJS.n($CLJS.V0.g?$CLJS.V0.g($CLJS.Q0,a):$CLJS.V0.call(null,$CLJS.Q0,a))?$CLJS.Fla:$CLJS.n($CLJS.V0.g?$CLJS.V0.g($CLJS.G0,a):$CLJS.V0.call(null,
$CLJS.G0,a))?$CLJS.Gla:$CLJS.n($CLJS.V0.g?$CLJS.V0.g($CLJS.L0,a):$CLJS.V0.call(null,$CLJS.L0,a))?$CLJS.Jla:$CLJS.n($CLJS.V0.g?$CLJS.V0.g($CLJS.D0,a):$CLJS.V0.call(null,$CLJS.D0,a))?$CLJS.Hla:$CLJS.n($CLJS.V0.g?$CLJS.V0.g($CLJS.F0,a):$CLJS.V0.call(null,$CLJS.F0,a))?$CLJS.Ila:$CLJS.Kla};$CLJS.o3=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.K?a.T:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.p3=function(a,b){a=$CLJS.o3(a);b=$CLJS.n(b)?b:$CLJS.pv;return 0===a?$CLJS.F.g(b,$CLJS.pv)?$CLJS.yG("Today"):$CLJS.WF("This {0}",$CLJS.G([$CLJS.i3.h(b)])):$CLJS.F.g(a,1)?$CLJS.F.g(b,$CLJS.pv)?$CLJS.yG("Tomorrow"):$CLJS.WF("Next {0}",$CLJS.G([$CLJS.i3.h(b)])):$CLJS.F.g(a,-1)?$CLJS.F.g(b,$CLJS.pv)?$CLJS.yG("Yesterday"):$CLJS.WF("Previous {0}",$CLJS.G([$CLJS.i3.h(b)])):0>a?$CLJS.WF("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.i3.g(Math.abs(a),b)])):0<a?$CLJS.WF("Next {0} {1}",$CLJS.G([a,$CLJS.i3.g(a,
b)])):null};$CLJS.q3=function(a){var b=$CLJS.n3(a);b=$CLJS.De(b);return null==b?a:$CLJS.S.j(a,$CLJS.$M,b)};Tla=new $CLJS.K(null,"legacy-filter","legacy-filter",-948552546);Ula=new $CLJS.K(null,"query-filters","query-filters",409521440);r3=new $CLJS.K("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);s3=new $CLJS.K("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);Vla=new $CLJS.K(null,"matching-filters","matching-filters",-326129659);
t3=new $CLJS.K("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);u3=new $CLJS.K("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var v3=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mt,$CLJS.ft],null)),w3=null,x3=0,y3=0;;)if(y3<x3){var Wla=w3.X(null,y3);$CLJS.fH(Wla,t3);y3+=1}else{var z3=$CLJS.z(v3);if(z3){var A3=z3;if($CLJS.zd(A3)){var B3=$CLJS.kc(A3),Xla=$CLJS.lc(A3),Yla=B3,Zla=$CLJS.E(B3);v3=Xla;w3=Yla;x3=Zla}else{var $la=$CLJS.B(A3);$CLJS.fH($la,t3);v3=$CLJS.C(A3);w3=null;x3=0}y3=0}else break}
for(var C3=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.pI],null)),D3=null,E3=0,F3=0;;)if(F3<E3){var ama=D3.X(null,F3);$CLJS.fH(ama,s3);F3+=1}else{var G3=$CLJS.z(C3);if(G3){var H3=G3;if($CLJS.zd(H3)){var I3=$CLJS.kc(H3),bma=$CLJS.lc(H3),cma=I3,dma=$CLJS.E(I3);C3=bma;D3=cma;E3=dma}else{var ema=$CLJS.B(H3);$CLJS.fH(ema,s3);C3=$CLJS.C(H3);D3=null;E3=0}F3=0}else break}
for(var J3=$CLJS.z(new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Cs,$CLJS.Es,$CLJS.ys,$CLJS.As,$CLJS.OH,$CLJS.hI,$CLJS.WH,$CLJS.PH],null)),K3=null,L3=0,M3=0;;)if(M3<L3){var fma=K3.X(null,M3);$CLJS.fH(fma,u3);M3+=1}else{var N3=$CLJS.z(J3);if(N3){var O3=N3;if($CLJS.zd(O3)){var P3=$CLJS.kc(O3),gma=$CLJS.lc(O3),hma=P3,ima=$CLJS.E(P3);J3=gma;K3=hma;L3=ima}else{var jma=$CLJS.B(O3);$CLJS.fH(jma,u3);J3=$CLJS.C(O3);K3=null;L3=0}M3=0}else break}
for(var Q3=$CLJS.z(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.xH,$CLJS.JH,$CLJS.zH,$CLJS.GH,$CLJS.ht],null)),R3=null,S3=0,T3=0;;)if(T3<S3){var kma=R3.X(null,T3);$CLJS.fH(kma,r3);T3+=1}else{var U3=$CLJS.z(Q3);if(U3){var V3=U3;if($CLJS.zd(V3)){var W3=$CLJS.kc(V3),lma=$CLJS.lc(V3),mma=W3,nma=$CLJS.E(W3);Q3=lma;R3=mma;S3=nma}else{var oma=$CLJS.B(V3);$CLJS.fH(oma,r3);Q3=$CLJS.C(V3);R3=null;S3=0}T3=0}else break}
$CLJS.T1.m(null,$CLJS.LX,function(a,b){var c=$CLJS.De($CLJS.LX.h($CLJS.hY(a,b)));return $CLJS.n(c)?$CLJS.WF("Filtered by {0}",$CLJS.G([$CLJS.f3($CLJS.yG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.s1.v(a,b,v,$CLJS.t1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);
return $CLJS.ce($CLJS.s1.v(a,b,t,$CLJS.t1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}())])):null});
$CLJS.Q1.m(null,t3,function(a,b,c,d){c=$CLJS.z(c);var e=$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var f=$CLJS.C(c);return $CLJS.f3(function(){var k=e instanceof $CLJS.K?e.T:null;switch(k){case "and":return $CLJS.yG("and");case "or":return $CLJS.yG("or");default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}(),function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<
v){var D=$CLJS.kd(u,A);D=$CLJS.s1.v(a,b,D,d);x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.s1.v(a,b,x,d),m($CLJS.Kc(t)))}return null}},null,null)}(f)}())});
$CLJS.Q1.m(null,s3,function(a,b,c,d){var e=$CLJS.z(c);c=$CLJS.B(e);e=$CLJS.C(e);$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.Ze.g(function(k){return $CLJS.s1.v(a,b,k,d)},f);if($CLJS.F.g($CLJS.E(f),2))switch(f=$CLJS.H(e,0,null),e=$CLJS.H(e,1,null),c=c instanceof $CLJS.K?c.T:null,c){case "\x3d":return $CLJS.WF("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.WF("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}else switch(f=$CLJS.B(e),e=$CLJS.f3($CLJS.yG("or"),
$CLJS.Kc(e)),c=c instanceof $CLJS.K?c.T:null,c){case "\x3d":return $CLJS.WF("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.WF("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.Q1.m(null,u3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);var f=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);f=$CLJS.s1.v(a,b,f,d);a=$CLJS.s1.v(a,b,c,d);e=e instanceof $CLJS.K?e.T:null;switch(e){case "\x3c":return $CLJS.WF("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.WF("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.WF("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.WF("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.WF("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.WF("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.WF("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.WF("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.Q1.m(null,$CLJS.yH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.WF("{0} is between {1} and {2}",$CLJS.G([$CLJS.s1.v(a,b,e,d),$CLJS.s1.v(a,b,f,d),$CLJS.s1.v(a,b,c,d)]))});
$CLJS.Q1.m(null,$CLJS.EH,function(a,b,c,d){$CLJS.H(c,0,null);var e=$CLJS.H(c,1,null),f=$CLJS.H(c,2,null),k=$CLJS.H(c,3,null),l=$CLJS.H(c,4,null),m=$CLJS.H(c,5,null),t=$CLJS.H(c,6,null);c=$CLJS.H(c,7,null);return $CLJS.s1.v(a,b,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.mt,e,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.yH,e,f,t,l],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.yH,e,k,m,c],null)],null),d)});
$CLJS.Q1.m(null,r3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.s1.v(a,b,c,d);e=e instanceof $CLJS.K?e.T:null;switch(e){case "is-null":return $CLJS.WF("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.WF("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.WF("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.WF("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.WF("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.h(e)].join(""));}});$CLJS.Q1.m(null,$CLJS.iI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.WF("{0} is within {1}",$CLJS.G([$CLJS.s1.v(a,b,e,d),$CLJS.p3(f,c).toLowerCase()]))});
$CLJS.X3=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){return $CLJS.e3($CLJS.Fs,$CLJS.ce(c,$CLJS.ce(d,e)))}a.A=2;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.Y3=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.SD(f),$CLJS.vM))f=$CLJS.TX(f);else return e=$CLJS.n(e)?e:-1,f=$CLJS.UX.h(f),$CLJS.qY.l(d,e,$CLJS.wk,$CLJS.G([$CLJS.LX,$CLJS.GF($CLJS.$d,$CLJS.tf),f]))}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Z3=function(){function a(d,e){return $CLJS.De($CLJS.LX.h($CLJS.hY(d,$CLJS.n(e)?e:-1)))}function b(d){return c.g?c.g(d,null):c.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.$3=function(){function a(d,e){var f=$CLJS.hY(d,e);return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.tk.g($CLJS.Ze.h($CLJS.q3),$CLJS.af($CLJS.$M)),$CLJS.a2.j(d,e,f)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.a4=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){c=$CLJS.wd(c)?$CLJS.QH.h(c):$CLJS.rh.h(c);return $CLJS.SX($CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.N,$CLJS.UX.h(d)],null),$CLJS.Ze.g($CLJS.UX,e)))}a.A=2;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.pma=function(){function a(d,e,f){var k=$CLJS.H(f,0,null);$CLJS.H(f,1,null);f=$CLJS.H(f,2,null);var l=$CLJS.hY(d,e);l=$CLJS.a2.j(d,e,l);d=$CLJS.W2.v(d,e,f,l);d=$CLJS.hJ(function(m){return $CLJS.F.g($CLJS.QH.h(m),k)},$CLJS.n3(d));return $CLJS.n(d)?d:$CLJS.Z2.h(k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;
c.j=a;return c}();
$CLJS.qma=function(){function a(d,e,f){var k=$CLJS.e_(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jO,$CLJS.AH],null),f);d=$CLJS.uf($CLJS.Z3.g(d,e));e=$CLJS.bf(function(l){return $CLJS.F.g($CLJS.e_(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jO,$CLJS.AH],null),$CLJS.o_.h(l)),k)},d);if($CLJS.z(e)){if($CLJS.C(e))throw $CLJS.ai("Multiple matching filters found",new $CLJS.h(null,3,[Tla,k,Ula,d,Vla,e],null));return $CLJS.B(e)}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,
e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.rma=function(){function a(d,e,f){f=$CLJS.U_.j(d,e,f);d=$CLJS.$3.g(d,e);return $CLJS.W2.g(f,d)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
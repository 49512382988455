var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Ada,Bda,Cda,Dda,Eda,Fda,Gda,Hda,Ida;Ada=function(a,b){a.sort(b||$CLJS.Ca)};Bda=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Ca;Ada(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.II=function(a){return $CLJS.F.g(a,$CLJS.Hd)?$CLJS.Hd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.JI=function(a,b){if($CLJS.z(b)){var c=$CLJS.ue.h?$CLJS.ue.h(b):$CLJS.ue.call(null,b);Bda(c,$CLJS.II(a));return $CLJS.od($CLJS.z(c),$CLJS.pd(b))}return $CLJS.Jc};$CLJS.KI=new $CLJS.K(null,"second","second",-444702010);$CLJS.LI=new $CLJS.K(null,"second-of-minute","second-of-minute",222734326);
$CLJS.MI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.NI=new $CLJS.K(null,"millisecond","millisecond",-540123566);$CLJS.OI=new $CLJS.K(null,"year-of-era","year-of-era",682445876);Cda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
Dda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.PI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.QI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.RI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);Eda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);Fda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
Gda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Hda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.SI=new $CLJS.K("option","temporal-bucketing","option/temporal-bucketing",-108914562);
$CLJS.TI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);Ida=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var UI=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Vk,$CLJS.Xk,$CLJS.pj,$CLJS.hi,$CLJS.Yk,$CLJS.Uk,$CLJS.Rk,$CLJS.OI],null),VI=$CLJS.Yg(UI),Jda,Kda,Lda;$CLJS.Y(Gda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid date extraction unit"],null)],null),VI));var WI=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.pv,$CLJS.$i,$CLJS.ij,$CLJS.gi,$CLJS.Rk],null),XI=$CLJS.Yg(WI);
$CLJS.Y(Fda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid date truncation unit"],null)],null),XI));$CLJS.YI=$CLJS.Wf.j($CLJS.tf,$CLJS.Nk.o(),$CLJS.Ye.g(WI,UI));Jda=$CLJS.Yg($CLJS.YI);$CLJS.Y($CLJS.RI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid date bucketing unit"],null)],null),Jda));var ZI=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LI,$CLJS.Wk,$CLJS.Tk],null),$I=$CLJS.Yg(ZI);
$CLJS.Y(Eda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid time extraction unit"],null)],null),$I));var aJ=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.NI,$CLJS.KI,$CLJS.mv,$CLJS.ov],null),bJ=$CLJS.Yg(aJ);$CLJS.Y(Ida,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid time truncation unit"],null)],null),bJ));$CLJS.cJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Nk.o(),$CLJS.Ye.g(aJ,ZI));Kda=$CLJS.Yg($CLJS.cJ);
$CLJS.Y(Dda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid time bucketing unit"],null)],null),Kda));$CLJS.dJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Nk.o(),$CLJS.Ye.l(aJ,WI,$CLJS.G([ZI,UI])));Lda=$CLJS.Yg($CLJS.dJ);$CLJS.Y($CLJS.MI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid datetime bucketing unit"],null)],null),$CLJS.dJ));var Mda=$CLJS.$d.g(Lda,$CLJS.Wh);
$CLJS.Y($CLJS.UF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid temporal bucketing unit"],null)],null),Mda));var Nda=$CLJS.qv.g(XI,bJ);$CLJS.Y($CLJS.PI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid datetime truncation unit"],null)],null),Nda));$CLJS.eJ=$CLJS.qv.g(VI,$I);$CLJS.Y(Cda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid datetime extraction unit"],null)],null),$CLJS.eJ));
var fJ=$CLJS.$d.g(XI,$CLJS.Rk);$CLJS.Y($CLJS.TI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid date interval unit"],null)],null),fJ));$CLJS.Y($CLJS.QI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid time interval unit"],null)],null),bJ));var Oda=$CLJS.qv.g(fJ,bJ);$CLJS.Y($CLJS.TF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid datetime interval unit"],null)],null),Oda));
$CLJS.Y(Hda,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.SI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sk,$CLJS.UF],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.wt],null)],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var wY,Xia,yY,AY,BY,Yia,DY,EY,vY,FY,Zia,$ia,aja,bja,IY,JY,LY,MY,NY,OY,PY,QY,xY,dja,eja,TY,fja,UY,gja,WY,hja,ija,jja,kja,lja,sY;$CLJS.tY=function(a,b){if("string"===typeof b)return sY(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.uY=function(a,b,c){var d=$CLJS.Rl(a,b);if($CLJS.n(d)){var e=$CLJS.S.j;d=$CLJS.zb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.S,a,b,c)}return a};
wY=function(a){var b=vY;return $CLJS.vC(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};Xia=function(a){var b=xY;return $CLJS.vC(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};yY=function(a){if($CLJS.md(a))return a;throw $CLJS.ai("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.ui,a],null));};
$CLJS.zY=function(a,b,c){return $CLJS.wd(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.kd(l,A),J=$CLJS.H(D,0,null),M=$CLJS.H(D,1,null);D=t;var V=$CLJS.R,Z=J;J=$CLJS.$d.g(b,J);J=a.g?a.g(J,M):a.call(null,J,M);D.add(new $CLJS.Q(null,2,5,V,[Z,J],null));A+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[v,function(){var A=$CLJS.$d.g(b,v),D=x;return a.g?a.g(A,D):a.call(null,A,D)}()],null),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):$CLJS.vd(c)?$CLJS.vk.g($CLJS.Me(a,$CLJS.B(c)instanceof $CLJS.K?$CLJS.$d.g(b,$CLJS.B(c)):b),c):c};AY=function(a){var b=(b=a instanceof $CLJS.K)?$CLJS.ge(a):b;return $CLJS.n(b)?[$CLJS.ge(a),"/",$CLJS.Zg(a)].join(""):$CLJS.Zg(a)};
BY=function(a,b){var c=$CLJS.vd(b)&&!$CLJS.qf(b)&&$CLJS.B(b)instanceof $CLJS.K;return c?$CLJS.td(a)?(b=$CLJS.B(b),a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c};
Yia=function(a,b){return $CLJS.$e(function(c){var d=$CLJS.bf;a:try{if($CLJS.yd(c)&&1<=$CLJS.E(c))try{var e=$CLJS.Ak.j(c,0,1);if($CLJS.yd(e)&&1===$CLJS.E(e))try{var f=$CLJS.bd(e,0);if($CLJS.n($CLJS.Me($CLJS.F,a)(f))){var k=$CLJS.Ak.g(c,1);var l=new $CLJS.Q(null,1,5,$CLJS.R,[k],null)}else throw $CLJS.UW;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.UW)throw $CLJS.UW;throw m;}throw t;}else throw $CLJS.UW;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.UW)throw $CLJS.UW;throw m;}throw t;}else throw $CLJS.UW;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.UW){l=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[c],null)],null);break a}throw m;}throw t;}return $CLJS.B($CLJS.z(d($CLJS.Ta,l)))},$CLJS.G([b]))};
DY=function(a,b){for(;;)switch(b=$CLJS.Nk.h($CLJS.bf($CLJS.Ta,b)),$CLJS.E(b)){case 0:return null;case 1:return a=$CLJS.B(b),CY.h?CY.h(a):CY.call(null,a);default:if($CLJS.n($CLJS.Je($CLJS.Me(BY,a),b))){var c=a;b=Yia(a,b);a=c}else{c=$CLJS.Ze.g(CY,b);if($CLJS.F.g(c,b))return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),b);b=c}}};EY=function(a){var b=$CLJS.vd(a);return b?(b=!$CLJS.qf(a))?$CLJS.pC($CLJS.ee,$CLJS.Va)($CLJS.B(a)):b:b};
vY=function(a){return $CLJS.n($CLJS.pC($CLJS.ee,$CLJS.Va)(a))?$CLJS.rh.h($CLJS.tC(AY(a).toLowerCase(),/_/,"-")):a};FY=function(a,b){var c=EY(b);return $CLJS.n(c)?(b=vY($CLJS.B(b)),$CLJS.td(a)?(a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,b)):c};Zia=function(a){return $CLJS.I.j(new $CLJS.h(null,3,[$CLJS.Wh,$CLJS.Rd,$CLJS.Yi,vY,$CLJS.sP,vY],null),a,function(b){var c=$CLJS.I,d=c.g;b=$CLJS.Ce([a,b]);b=GY.g?GY.g(b,HY):GY.call(null,b,HY);return d.call(c,b,a)})};
$ia=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=vY(c);var d=Zia(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.F.g($CLJS.Yi.h(a),$CLJS.LN)&&$CLJS.Sa($CLJS.sP.h(a))?$CLJS.S.j(a,$CLJS.sP,$CLJS.rI):a};aja=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=AY(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.S.j($ia(b),$CLJS.T,c)],null)}),a)};
bja=function(a){a=GY.h?GY.h(a):GY.call(null,a);return xY.h?xY.h(a):xY.call(null,a)};IY=function(a){return $CLJS.uY($CLJS.uY($CLJS.ab(function(b,c){return $CLJS.uY(b,c,$CLJS.rh)},a,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.TE,$CLJS.Kca,$CLJS.fQ,cja,$CLJS.RB,$CLJS.Sk],null)),$CLJS.TD,bja),$CLJS.uP,$CLJS.YW)};JY=function(a){return $CLJS.Ed(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,a,null],null):a};LY=function(a){return KY.h(JY(a))};
MY=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return CY($CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.Ze.g(KY,b)))};NY=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[a,LY(b)],null),$CLJS.Ze.g(KY,c))};OY=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(a)?new $CLJS.Q(null,2,5,$CLJS.R,[b,LY(a)],null):new $CLJS.Q(null,1,5,$CLJS.R,[b],null)};
PY=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,LY(a)],null)};QY=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,KY.h(a)],null)};
xY=function(a){return RY(function(b){if($CLJS.wd(b))return Xia(b);if($CLJS.Sa(EY(b)))return b;try{return KY.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.zC($CLJS.tA);if($CLJS.n($CLJS.yC("metabase.mbql.normalize",d))){var e=$CLJS.yG("Invalid clause:");e instanceof Error?$CLJS.xC("metabase.mbql.normalize",d,$CLJS.fy.l($CLJS.G([b])),e):$CLJS.xC("metabase.mbql.normalize",d,$CLJS.fy.l($CLJS.G([e,b])),null)}throw $CLJS.$h($CLJS.WF("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.VW(c)])),new $CLJS.h(null,
1,[$CLJS.BT,b],null),c);}throw f;}},a)};dja=function(a){return $CLJS.uf($CLJS.Ol(xY,SY(a)))};eja=function(a){for(;;)if($CLJS.n(EY(a)))a=new $CLJS.Q(null,1,5,$CLJS.R,[a],null);else return $CLJS.De($CLJS.vk.g(JY,a))};TY=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.LN);return $CLJS.n(b)?$CLJS.wk.j(a,$CLJS.LN,KY):a};
fja=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,TY(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,
2,5,$CLJS.R,[l,TY(f)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())};UY=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.iS);return $CLJS.n(b)?$CLJS.wk.j(a,$CLJS.iS,fja):a};gja=function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.LR);b=$CLJS.Sa(a)?VY.h?VY.h(b):VY.call(null,b):b;return $CLJS.n(a)?UY(b):b};WY=function(a){return $CLJS.td(a)?$CLJS.z(a):null!=a};
hja=function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.jO);var c=$CLJS.I.g(b,$CLJS.XO),d=$CLJS.I.g(b,$CLJS.tQ),e=$CLJS.I.g(b,$CLJS.LR);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.I.g(f,$CLJS.tQ);var l=$CLJS.wN($CLJS.lk.g(f,$CLJS.tQ),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jO,$CLJS.tQ],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.wk.j(l,$CLJS.jO,VY):l,t=$CLJS.n(c)?$CLJS.wk.j(m,$CLJS.XO,$CLJS.Me($CLJS.vk,xY)):m,u=$CLJS.n(e)?$CLJS.wk.j(t,$CLJS.LR,UY):t;return xY(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.$h($CLJS.WF("Error canonicalizing query: {0}",
$CLJS.G([$CLJS.VW(m)])),new $CLJS.h(null,1,[$CLJS.jO,a],null),m);throw v;}};
ija=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.jO),c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.LH);c=$CLJS.I.g(c,$CLJS.GG);return $CLJS.z(b)&&$CLJS.z(c)?(b=$CLJS.Wf.j($CLJS.Ug,$CLJS.eE,$CLJS.z($CLJS.bf($CLJS.Ta,function k(e,f){try{if($CLJS.yd(f)&&3===$CLJS.E(f))try{var l=$CLJS.bd(f,0);if($CLJS.fe(l,$CLJS.XH)){var m=$CLJS.bd(f,1),t=$CLJS.bd(f,2);return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[f,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,m,$CLJS.lk.g(t,$CLJS.cI)],null)],null)],null)}throw $CLJS.UW;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.UW)throw $CLJS.UW;throw l;}throw u;}else throw $CLJS.UW;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.UW)return $CLJS.cX(k,e,f);throw l;}throw u;}}($CLJS.tf,b)))),$CLJS.XY.j(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jO,$CLJS.GG],null),$CLJS.tk.g($CLJS.uf,$CLJS.Me($CLJS.uk,b)))):a};
jja=function(a){try{return ija(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.$h($CLJS.yG("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.jO,a],null),b);}throw c;}};
kja=function(a,b){var c=$CLJS.Wf.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.le(null,function(){for(var k=e;;)if(k=$CLJS.z(k)){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.kd(l,A),J=$CLJS.H(D,0,null);D=$CLJS.H(D,1,null);var M=$CLJS.$d.g(b,J);D=YY.g?YY.g(D,M):YY.call(null,D,M);null!=D&&t.add(new $CLJS.Q(null,2,5,$CLJS.R,[J,D],null));A+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);u=function(){var A=x,D=$CLJS.$d.g(b,v);return YY.g?YY.g(A,D):YY.call(null,A,D)}();if(null!=u)return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null),f($CLJS.Kc(k)));k=$CLJS.Kc(k)}else return null},null,null)}(a)}());return $CLJS.z(c)?c:null};lja=function(a,b){a=$CLJS.vk.g(function(c){var d=$CLJS.$d.g(b,ZY);return YY.g?YY.g(c,d):YY.call(null,c,d)},a);return $CLJS.n($CLJS.Je($CLJS.Ta,a))?a:null};
sY=function sY(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.uf(d);return $CLJS.ce(f,new $CLJS.le(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),sY.g?sY.g(a,k):sY.call(null,a,k)):null},null,null))};
$CLJS.XY=function XY(a){switch(arguments.length){case 3:return XY.j(arguments[0],arguments[1],arguments[2]);case 4:return XY.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return XY.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return XY.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return XY.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.XY.j=function(a,b,c){var d=$CLJS.z(b);b=$CLJS.B(d);if(d=$CLJS.C(d))a=$CLJS.S.j(a,b,$CLJS.XY.j($CLJS.I.g(a,b),d,c));else{d=$CLJS.S.j;var e=$CLJS.I.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.S,a,b,c)}return a};
$CLJS.XY.v=function(a,b,c,d){var e=$CLJS.z(b);b=$CLJS.B(e);if(e=$CLJS.C(e))a=$CLJS.S.j(a,b,$CLJS.XY.v($CLJS.I.g(a,b),e,c,d));else{e=$CLJS.S.j;var f=$CLJS.I.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.S,a,b,c)}return a};$CLJS.XY.N=function(a,b,c,d,e){var f=$CLJS.z(b);b=$CLJS.B(f);if(f=$CLJS.C(f))a=$CLJS.S.j(a,b,$CLJS.XY.N($CLJS.I.g(a,b),f,c,d,e));else{f=$CLJS.S.j;var k=$CLJS.I.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.S,a,b,c)}return a};
$CLJS.XY.W=function(a,b,c,d,e,f){var k=$CLJS.z(b);b=$CLJS.B(k);if(k=$CLJS.C(k))a=$CLJS.S.j(a,b,$CLJS.XY.W($CLJS.I.g(a,b),k,c,d,e,f));else{k=$CLJS.S.j;var l=$CLJS.I.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.S,a,b,c)}return a};$CLJS.XY.l=function(a,b,c,d,e,f,k){var l=$CLJS.z(b);b=$CLJS.B(l);return(l=$CLJS.C(l))?$CLJS.S.j(a,b,$CLJS.P.l($CLJS.XY,$CLJS.I.g(a,b),l,c,d,$CLJS.G([e,f,k]))):$CLJS.S.j(a,b,$CLJS.P.l(c,$CLJS.I.g(a,b),d,e,f,$CLJS.G([k])))};
$CLJS.XY.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var k=$CLJS.C(f);f=$CLJS.B(k);k=$CLJS.C(k);return this.l(b,a,c,d,e,f,k)};$CLJS.XY.A=6;
var RY=function RY(a,b){return $CLJS.WW($CLJS.Me(RY,a),$CLJS.Rd,a.h?a.h(b):a.call(null,b))},CY=function CY(a){for(;;){if($CLJS.wd(a))return $CLJS.Eu(a,CY);if($CLJS.Cd(a))a=$CLJS.uf(a);else if($CLJS.yd(a)){if($CLJS.Sa($CLJS.Je($CLJS.Ta,a)))return null;var c=a,d=$CLJS.z(c),e=$CLJS.B(d),f=$CLJS.C(d),k=e,l=f,m=$CLJS.H(l,0,null),t=l,u=k,v=u instanceof $CLJS.K?u.T:null;switch(v){case "not":if($CLJS.xl(m)){var x=$CLJS.B(m),A=x instanceof $CLJS.K?x.T:null;switch(A){case "not":a=$CLJS.hd(m);continue;case "and":return DY($CLJS.ft,
$CLJS.Ze.g(function(){return function(D){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,D],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Kc(m)));case "or":return DY($CLJS.mt,$CLJS.Ze.g(function(){return function(D){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,D],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Kc(m)));default:return a}}else return a;case "and":return DY($CLJS.mt,t);case "or":return DY($CLJS.ft,t);default:return $CLJS.vk.g(CY,a)}}else return a}},mja=new $CLJS.K(null,"value_field","value_field",
-980977878),$Y=new $CLJS.K(null,"ascending","ascending",-988350486),aZ=new $CLJS.K(null,"named","named",-422393479),bZ=new $CLJS.K(null,"values_source_config","values_source_config",-590570309),cZ=new $CLJS.K(null,"descending","descending",-24766135),dZ=new $CLJS.K(null,"binning-strategy","binning-strategy",2063329158),eZ=new $CLJS.K(null,"datetime-field","datetime-field",21731696),nja=new $CLJS.K(null,"use-as-display-name?","use-as-display-name?",686752941),fZ=new $CLJS.K(null,"field-literal","field-literal",
-1295883554),gZ=new $CLJS.K(null,"viz-settings","viz-settings",256055379),oja=new $CLJS.K(null,"rows","rows",850049680),pja=new $CLJS.K(null,"special-fn","special-fn",1290649344),ZY=new $CLJS.K("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),HY=new $CLJS.K(null,"ignore-path","ignore-path",944069061),qja=new $CLJS.K(null,"label_field","label_field",-1573182765),rja=new $CLJS.K(null,"fk-\x3e","fk-\x3e",-499026738),sja=new $CLJS.K(null,"joined-field","joined-field",
-2048778268),cja=new $CLJS.K(null,"visibility_type","visibility_type",-508434247);var hZ,tja=$CLJS.Oe($CLJS.N),uja=$CLJS.Oe($CLJS.N),vja=$CLJS.Oe($CLJS.N),wja=$CLJS.Oe($CLJS.N),xja=$CLJS.I.j($CLJS.N,$CLJS.Ti,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));hZ=new $CLJS.Xh($CLJS.xh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.tk.g(vY,$CLJS.B),xja,tja,uja,vja,wja);hZ.m(null,$CLJS.MH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MH,b],null);return null!=a?$CLJS.$d.g(b,a):b});
hZ.m(null,$CLJS.dD,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dD,a instanceof $CLJS.K?AY(a):a],null)});hZ.m(null,dZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?$CLJS.$d.g(hZ.h(new $CLJS.Q(null,3,5,$CLJS.R,[dZ,b,c],null)),a):new $CLJS.Q(null,3,5,$CLJS.R,[dZ,GY.g?GY.g(b,HY):GY.call(null,b,HY),vY(c)],null)});
hZ.m(null,$CLJS.XH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=GY.g?GY.g(a,HY):GY.call(null,a,HY);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,b,function(){var d=$CLJS.n($CLJS.gD.h(c))?$CLJS.wk.j(c,$CLJS.gD,$CLJS.rh):c;d=$CLJS.n($CLJS.cI.h(c))?$CLJS.wk.j(d,$CLJS.cI,$CLJS.rh):d;return $CLJS.n($CLJS.XS.h(c))?$CLJS.wk.j(d,$CLJS.XS,function(e){return $CLJS.n($CLJS.rG.h(e))?$CLJS.wk.j(e,$CLJS.rG,$CLJS.rh):e}):d}()],null)});
hZ.m(null,fZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[fZ,b instanceof $CLJS.K?AY(b):b,$CLJS.rh.h(a)],null)});hZ.m(null,eZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[eZ,GY.g?GY.g(b,HY):GY.call(null,b,HY),$CLJS.iu,vY(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[eZ,GY.g?GY.g(b,HY):GY.call(null,b,HY),vY(c)],null)});
hZ.m(null,$CLJS.iI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);return $CLJS.n(a)?$CLJS.$d.g(hZ.h(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.iI,b,c,d],null)),GY.g?GY.g(a,HY):GY.call(null,a,HY)):new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.iI,GY.g?GY.g(b,HY):GY.call(null,b,HY),$CLJS.Ed(c)?c:vY(c),vY(d)],null)});
hZ.m(null,$CLJS.GK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GK,b,vY(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GK,$CLJS.vx],null)});hZ.m(null,$CLJS.EK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.EK,b,vY(a)],null)});
hZ.m(null,$CLJS.OK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.OK,GY.g?GY.g(b,HY):GY.call(null,b,HY),c,vY(a)],null)});hZ.m(null,$CLJS.UK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.UK,GY.g?GY.g(b,HY):GY.call(null,b,HY),c,vY(a)],null)});
hZ.m(null,$CLJS.MK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MK,GY.g?GY.g(b,HY):GY.call(null,b,HY),vY(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MK,GY.g?GY.g(b,HY):GY.call(null,b,HY)],null)});
hZ.m(null,$CLJS.SK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.SK,GY.g?GY.g(b,HY):GY.call(null,b,HY),vY(c),vY(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SK,GY.g?GY.g(b,HY):GY.call(null,b,HY),vY(c)],null)});
hZ.m(null,$CLJS.DK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.DK,GY.g?GY.g(b,HY):GY.call(null,b,HY),GY.g?GY.g(c,HY):GY.call(null,c,HY),vY(a)],null)});hZ.m(null,$CLJS.oj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oj,b,a],null)});
hZ.m(null,$CLJS.Wh,function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[vY(a)],null),$CLJS.Ze.h(function(c){return GY.g?GY.g(c,HY):GY.call(null,c,HY)}),b)});
var iZ=function iZ(a){if($CLJS.n($CLJS.pC($CLJS.ee,$CLJS.Va)(a))){var c=vY(a);var d=new $CLJS.Tg(null,new $CLJS.h(null,18,[$CLJS.Kn,null,$CLJS.Js,null,$CLJS.nI,null,$CLJS.fI,null,$CLJS.ku,null,$CLJS.YH,null,$CLJS.sI,null,$CLJS.tI,null,$CLJS.BH,null,$CLJS.IH,null,$CLJS.Is,null,$CLJS.CH,null,$CLJS.kI,null,$CLJS.dI,null,$CLJS.Rj,null,$CLJS.Iz,null,$CLJS.RH,null,$CLJS.jI,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(EY(a))?(a=$CLJS.B(a),iZ.h?iZ.h(a):iZ.call(null,
a)):null},yja=new $CLJS.h(null,8,[$CLJS.Yi,vY,$CLJS.LR,function(a){a=wY(a);return $CLJS.z($CLJS.iS.h(a))?$CLJS.wk.j(a,$CLJS.iS,aja):a},$CLJS.jO,new $CLJS.h(null,6,[$CLJS.MH,function jZ(a){if($CLJS.n($CLJS.pC($CLJS.ee,$CLJS.Va)(a)))return vY(a);if($CLJS.n(FY(aZ,a))){a=$CLJS.z(a);$CLJS.B(a);var c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[aZ,jZ.h?jZ.h(a):jZ.call(null,a)],null),c)}return $CLJS.n($CLJS.n(EY(a))?iZ($CLJS.hd(a)):null)?$CLJS.vk.g(jZ,a):GY.g?GY.g(a,
HY):GY.call(null,a,HY)},$CLJS.BR,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[AY(u),GY.g?GY.g(t,HY):GY.call(null,t,HY)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=
$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[AY(l),GY.g?GY.g(f,HY):GY.call(null,f,HY)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())},$CLJS.qT,function(a){return $CLJS.uf(function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(EY(t))?hZ.h(t):$CLJS.ae(hZ.h($CLJS.ae(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}l=$CLJS.B(e);return $CLJS.ce($CLJS.n(EY(l))?hZ.h(l):$CLJS.ae(hZ.h($CLJS.ae(l))),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())},$CLJS.kS,function(a){a=wY(a);a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.LR);if($CLJS.n(b))return a=$CLJS.bX(a,new $CLJS.h(null,1,[$CLJS.LR,$CLJS.jO],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.LR],null),a=GY.g?GY.g(a,b):GY.call(null,a,b),$CLJS.bX(a,new $CLJS.h(null,1,[$CLJS.jO,$CLJS.LR],
null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.jO],null);return GY.g?GY.g(a,b):GY.call(null,a,b)},$CLJS.tQ,new $CLJS.h(null,1,[ZY,IY],null),$CLJS.fP,new $CLJS.h(null,1,[ZY,function(a){a=GY.g?GY.g(a,$CLJS.jO):GY.call(null,a,$CLJS.jO);var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.rG),d=$CLJS.I.g(b,$CLJS.GG);a=$CLJS.I.g(b,$CLJS.KG);b=$CLJS.n(c)?$CLJS.wk.j(b,$CLJS.rG,vY):b;d=$CLJS.n($CLJS.pC($CLJS.ee,$CLJS.Va)(d))?$CLJS.wk.j(b,$CLJS.GG,vY):b;return $CLJS.n(a)?$CLJS.wk.j(d,$CLJS.KG,AY):d}],null)],null),$CLJS.xA,
new $CLJS.h(null,1,[$CLJS.RP,$CLJS.Rd],null),$CLJS.XO,new $CLJS.h(null,1,[ZY,function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.Yi),d=$CLJS.I.g(b,$CLJS.ZK),e=$CLJS.I.g(b,$CLJS.Ki);a=$CLJS.I.g(b,bZ);b=$CLJS.n(e)?$CLJS.wk.j(b,$CLJS.Ki,AY):b;c=$CLJS.n(c)?$CLJS.wk.j(b,$CLJS.Yi,vY):b;d=$CLJS.n(d)?$CLJS.wk.j(c,$CLJS.ZK,function(f){return GY.g?GY.g(f,HY):GY.call(null,f,HY)}):c;d=$CLJS.n(a)?$CLJS.XY.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[bZ,qja],null),function(f){return GY.g?GY.g(f,HY):GY.call(null,f,HY)}):d;return $CLJS.n(a)?
$CLJS.XY.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[bZ,mja],null),function(f){return GY.g?GY.g(f,HY):GY.call(null,f,HY)}):d}],null),$CLJS.SO,function(a){return null==a?null:vY(a)},$CLJS.tQ,new $CLJS.h(null,1,[ZY,IY],null),gZ,vY],null),GY=function GY(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return GY.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
GY.l=function(a,b){b=$CLJS.H(b,0,null);var c=b instanceof $CLJS.K?new $CLJS.Q(null,1,5,$CLJS.R,[b],null):$CLJS.uf(b);b=$CLJS.z(c)?$CLJS.qC(yja,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.xd(a)?a:$CLJS.wd(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);A=vY(A);A=new $CLJS.Q(null,
2,5,$CLJS.R,[A,GY.l(x,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}m=$CLJS.B(l);u=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);u=vY(u);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[u,GY.l(m,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),u)]))],null),k($CLJS.Kc(l)))}return null}},null,null)}(a)}()):$CLJS.n(EY(a))?hZ.h(a):$CLJS.vd(a)?$CLJS.vk.g(function(e){return GY.l(e,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),ZY)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.$h($CLJS.WF("Error normalizing form: {0}",$CLJS.G([$CLJS.VW(d)])),new $CLJS.h(null,3,[$CLJS.PB,a,$CLJS.al,c,pja,b],null),d);}throw e;}};GY.A=1;GY.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
var KY=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.Ti,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(EY(f))?$CLJS.B(f):null},e,a,b,c,d)}();KY.m(null,$CLJS.Wh,function(a){return a});
KY.m(null,$CLJS.XH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);if($CLJS.n(FY($CLJS.XH,b))){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);return KY.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,c,$CLJS.De($CLJS.Lk.l($CLJS.G([b,a])))],null))}return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,b,$CLJS.De(a)],null)});
KY.m(null,$CLJS.MH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.sd(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MH,b],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MH,b,a],null)});KY.m(null,$CLJS.AM,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(EY(a))?KY.h(a):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,a,null],null)});
KY.m(null,fZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,b,new $CLJS.h(null,1,[$CLJS.gD,a],null)],null)});KY.m(null,rja,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=LY(b);$CLJS.H(c,0,null);b=$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=LY(a);$CLJS.H(c,0,null);a=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,a,$CLJS.S.j(c,$CLJS.gP,b)],null)});
KY.m(null,sja,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=LY(a);return $CLJS.P.v($CLJS.iX,a,$CLJS.S,$CLJS.G([$CLJS.WQ,b]))});
KY.m(null,eZ,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.H(a,0,null);b=$CLJS.H(a,1,null);var c=$CLJS.H(a,2,null);a=LY(b);b=c;$CLJS.H(a,0,null);$CLJS.H(a,1,null);c=$CLJS.H(a,2,null);c=$CLJS.O(c);var d=$CLJS.I.g(c,$CLJS.gD);$CLJS.Sa(d)||$CLJS.mU.g(d,b)?a=$CLJS.P.v($CLJS.iX,a,$CLJS.S,$CLJS.G([$CLJS.cI,b])):(c=$CLJS.zC($CLJS.vA),$CLJS.n($CLJS.yC("metabase.mbql.util",c))&&(b=$CLJS.WF("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.G([b,d,$CLJS.Eh.l($CLJS.G([a]))])),b instanceof
Error?$CLJS.xC("metabase.mbql.util",c,$CLJS.fy(),b):$CLJS.xC("metabase.mbql.util",c,$CLJS.fy.l($CLJS.G([b])),null)));return a;case 4:return $CLJS.H(a,0,null),b=$CLJS.H(a,1,null),$CLJS.H(a,2,null),c=$CLJS.H(a,3,null),KY.h(new $CLJS.Q(null,3,5,$CLJS.R,[eZ,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
KY.m(null,dZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);var e=LY(b);$CLJS.H(e,0,null);b=$CLJS.H(e,1,null);e=$CLJS.H(e,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XH,b,$CLJS.S.j(e,$CLJS.XS,$CLJS.Lk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.rG,c],null),$CLJS.n(d)?$CLJS.Ce([c,d]):null,a])))],null)});
for(var kZ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,$CLJS.ft,$CLJS.ht],null)),lZ=null,mZ=0,nZ=0;;)if(nZ<mZ){var oZ=lZ.X(null,nZ);KY.m(null,oZ,function(){return function(a){return MY(a)}}(kZ,lZ,mZ,nZ,oZ));nZ+=1}else{var pZ=$CLJS.z(kZ);if(pZ){var qZ=pZ;if($CLJS.zd(qZ)){var rZ=$CLJS.kc(qZ),zja=$CLJS.lc(qZ),Aja=rZ,Bja=$CLJS.E(rZ);kZ=zja;lZ=Aja;mZ=Bja}else{var sZ=$CLJS.B(qZ);KY.m(null,sZ,function(){return function(a){return MY(a)}}(kZ,lZ,mZ,nZ,sZ,qZ,pZ));kZ=$CLJS.C(qZ);lZ=null;mZ=0}nZ=0}else break}
KY.m(null,$CLJS.EH,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.EH,LY(a),LY(b)],null),c)});KY.m(null,$CLJS.iI,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=LY(a);a=$CLJS.n(BY($CLJS.XH,a))?$CLJS.iX.l(c,$CLJS.lk,$CLJS.G([$CLJS.cI])):c;return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iI,a],null),b)});
for(var tZ=$CLJS.z(new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.OH,$CLJS.hI,$CLJS.WH,$CLJS.PH,$CLJS.Fs,$CLJS.pI,$CLJS.Cs,$CLJS.Es,$CLJS.ys,$CLJS.As,$CLJS.zH,$CLJS.GH,$CLJS.xH,$CLJS.JH,$CLJS.yH],null)),uZ=null,vZ=0,wZ=0;;)if(wZ<vZ){var xZ=uZ.X(null,wZ);KY.m(null,xZ,function(){return function(a){return NY(a)}}(tZ,uZ,vZ,wZ,xZ));wZ+=1}else{var yZ=$CLJS.z(tZ);if(yZ){var zZ=yZ;if($CLJS.zd(zZ)){var AZ=$CLJS.kc(zZ),Cja=$CLJS.lc(zZ),Dja=AZ,Eja=$CLJS.E(AZ);tZ=Cja;uZ=Dja;vZ=Eja}else{var BZ=$CLJS.B(zZ);KY.m(null,BZ,
function(){return function(a){return NY(a)}}(tZ,uZ,vZ,wZ,BZ,zZ,yZ));tZ=$CLJS.C(zZ);uZ=null;vZ=0}wZ=0}else break}KY.m(null,oja,function(){return null});KY.m(null,$CLJS.WN,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WN,KY.h(b),a],null)});
KY.m(null,aZ,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=KY.h;var e=$CLJS.R;b=KY.h(b);d=$CLJS.H(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.I.g(d,nja)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.aG,a],null);return c.call(KY,new $CLJS.Q(null,3,5,e,[$CLJS.WN,b,a],null))});
for(var CZ=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Iz,$CLJS.YH],null)),DZ=null,EZ=0,FZ=0;;)if(FZ<EZ){var GZ=DZ.X(null,FZ);KY.m(null,GZ,function(){return function(a){return OY(a)}}(CZ,DZ,EZ,FZ,GZ));FZ+=1}else{var HZ=$CLJS.z(CZ);if(HZ){var IZ=HZ;if($CLJS.zd(IZ)){var JZ=$CLJS.kc(IZ),Fja=$CLJS.lc(IZ),Gja=JZ,Hja=$CLJS.E(JZ);CZ=Fja;DZ=Gja;EZ=Hja}else{var KZ=$CLJS.B(IZ);KY.m(null,KZ,function(){return function(a){return OY(a)}}(CZ,DZ,EZ,FZ,KZ,IZ,HZ));CZ=$CLJS.C(IZ);DZ=null;EZ=0}FZ=0}else break}
for(var LZ=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.RH,$CLJS.uI,$CLJS.BH,$CLJS.nI,$CLJS.jI,$CLJS.Kn,$CLJS.Rj,$CLJS.kI,$CLJS.CH],null)),MZ=null,NZ=0,OZ=0;;)if(OZ<NZ){var PZ=MZ.X(null,OZ);KY.m(null,PZ,function(){return function(a){return PY(a)}}(LZ,MZ,NZ,OZ,PZ));OZ+=1}else{var QZ=$CLJS.z(LZ);if(QZ){var RZ=QZ;if($CLJS.zd(RZ)){var SZ=$CLJS.kc(RZ),Ija=$CLJS.lc(RZ),Jja=SZ,Kja=$CLJS.E(SZ);LZ=Ija;MZ=Jja;NZ=Kja}else{var TZ=$CLJS.B(RZ);KY.m(null,TZ,function(){return function(a){return PY(a)}}(LZ,MZ,NZ,OZ,
TZ,RZ,QZ));LZ=$CLJS.C(RZ);MZ=null;NZ=0}OZ=0}else break}KY.m(null,$CLJS.IH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IH,LY(b),a],null)});
for(var UZ=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dI,$CLJS.fI],null)),VZ=null,WZ=0,XZ=0;;)if(XZ<WZ){var YZ=VZ.X(null,XZ);KY.m(null,YZ,function(){return function(a){return QY(a)}}(UZ,VZ,WZ,XZ,YZ));XZ+=1}else{var ZZ=$CLJS.z(UZ);if(ZZ){var $Z=ZZ;if($CLJS.zd($Z)){var a_=$CLJS.kc($Z),Lja=$CLJS.lc($Z),Mja=a_,Nja=$CLJS.E(a_);UZ=Lja;VZ=Mja;WZ=Nja}else{var b_=$CLJS.B($Z);KY.m(null,b_,function(){return function(a){return QY(a)}}(UZ,VZ,WZ,XZ,b_,$Z,ZZ));UZ=$CLJS.C($Z);VZ=null;WZ=0}XZ=0}else break}
KY.m(null,$CLJS.sI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sI,KY.h(b),KY.h(a)],null)});
KY.m(null,$CLJS.FH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?$CLJS.$d.g(KY.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FH,b],null)),GY.l(a,$CLJS.G([HY]))):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FH,$CLJS.uf(function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.Q(null,
2,5,$CLJS.R,[KY.h(v),KY.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[KY.h(m),KY.h(k)],null),e($CLJS.Kc(f)))}return null}},null,null)}(b)}())],null)});
KY.m(null,$CLJS.qH,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qH,KY.h(a),$CLJS.F.g(0,b)?1:KY.h(b)],null),$CLJS.Ze.g(KY,c))});
var SY=function SY(a){return function f(d,e){try{if($CLJS.n(function(){var x=yY($CLJS.Cd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.uf(e));throw $CLJS.UW;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.UW)try{if($CLJS.n(function(){var A=yY($CLJS.ee);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[e],null)],null);throw $CLJS.UW;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.UW)try{if($CLJS.yd(e)&&2<=$CLJS.E(e))try{var k=$CLJS.Ak.j(e,
0,2);if($CLJS.yd(k)&&2===$CLJS.E(k))try{var l=$CLJS.bd(k,0);if($CLJS.n($CLJS.pN($CLJS.ee,$CLJS.Ke(new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.Js,null,$CLJS.ku,null,$CLJS.tI,null,$CLJS.Is,null,aZ,null],null),null)))(l)))try{var m=$CLJS.bd(k,1);if($CLJS.n(iZ(m)))return $CLJS.Wf.j($CLJS.tf,$CLJS.VF(SY),a);throw $CLJS.UW;}catch(D){if(D instanceof Error){var t=D;if(t===$CLJS.UW)throw $CLJS.UW;throw t;}throw D;}else throw $CLJS.UW;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.UW)throw $CLJS.UW;throw t;
}throw D;}else throw $CLJS.UW;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.UW)throw $CLJS.UW;throw t;}throw D;}else throw $CLJS.UW;}catch(D){if(D instanceof Error)if(t=D,t===$CLJS.UW)try{if($CLJS.yd(e)&&1<=$CLJS.E(e))try{var u=$CLJS.Ak.j(e,0,1);if($CLJS.yd(u)&&1===$CLJS.E(u))try{if($CLJS.bd(u,0)instanceof $CLJS.K)return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.UW;}catch(J){if(J instanceof Error){var v=J;if(v===$CLJS.UW)throw $CLJS.UW;throw v;}throw J;}else throw $CLJS.UW;}catch(J){if(J instanceof
Error){v=J;if(v===$CLJS.UW)throw $CLJS.UW;throw v;}throw J;}else throw $CLJS.UW;}catch(J){if(J instanceof Error){v=J;if(v===$CLJS.UW)return e;throw v;}throw J;}else throw t;else throw D;}else throw d;else throw A;}else throw d;else throw x;}}($CLJS.tf,a)},c_=function c_(a){return function f(d,e){try{var k=yY($CLJS.Cd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.uf(e));throw $CLJS.UW;}catch(D){if(D instanceof Error)if(l=D,l===$CLJS.UW)try{if($CLJS.yd(e)&&2===$CLJS.E(e))try{var m=
$CLJS.bd(e,1);if($CLJS.fe(m,$CLJS.UH)){var t=$CLJS.bd(e,0);return f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UH,t],null))}throw $CLJS.UW;}catch(J){if(J instanceof Error)if(l=J,l===$CLJS.UW)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,$CLJS.NH))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NH,t],null));throw $CLJS.UW;}catch(M){if(M instanceof Error){var u=M;if(u===$CLJS.UW)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,$Y))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UH,t],null));throw $CLJS.UW;
}catch(V){if(V instanceof Error)if(l=V,l===$CLJS.UW)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,cZ))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NH,t],null));throw $CLJS.UW;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.UW)try{var v=$CLJS.bd(e,0);if($CLJS.fe(v,$Y))return t=$CLJS.bd(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UH,t],null));throw $CLJS.UW;}catch(fa){if(fa instanceof Error)if(m=fa,m===$CLJS.UW)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,cZ))return t=$CLJS.bd(e,1),f(d,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.NH,t],null));throw $CLJS.UW;}catch(Da){if(Da instanceof Error)if(m=Da,m===$CLJS.UW)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,$CLJS.UH))return t=$CLJS.bd(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UH,LY(t)],null);throw $CLJS.UW;}catch(Ha){if(Ha instanceof Error)if(m=Ha,m===$CLJS.UW)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,$CLJS.NH))return t=$CLJS.bd(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NH,LY(t)],null);throw $CLJS.UW;}catch(mb){if(mb instanceof Error){t=mb;if(t===$CLJS.UW)throw $CLJS.UW;throw t;
}throw mb;}else throw m;else throw Ha;}else throw m;else throw Da;}else throw m;else throw fa;}else throw m;else throw Z;}else throw l;else throw V;}else throw u;}else throw M;}else throw l;else throw J;}else throw $CLJS.UW;}catch(J){if(J instanceof Error)if(l=J,l===$CLJS.UW)try{if($CLJS.yd(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Ak.j(e,0,0);if($CLJS.yd(x)&&0===$CLJS.E(x))try{var A=$CLJS.Ak.g(e,0);if($CLJS.F.g(A,a))return $CLJS.uf($CLJS.Nk.h($CLJS.Ze.g(c_,a)));throw $CLJS.UW;}catch(M){if(M instanceof Error){u=
M;if(u===$CLJS.UW)throw $CLJS.UW;throw u;}throw M;}else throw $CLJS.UW;}catch(M){if(M instanceof Error){u=M;if(u===$CLJS.UW)throw $CLJS.UW;throw u;}throw M;}else throw $CLJS.UW;}catch(M){if(M instanceof Error){u=M;if(u===$CLJS.UW)return $CLJS.zY(f,d,e);throw u;}throw M;}else throw l;else throw J;}else throw l;else throw D;}}($CLJS.tf,a)},VY=$CLJS.tk.g(xY,function(a){var b=WY($CLJS.MH.h(a))?$CLJS.wk.j(a,$CLJS.MH,dja):a;b=WY($CLJS.LH.h(a))?$CLJS.wk.j(b,$CLJS.LH,eja):b;b=WY($CLJS.GG.h(a))?$CLJS.wk.j(b,
$CLJS.GG,$CLJS.Me($CLJS.vk,JY)):b;b=WY($CLJS.qT.h(a))?$CLJS.wk.j(b,$CLJS.qT,c_):b;return WY($CLJS.kS.h(a))?$CLJS.wk.j(b,$CLJS.kS,gja):b}),Oja=new $CLJS.h(null,3,[$CLJS.LR,$CLJS.Rd,$CLJS.jO,new $CLJS.h(null,2,[$CLJS.kS,function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.LR);if($CLJS.n(b))return a=$CLJS.bX(a,new $CLJS.h(null,1,[$CLJS.LR,$CLJS.jO],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.LR],null),a=YY.g?YY.g(a,b):YY.call(null,a,b),$CLJS.bX(a,new $CLJS.h(null,1,[$CLJS.jO,$CLJS.LR],null));b=new $CLJS.Q(null,
1,5,$CLJS.R,[$CLJS.jO],null);return YY.g?YY.g(a,b):YY.call(null,a,b)},$CLJS.fP,new $CLJS.h(null,1,[ZY,function(a){var b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.jO],null);return YY.g?YY.g(a,b):YY.call(null,a,b)}],null)],null),gZ,$CLJS.Rd],null),YY=function YY(a){switch(arguments.length){case 1:return YY.h(arguments[0]);case 2:return YY.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};YY.h=function(a){return YY.g(a,$CLJS.tf)};
YY.g=function(a,b){try{var c=$CLJS.z(b)?$CLJS.qC(Oja,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.xd(a)?a:$CLJS.wd(a)?kja(a,b):$CLJS.vd(a)?lja(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.$h("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.PB,a,$CLJS.al,b],null),d);throw d;}};YY.A=2;
$CLJS.d_=function(){var a=$CLJS.tk.l(YY,jja,hja,$CLJS.G([GY]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.$h($CLJS.WF("Error normalizing query: {0}",$CLJS.G([$CLJS.VW(c)])),new $CLJS.h(null,1,[$CLJS.jO,b],null),c);}throw d;}}}();$CLJS.e_=function e_(a,b){if($CLJS.Sa($CLJS.z(a)))a=(0,$CLJS.d_)(b);else{var d=$CLJS.I,e=d.g;var f=$CLJS.Cu(a);b=$CLJS.Ce([$CLJS.id(a),b]);f=e_.g?e_.g(f,b):e_.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};
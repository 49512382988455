var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var qqa,rqa,tqa,uqa,vqa,wqa;qqa=function(a,b){return $CLJS.vC(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};rqa=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){return $CLJS.Zf.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.cc($CLJS.N),b))};tqa=function(a){return $CLJS.Wf.j($CLJS.Ug,$CLJS.tk.g($CLJS.VF(function(b){return $CLJS.tY(b,a)}),$CLJS.Ze.h($CLJS.hd)),sqa)};
uqa=function(a){return new $CLJS.h(null,3,[$CLJS.Yi,$CLJS.kD,$CLJS.T,a,$CLJS.Ki,$CLJS.p.h($CLJS.xG())],null)};
vqa=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.T);return $CLJS.Lk.l($CLJS.G([a,function(){var c=$CLJS.fh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.H(c,0,null),c=$CLJS.H(c,1,null),c=$CLJS.WX(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.YO,$CLJS.FS,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.Yi,$CLJS.hO,$CLJS.UN,c],null):null}(),$CLJS.n($CLJS.aG.h(a))?null:new $CLJS.h(null,
1,[$CLJS.aG,$CLJS.p2.g($CLJS.o2,b)],null)]))};$CLJS.E8=function(a){a=$CLJS.h2($CLJS.n1(a));return $CLJS.n($CLJS.qC(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XB,wqa],null)))?$CLJS.$d.g($CLJS.Ug,$CLJS.OP):$CLJS.Ug};$CLJS.F8=function(a,b){var c=$CLJS.E8(a);return(0,$CLJS.qY)(a,0,function(d){var e=$CLJS.yv.g(xqa,c);d=$CLJS.P.j($CLJS.lk,d,e);d=$CLJS.Lk.l($CLJS.G([d,$CLJS.Ql(b,c)]));$CLJS.yv.g(c,$CLJS.Yg($CLJS.Pg(b)));$CLJS.hY(a,0);return d})};$CLJS.G8=function(a){return $CLJS.De($CLJS.Ql($CLJS.hY(a,0),$CLJS.E8(a)))};
$CLJS.H8=function(a){return $CLJS.LR.h($CLJS.hY(a,0))};wqa=new $CLJS.K(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.yqa=new $CLJS.K(null,"write","write",-1857649168);$CLJS.zqa=new $CLJS.K(null,"native-permissions","native-permissions",1277672164);var sqa,xqa;sqa=new $CLJS.Q(null,3,5,$CLJS.R,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.I8=function(){function a(d,e){d=$CLJS.De(tqa(d));var f=$CLJS.De($CLJS.Yg($CLJS.Pg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.yv.g(d,f);d=$CLJS.yv.g(f,d);if($CLJS.F.l(1,$CLJS.E(k),$CLJS.G([$CLJS.E(d)]))){d=$CLJS.B(d);k=$CLJS.B(k);f=$CLJS.I.g(e,d);var l=$CLJS.F.g($CLJS.aG.h(f),$CLJS.p2.g($CLJS.o2,d))?$CLJS.p2.g($CLJS.o2,k):$CLJS.aG.h(f);f=$CLJS.S.l($CLJS.lk.l(f,$CLJS.UN,$CLJS.G([$CLJS.FS,$CLJS.vR])),$CLJS.aG,l,$CLJS.G([$CLJS.T,k]));e=$CLJS.S.j($CLJS.lk.g(e,d),k,f)}else e=$CLJS.Lk.l($CLJS.G([qqa($CLJS.Ke(d),
e),rqa($CLJS.T,$CLJS.Ze.g(uqa,k))]));e=$CLJS.Eu(e,vqa)}else e=$CLJS.N;return e}function b(d){return $CLJS.I8.g?$CLJS.I8.g(d,null):$CLJS.I8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();xqa=new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.OP,null],null),null);
$CLJS.Y(new $CLJS.K("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OP,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.fG],null)],null));
$CLJS.J8=function(){function a(d,e,f,k){var l=$CLJS.I8.h(e);return $CLJS.F8($CLJS.l2.g(d,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.SX(new $CLJS.h(null,4,[$CLJS.nv,$CLJS.DX,$CLJS.PW,f,$CLJS.iS,l,$CLJS.LR,e],null))],null)),k)}function b(d,e){return $CLJS.J8.v?$CLJS.J8.v(d,e,null,null):$CLJS.J8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.K8=function(){function a(d,e,f){$CLJS.hY(d,0);return $CLJS.F8($CLJS.l2.g(e,$CLJS.SM.h(d)),f)}function b(d,e){return $CLJS.K8.j?$CLJS.K8.j(d,e,null):$CLJS.K8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.k2.m(null,$CLJS.DX,function(a){return $CLJS.f2($CLJS.E8(a),$CLJS.Yg($CLJS.Pg($CLJS.G8(a))))&&!$CLJS.uC($CLJS.H8(a))});
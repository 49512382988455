var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var $J,aK,uea,vea,wea,eK,fK,gK,iK,xea,kK;$J=function(a){switch(arguments.length){case 2:return $CLJS.eH(arguments[0],arguments[1]);case 3:return $CLJS.dH(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};aK=function(a){return["(?:",$CLJS.p.h($CLJS.P.g($CLJS.p,a)),")?"].join("")};uea=new $CLJS.K("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
vea=new $CLJS.K("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.bK=new $CLJS.K("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);wea=new $CLJS.K("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.cK=new $CLJS.K("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
$CLJS.dK=new $CLJS.K("metabase.lib.schema.literal","string.zone-offset","metabase.lib.schema.literal/string.zone-offset",-437074263);eK=new $CLJS.K("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);fK=new $CLJS.K("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);gK=new $CLJS.K("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);
$CLJS.hK=new $CLJS.K("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);iK=new $CLJS.K("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);xea=new $CLJS.K("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.jK=new $CLJS.K("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);
kK=new $CLJS.K("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);$CLJS.lK=new $CLJS.K("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.AG.m(null,$CLJS.jD,function(){return $CLJS.lj});$CLJS.Y(kK,$CLJS.wt);$CLJS.AG.m(null,$CLJS.mD,function(){return $CLJS.uj});$CLJS.Y(kK,$CLJS.wt);$CLJS.Y(xea,$CLJS.st);$CLJS.AG.m(null,$CLJS.ZC,function(){return $CLJS.hj});$CLJS.Y(vea,$CLJS.rt);$CLJS.AG.m(null,$CLJS.XC,function(){return $CLJS.BF});$CLJS.Y(wea,$CLJS.Ij);
var yea=[":\\d{2}",aK($CLJS.G(["\\.\\d{1,6}"]))].join(""),mK=["\\d{2}:\\d{2}",aK($CLJS.G([yea]))].join(""),nK=["\\d{4}-\\d{2}-\\d{2}T",mK].join(""),oK=["(?:Z|(?:[+-]",mK,"))"].join(""),zea=$CLJS.hh(["(?:Z|(?:[+-]",mK,"))"].join("")),Aea=$CLJS.hh("^\\d{4}-\\d{2}-\\d{2}$"),Bea=$CLJS.hh(["^",mK,"$"].join("")),Cea=$CLJS.hh(["^",mK,oK,"$"].join("")),Dea=$CLJS.hh(["^",nK,"$"].join("")),Eea=$CLJS.hh(["^",nK,oK,"$"].join(""));
$CLJS.Y(fK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.uu,"date string literal"],null),Aea],null));$CLJS.Y($CLJS.dK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.uu,"timezone offset string literal"],null),zea],null));
$CLJS.Y(eK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.uu,"local time string literal"],null),Bea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.uu,"offset time string literal"],null),Cea],null)],null));
$CLJS.Y(gK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.uu,"local date time string literal"],null),Dea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.uu,"offset date time string literal"],null),Eea],null)],null));
$CLJS.AG.m(null,$CLJS.YC,function(a){return $CLJS.n($CLJS.eH?$CLJS.eH(gK,a):$J.call(null,gK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.Mj,null,$CLJS.eF,null],null),null):$CLJS.n($CLJS.eH?$CLJS.eH(fK,a):$J.call(null,fK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.GE,null,$CLJS.Mj,null],null),null):$CLJS.n($CLJS.eH?$CLJS.eH(eK,a):$J.call(null,eK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.EF,null,$CLJS.Mj,null],null),null):$CLJS.Mj});$CLJS.Y($CLJS.hK,fK);$CLJS.Y($CLJS.cK,eK);
$CLJS.Y($CLJS.lK,gK);$CLJS.Y(uea,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ft,$CLJS.hK,$CLJS.cK,$CLJS.lK],null));$CLJS.pK=$CLJS.hh("^\\d{4}-\\d{2}$");$CLJS.Y($CLJS.jK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.uu,"year-month string literal"],null),$CLJS.pK],null));$CLJS.qK=$CLJS.hh("^\\d{4}$");$CLJS.Y($CLJS.bK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.uu,"year string literal"],null),$CLJS.qK],null));
$CLJS.Y(iK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ti,$CLJS.pG],null)],null)],null));$CLJS.yI.g($CLJS.oj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.uu,"Value :value clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.oj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iK],null),$CLJS.ol],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var EW,FW,GW,IW,JW,KW,LW,MW,NW,OW;EW=new $CLJS.K("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);FW=new $CLJS.K("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);GW=new $CLJS.K("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.HW=new $CLJS.K("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);IW=new $CLJS.K("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);JW=new $CLJS.K("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);KW=new $CLJS.K("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
LW=new $CLJS.K("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);MW=new $CLJS.K("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);NW=new $CLJS.K("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);OW=new $CLJS.K("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(EW,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bt,new $CLJS.h(null,1,[$CLJS.uu,"Valid template tag :widget-type"],null),$CLJS.WB],null),$CLJS.Pg($CLJS.zW)));$CLJS.Y(IW,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.bt,$CLJS.hO,$CLJS.YO,$CLJS.LN,$CLJS.dv,$CLJS.kD,$CLJS.dR],null));
$CLJS.Y(LW,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.fG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aG,$CLJS.fG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,$CLJS.fG,$CLJS.vt],null)],null)],null));
$CLJS.Y(MW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.ol],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zP,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.wt],null)],null)],null));
$CLJS.Y(JW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MW],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.LN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.LN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sP,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Dt,!0],
null),$CLJS.Qj],null)],null)],null));
$CLJS.Y(KW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LW],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.hO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UN,$CLJS.fG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vR,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.JM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wR,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.CM],null)],null)],null));
$CLJS.Y(OW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.YO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FS,$CLJS.BM],null)],null)],null));$CLJS.Y(NW,$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.bt],null),$CLJS.nW));
$CLJS.Y(FW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NW],null)],null)],null)],null));
$CLJS.Y(GW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IW],null)],null)],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Bi,$CLJS.Yi],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.LN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hO,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YO,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Lo,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FW],null)],null)],null)],null));
$CLJS.Y($CLJS.HW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,$CLJS.fG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Ie(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.T.h(b))},a)}],null)],null));
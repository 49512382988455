var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var kda,lda,mda,nda,oda,pda,qda,DI,rda,sda,tda,uda,vda,wda,xda,yda,FI,zda;$CLJS.zI=new $CLJS.K(null,"supported-field","supported-field",-2061545519);kda=new $CLJS.K("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.AI=new $CLJS.K("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);lda=new $CLJS.K("mbql.clause","count-where","mbql.clause/count-where",2010666417);
mda=new $CLJS.K("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.BI=new $CLJS.K(null,"requires-column?","requires-column?",1623131448);nda=new $CLJS.K("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.CI=new $CLJS.K(null,"display-info","display-info",-816930907);oda=new $CLJS.K("mbql.clause","max","mbql.clause/max",1615385533);pda=new $CLJS.K("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
qda=new $CLJS.K("mbql.clause","median","mbql.clause/median",-1026386338);DI=new $CLJS.K("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);rda=new $CLJS.K("mbql.clause","min","mbql.clause/min",2074448531);sda=new $CLJS.K("mbql.clause","percentile","mbql.clause/percentile",2064253651);tda=new $CLJS.K("mbql.clause","stddev","mbql.clause/stddev",659381076);uda=new $CLJS.K("mbql.clause","var","mbql.clause/var",809434920);
vda=new $CLJS.K("mbql.clause","share","mbql.clause/share",-37921592);wda=new $CLJS.K("mbql.clause","count","mbql.clause/count",-454225910);xda=new $CLJS.K("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.EI=new $CLJS.K(null,"driver-feature","driver-feature",1905324248);yda=new $CLJS.K("mbql.clause","avg","mbql.clause/avg",1826863173);FI=new $CLJS.K("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.GI=new $CLJS.K("operator","aggregation","operator/aggregation",-1961510924);zda=new $CLJS.K("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.oH($CLJS.Iz,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ks,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)],null)],null)]));$CLJS.oH($CLJS.YH,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ks,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)],null)],null)]));
$CLJS.mH($CLJS.RH,$CLJS.G([$CLJS.ku,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));$CLJS.mH($CLJS.BH,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)]));$CLJS.mH($CLJS.fI,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LG],null)],null)]));
$CLJS.mH($CLJS.Rj,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TG],null)],null)]));$CLJS.fH($CLJS.Rj,$CLJS.SG);$CLJS.mH($CLJS.kI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));$CLJS.fH($CLJS.kI,$CLJS.SG);$CLJS.mH($CLJS.Kn,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TG],null)],null)]));$CLJS.fH($CLJS.Kn,$CLJS.SG);
$CLJS.Y(DI,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.uu,"valid percentile"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.mH($CLJS.IH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DI],null)]));$CLJS.fH($CLJS.IH,$CLJS.SG);
$CLJS.mH($CLJS.dI,$CLJS.G([$CLJS.ku,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LG],null)],null)]));$CLJS.mH($CLJS.nI,$CLJS.G([$CLJS.ku,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));$CLJS.mH($CLJS.jI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));
$CLJS.mH($CLJS.uI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));$CLJS.fH($CLJS.jI,$CLJS.SG);$CLJS.fH($CLJS.uI,$CLJS.SG);$CLJS.mH($CLJS.sI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LG],null)],null)]));$CLJS.fH($CLJS.sI,$CLJS.SG);
$CLJS.mH($CLJS.CH,$CLJS.G([$CLJS.ku,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));$CLJS.Y(FI,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.ft,yda,wda,pda,lda,kda,oda,qda,rda,sda,vda,tda,zda,xda,mda,uda,$CLJS.ol],null));$CLJS.Y($CLJS.AI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.Kn,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FI],null)],null));
$CLJS.HI=new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.h(null,4,[$CLJS.QH,$CLJS.Iz,$CLJS.BI,!1,$CLJS.EI,$CLJS.rH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.yG("Count of rows"),$CLJS.HH,$CLJS.yG("Count"),$CLJS.Ru,$CLJS.yG("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.QH,$CLJS.jI,$CLJS.zI,$CLJS.Oj,$CLJS.BI,!0,$CLJS.EI,$CLJS.rH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.yG("Sum of ..."),$CLJS.HH,$CLJS.yG("Sum"),$CLJS.Ru,$CLJS.yG("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.QH,$CLJS.RH,$CLJS.zI,$CLJS.Oj,$CLJS.BI,!0,$CLJS.EI,$CLJS.rH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.yG("Average of ..."),$CLJS.HH,$CLJS.yG("Average"),$CLJS.Ru,$CLJS.yG("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.QH,$CLJS.kI,$CLJS.zI,$CLJS.Oj,$CLJS.BI,!0,$CLJS.EI,$CLJS.TH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.yG("Median of ..."),$CLJS.HH,$CLJS.yG("Median"),$CLJS.Ru,$CLJS.yG("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.QH,$CLJS.BH,$CLJS.zI,$CLJS.xt,$CLJS.BI,!0,$CLJS.EI,$CLJS.rH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.yG("Number of distinct values of ..."),$CLJS.HH,$CLJS.yG("Distinct values"),$CLJS.Ru,$CLJS.yG("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.QH,$CLJS.uI,$CLJS.zI,$CLJS.Oj,$CLJS.BI,!0,$CLJS.EI,$CLJS.rH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.yG("Cumulative sum of ..."),
$CLJS.HH,$CLJS.yG("Sum"),$CLJS.Ru,$CLJS.yG("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.QH,$CLJS.YH,$CLJS.BI,!1,$CLJS.EI,$CLJS.rH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.yG("Cumulative count of rows"),$CLJS.HH,$CLJS.yG("Count"),$CLJS.Ru,$CLJS.yG("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.QH,$CLJS.nI,$CLJS.zI,$CLJS.Oj,$CLJS.BI,
!0,$CLJS.EI,$CLJS.gI,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.yG("Standard deviation of ..."),$CLJS.HH,$CLJS.yG("SD"),$CLJS.Ru,$CLJS.yG("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.QH,$CLJS.Kn,$CLJS.zI,$CLJS.Ji,$CLJS.BI,!0,$CLJS.EI,$CLJS.rH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.yG("Minimum of ..."),$CLJS.HH,$CLJS.yG("Min"),$CLJS.Ru,$CLJS.yG("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.QH,$CLJS.Rj,$CLJS.zI,$CLJS.Ji,$CLJS.BI,!0,$CLJS.EI,$CLJS.rH,$CLJS.CI,function(){return new $CLJS.h(null,3,[$CLJS.aG,$CLJS.yG("Maximum of ..."),$CLJS.HH,$CLJS.yG("Max"),$CLJS.Ru,$CLJS.yG("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(nda,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.GI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QH,$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.bt],null),$CLJS.Ze.h($CLJS.QH),$CLJS.HI)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zI,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,$CLJS.Ii],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.BI,$CLJS.wt],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.EI,$CLJS.Ii],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CI,$CLJS.md],null)],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var iJ,jJ,Pda,lJ,Qda,Rda,Sda,mJ,kJ;$CLJS.gJ=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.$d.g($CLJS.I.j(c,e,$CLJS.tf),d))},$CLJS.cc($CLJS.N),b))};$CLJS.hJ=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Rc(d):null},null,b)};
iJ=function(a,b){$CLJS.H(a,0,null);$CLJS.H(a,1,null);$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);b=$CLJS.fE(b,$CLJS.GE)?$CLJS.TI:$CLJS.fE(b,$CLJS.EF)?$CLJS.QI:$CLJS.fE(b,$CLJS.eF)?$CLJS.TF:null;return $CLJS.n(b)?$CLJS.eH(b,a):!0};
jJ=function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);a=$CLJS.gJ(function(d){return $CLJS.fE($CLJS.BG(d),$CLJS.RE)},a);var b=$CLJS.O(a);a=$CLJS.I.g(b,!1);b=$CLJS.I.g(b,!0);if($CLJS.rk.g($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.BG($CLJS.B(a));return $CLJS.Je(function(d){$CLJS.H(d,0,null);$CLJS.H(d,1,null);$CLJS.H(d,2,null);
var e=$CLJS.H(d,3,null);return $CLJS.n(iJ(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Pda=function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.uu,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Ss,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kJ],null)],
null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.wu,function(b){b=$CLJS.O(b);b=$CLJS.I.g(b,$CLJS.oj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(jJ(b))].join("")}],null),$CLJS.Ke(jJ)],null)],null)};
lJ=function(a){var b=$CLJS.R,c=Pda(a);a=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ss,new $CLJS.h(null,1,[$CLJS.uu,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qs,new $CLJS.h(null,1,[$CLJS.Kn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)],null)],null);
return new $CLJS.Q(null,3,5,b,[$CLJS.ft,c,a],null)};Qda=function(a){return $CLJS.Od($CLJS.hH,$CLJS.Ze.g(function(b){var c=$CLJS.BG(b),d=$CLJS.fE(c,$CLJS.NG);b=d?$CLJS.eH($CLJS.aI,b):d;return $CLJS.n(b)?$CLJS.yj:c},a))};Rda=function(a){a=$CLJS.hJ(function(b){return!$CLJS.fE(b,$CLJS.RE)},$CLJS.Ze.g($CLJS.BG,a));return $CLJS.fE(a,$CLJS.NG)?$CLJS.Fj:a};Sda=function(a){return $CLJS.n($CLJS.Je(function(b){return $CLJS.fE($CLJS.BG(b),$CLJS.RE)},a))?Rda(a):Qda(a)};
mJ=new $CLJS.K("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.nJ=new $CLJS.K("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);kJ=new $CLJS.K("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.K("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Vs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.K(null,"intervals","intervals",2096054013),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Is,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,kJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=$CLJS.BG(a);return $CLJS.Ie(function(d){return iJ(d,c)},b)}],null)],null));
$CLJS.Y(mJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qs,new $CLJS.h(null,1,[$CLJS.Kn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)],null));$CLJS.yI.g($CLJS.Is,lJ($CLJS.Is));$CLJS.yI.g($CLJS.ku,lJ($CLJS.ku));$CLJS.oH($CLJS.Js,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rt,mJ],null)]));$CLJS.oH($CLJS.tI,$CLJS.G([$CLJS.ku,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rt,mJ],null)]));
for(var oJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Is,$CLJS.ku,$CLJS.Js],null)),pJ=null,qJ=0,rJ=0;;)if(rJ<qJ){var Tda=pJ.X(null,rJ);$CLJS.fH(Tda,$CLJS.nJ);rJ+=1}else{var sJ=$CLJS.z(oJ);if(sJ){var tJ=sJ;if($CLJS.zd(tJ)){var uJ=$CLJS.kc(tJ),Uda=$CLJS.lc(tJ),Vda=uJ,Wda=$CLJS.E(uJ);oJ=Uda;pJ=Vda;qJ=Wda}else{var Xda=$CLJS.B(tJ);$CLJS.fH(Xda,$CLJS.nJ);oJ=$CLJS.C(tJ);pJ=null;qJ=0}rJ=0}else break}$CLJS.AG.m(null,$CLJS.nJ,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);return Sda(a)});
$CLJS.mH($CLJS.wH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));$CLJS.fH($CLJS.wH,$CLJS.SG);
for(var vJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vI,$CLJS.pH,$CLJS.KH],null)),wJ=null,xJ=0,yJ=0;;)if(yJ<xJ){var Yda=wJ.X(null,yJ);$CLJS.mH(Yda,$CLJS.G([$CLJS.ku,$CLJS.BF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));yJ+=1}else{var zJ=$CLJS.z(vJ);if(zJ){var AJ=zJ;if($CLJS.zd(AJ)){var BJ=$CLJS.kc(AJ),Zda=$CLJS.lc(AJ),$da=BJ,aea=$CLJS.E(BJ);vJ=Zda;wJ=$da;xJ=aea}else{var bea=$CLJS.B(AJ);$CLJS.mH(bea,$CLJS.G([$CLJS.ku,$CLJS.BF,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));vJ=$CLJS.C(AJ);wJ=null;xJ=0}yJ=0}else break}
for(var CJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sH,$CLJS.VH,$CLJS.bI],null)),DJ=null,EJ=0,FJ=0;;)if(FJ<EJ){var cea=DJ.X(null,FJ);$CLJS.mH(cea,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));FJ+=1}else{var GJ=$CLJS.z(CJ);if(GJ){var HJ=GJ;if($CLJS.zd(HJ)){var IJ=$CLJS.kc(HJ),dea=$CLJS.lc(HJ),eea=IJ,fea=$CLJS.E(IJ);CJ=dea;DJ=eea;EJ=fea}else{var gea=$CLJS.B(HJ);$CLJS.mH(gea,$CLJS.G([$CLJS.ku,$CLJS.hj,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));CJ=$CLJS.C(HJ);DJ=null;EJ=0}FJ=0}else break}$CLJS.mH($CLJS.tH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null)]));
$CLJS.AG.m(null,$CLJS.tH,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.fE($CLJS.BG(b),$CLJS.hj)&&$CLJS.fE($CLJS.BG(a),$CLJS.hj)?$CLJS.hj:$CLJS.BF});
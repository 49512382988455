var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var bda,dG,gG,sG;$CLJS.VF=function(a){return $CLJS.tk.g($CLJS.Ze.h(a),$CLJS.eE)};$CLJS.WF=function(a,b){a=$CLJS.bv($CLJS.tC(a,/''/,"'"),/\{\d+\}/);return $CLJS.P.j($CLJS.zu.t,$CLJS.Ok(a),$CLJS.Ok(b))};$CLJS.XF=new $CLJS.K("lib","metadata","lib/metadata",1798917220);$CLJS.YF=new $CLJS.K("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.ZF=new $CLJS.K(null,"num-bins","num-bins",847672055);$CLJS.$F=new $CLJS.K(null,"mbql","mbql",69346710);
$CLJS.aG=new $CLJS.K(null,"display-name","display-name",694513143);bda=new $CLJS.K("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.bG=new $CLJS.K("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.cG=new $CLJS.K("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);
dG=new $CLJS.K("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.eG=new $CLJS.K("lib","external-op","lib/external-op",-1470923877);$CLJS.fG=new $CLJS.K("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);gG=new $CLJS.K("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.hG=new $CLJS.K(null,"database-type","database-type",-426840562);
$CLJS.iG=new $CLJS.K(null,"operator","operator",-1860875338);$CLJS.jG=new $CLJS.K(null,"fk-target-field-id","fk-target-field-id",2019750287);$CLJS.kG=new $CLJS.K("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.lG=new $CLJS.K("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.mG=new $CLJS.K("lib","uuid","lib/uuid",-2145250720);
$CLJS.nG=new $CLJS.K(null,"x","x",2099068185);$CLJS.oG=new $CLJS.K("metabase.lib.schema.id","field","metabase.lib.schema.id/field",-985236431);$CLJS.pG=new $CLJS.K("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.qG=new $CLJS.K(null,"bin-width","bin-width",1377922579);$CLJS.rG=new $CLJS.K(null,"strategy","strategy",-1471631918);sG=new $CLJS.K("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.Y($CLJS.fG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ij,new $CLJS.h(null,1,[$CLJS.Kn,1],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,"non-blank string"],null),$CLJS.Ke($CLJS.uC)],null)],null));$CLJS.Y($CLJS.kG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.st,new $CLJS.h(null,1,[$CLJS.Kn,0],null)],null));$CLJS.Y($CLJS.YF,$CLJS.Al);$CLJS.Y(sG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ij,new $CLJS.h(null,2,[$CLJS.Kn,36,$CLJS.Rj,36],null)],null));
$CLJS.Y(gG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,2,[$CLJS.uu,"valid semantic type",$CLJS.wu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.oj);return["Not a valid semantic type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.fE(a,$CLJS.dj)}],null));
$CLJS.Y(dG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,2,[$CLJS.uu,"valid relation type",$CLJS.wu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.oj);return["Not a valid relation type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.fE(a,$CLJS.ei)}],null));$CLJS.Y($CLJS.cG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dG],null)],null));
$CLJS.Y($CLJS.pG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,2,[$CLJS.uu,"valid base type",$CLJS.wu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.oj);return["Not a valid base type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.fE(a,$CLJS.lj)}],null));
$CLJS.Y($CLJS.lG,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mG,sG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gD,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,$CLJS.pG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ti,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,$CLJS.pG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,
$CLJS.cG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hG,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,$CLJS.fG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,$CLJS.fG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aG,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,$CLJS.fG],null)],null)],null));
$CLJS.Y(bda,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.eG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,$CLJS.Ij,$CLJS.Ii],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kj,$CLJS.xt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Dt,!0],null),$CLJS.lG],null)],null));
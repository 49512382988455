var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var zG,EG,cda,dda,eda,fda,gda,hda;
$CLJS.xG=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.YB([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.yG=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.WF(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)};zG=function(a){return $CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K};
$CLJS.BG=function(a){var b=function(){var c=zG(a);return c?(c=$CLJS.wd($CLJS.hd(a)))?(c=$CLJS.ti.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.gD.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.AG.h(a)};
EG=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,2,[$CLJS.uu,"valid MBQL clause",$CLJS.wu,function(c){c=$CLJS.O(c);c=$CLJS.I.g(c,$CLJS.oj);return["invalid MBQL clause: ",$CLJS.Eh.l($CLJS.G([c]))].join("")}],null),$CLJS.Ke(zG)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.CG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ct,new $CLJS.h(null,1,[$CLJS.uu,b],null),function(c){c=$CLJS.BG(c);
return DG(c,a)}],null)],null)};$CLJS.FG=new $CLJS.K(null,"right-join","right-join",-56349359);cda=new $CLJS.K("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);dda=new $CLJS.K("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);eda=new $CLJS.K("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.GG=new $CLJS.K(null,"fields","fields",-1932066230);
$CLJS.HG=new $CLJS.K(null,"expr","expr",745722291);$CLJS.IG=new $CLJS.K("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.JG=new $CLJS.K("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.KG=new $CLJS.K(null,"alias","alias",-2039751630);$CLJS.LG=new $CLJS.K("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.MG=new $CLJS.K("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.NG=new $CLJS.K("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.OG=new $CLJS.K("lib","expression-name","lib/expression-name",-1799326590);$CLJS.PG=new $CLJS.K("mbql.clause","field","mbql.clause/field",1497292735);fda=new $CLJS.K("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);
$CLJS.QG=new $CLJS.K(null,"left-join","left-join",-672831855);$CLJS.RG=new $CLJS.K("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.SG=new $CLJS.K("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.TG=new $CLJS.K("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);
gda=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);hda=new $CLJS.K("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.UG=new $CLJS.K(null,"inner-join","inner-join",659431740);$CLJS.VG=new $CLJS.K("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);
$CLJS.CG=new $CLJS.K("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.WG=new $CLJS.K("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.XG=new $CLJS.K(null,"full-join","full-join",1305476385);$CLJS.YG=new $CLJS.K("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);
$CLJS.ZG=new $CLJS.K("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.AG=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.UD],null),$CLJS.Ti,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.SD(f);return $CLJS.F.g(k,$CLJS.fD)?$CLJS.Xa(f):k},e,a,b,c,d)}();
$CLJS.Y(dda,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fs,$CLJS.NG],null),$CLJS.pG],null));$CLJS.AG.m(null,$CLJS.Wh,function(a){throw $CLJS.ai($CLJS.WF("{0}: Don''t know how to determine the type of {1}",$CLJS.G([gda,$CLJS.Eh.l($CLJS.G([a]))])),new $CLJS.h(null,1,[$CLJS.HG,a],null));});$CLJS.AG.m(null,$CLJS.SG,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.BG(a)});
var DG=function DG(a,b){return $CLJS.ud(a)?$CLJS.Je(function(d){return DG.g?DG.g(d,b):DG.call(null,d,b)},a):$CLJS.ud(b)?$CLJS.Je(function(d){return DG.g?DG.g(a,d):DG.call(null,a,d)},b):$CLJS.F.g(a,$CLJS.NG)?!0:$CLJS.fE(a,b)};$CLJS.Y($CLJS.LG,EG($CLJS.uj,"expression returning a boolean"));$CLJS.Y($CLJS.WG,EG($CLJS.Mj,"expression returning a string"));$CLJS.Y($CLJS.ZG,EG($CLJS.hj,"expression returning an integer"));$CLJS.Y(eda,EG($CLJS.BF,"expression returning a non-integer real number"));
$CLJS.Y($CLJS.YG,EG($CLJS.yj,"expression returning a number"));$CLJS.Y(fda,EG($CLJS.GE,"expression returning a date"));$CLJS.Y(hda,EG($CLJS.EF,"expression returning a time"));$CLJS.Y(cda,EG($CLJS.eF,"expression returning a date time"));$CLJS.Y($CLJS.JG,EG($CLJS.Fj,"expression returning a date, time, or date time"));$CLJS.$G=new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.yj,null,$CLJS.Mj,null,$CLJS.Fj,null],null),null);$CLJS.Y($CLJS.TG,EG($CLJS.$G,"an expression that can be compared with :\x3e or :\x3c"));
var ida=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.pE,null,$CLJS.yj,null,$CLJS.Mj,null,$CLJS.WE,null,$CLJS.Fj,null,$CLJS.uj,null,$CLJS.zF,null],null),null);$CLJS.Y($CLJS.VG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WG],null),EG($CLJS.WE,"expression returning a BSONID")],null));$CLJS.Y($CLJS.RG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,EG(ida,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.Y($CLJS.MG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.et,EG($CLJS.lj,"any type of expression")],null));
$CLJS.Y($CLJS.IG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.Kn,1],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ss,$CLJS.xt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OG,$CLJS.Ij],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.xt],null)],null)],null)],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';$CLJS.b3=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.dc(c,d):c},$CLJS.cc($CLJS.tf),b))};$CLJS.c3=function(a,b,c){return null==c||$CLJS.Gd(a,b)?a:$CLJS.S.j(a,b,c)};$CLJS.d3=function(a){return $CLJS.mG.h($CLJS.uG(a))};$CLJS.e3=function(a,b){return $CLJS.Wf.j(new $CLJS.Q(null,2,5,$CLJS.R,[a,new $CLJS.h(null,1,[$CLJS.mG,$CLJS.p.h($CLJS.xG())],null)],null),$CLJS.Ze.h($CLJS.UX),b)};
$CLJS.f3=function(a,b){if($CLJS.z(b)){if($CLJS.F.g($CLJS.E(b),1))return $CLJS.B(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.F.g($CLJS.E(b),2)?[$CLJS.p.h($CLJS.B(b)),a,$CLJS.p.h($CLJS.hd(b))].join(""):[$CLJS.Fu(", ",$CLJS.Cu(b)),",",a,$CLJS.p.h($CLJS.id(b))].join("")}return null};$CLJS.h3=function(a,b){return $CLJS.g3.g(a,b instanceof $CLJS.K?b:$CLJS.Sk.h(b))};var Lla,Mla,Nla,Ola,Pla,k3;
$CLJS.i3=function(){function a(e,f){if($CLJS.Sa(f))return"";e=Math.abs(e);var k=$CLJS.rh.h(f);switch(k instanceof $CLJS.K?k.T:null){case "default":return $CLJS.aX("Default period","Default periods",e);case "millisecond":return $CLJS.aX("Millisecond","Milliseconds",e);case "second":return $CLJS.aX("Second","Seconds",e);case "minute":return $CLJS.aX("Minute","Minutes",e);case "hour":return $CLJS.aX("Hour","Hours",e);case "day":return $CLJS.aX("Day","Days",e);case "week":return $CLJS.aX("Week","Weeks",
e);case "month":return $CLJS.aX("Month","Months",e);case "quarter":return $CLJS.aX("Quarter","Quarters",e);case "year":return $CLJS.aX("Year","Years",e);case "minute-of-hour":return $CLJS.aX("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.aX("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.aX("Day of week","Days of week",e);case "day-of-month":return $CLJS.aX("Day of month","Days of month",e);case "day-of-year":return $CLJS.aX("Day of year","Days of year",e);case "week-of-year":return $CLJS.aX("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.aX("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.aX("Quarter of year","Quarters of year",e);default:return f=$CLJS.bv($CLJS.Zg(f),/-/),e=$CLJS.z(f),f=$CLJS.B(e),e=$CLJS.C(e),$CLJS.Fu(" ",$CLJS.ce($CLJS.mC(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.g3=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.UD],null),$CLJS.Ti,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.SD(f)},e,a,b,c,d)}();Lla=$CLJS.Oe($CLJS.N);Mla=$CLJS.Oe($CLJS.N);
Nla=$CLJS.Oe($CLJS.N);Ola=$CLJS.Oe($CLJS.N);Pla=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.UD],null),$CLJS.Ti,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));$CLJS.j3=new $CLJS.Xh($CLJS.xh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.SD,Pla,Lla,Mla,Nla,Ola);$CLJS.j3.m(null,$CLJS.Wh,function(){return null});$CLJS.j3.m(null,$CLJS.SI,function(a){return $CLJS.Sk.h(a)});k3=new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.KI,null,$CLJS.NI,null,$CLJS.OI,null,$CLJS.LI,null],null),null);
$CLJS.Qla=$CLJS.Wf.j($CLJS.tf,$CLJS.tk.g($CLJS.uk.h(k3),$CLJS.Ze.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.nv,$CLJS.SI,$CLJS.Sk,a],null);return $CLJS.F.g(a,$CLJS.ov)?$CLJS.S.j(b,$CLJS.Wh,!0):b})),$CLJS.cJ);$CLJS.Rla=$CLJS.vk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.nv,$CLJS.SI,$CLJS.Sk,a],null);return $CLJS.F.g(a,$CLJS.pv)?$CLJS.S.j(b,$CLJS.Wh,!0):b},$CLJS.YI);
$CLJS.Sla=$CLJS.Wf.j($CLJS.tf,$CLJS.tk.g($CLJS.uk.h(k3),$CLJS.Ze.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.nv,$CLJS.SI,$CLJS.Sk,a],null);return $CLJS.F.g(a,$CLJS.pv)?$CLJS.S.j(b,$CLJS.Wh,!0):b})),$CLJS.dJ);$CLJS.Q1.m(null,$CLJS.SI,function(a,b,c){a=$CLJS.O(c);a=$CLJS.I.g(a,$CLJS.Sk);return $CLJS.i3.h(a)});
$CLJS.X1.m(null,$CLJS.SI,function(a,b,c){return $CLJS.Lk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.aG,$CLJS.s1.j(a,b,c),$CLJS.N1,$CLJS.NC($CLJS.j3.h(c))],null),$CLJS.Ql(c,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wh,$CLJS.A1],null))]))});
$CLJS.l3=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.UD],null),$CLJS.Ti,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.SD(l)},e,a,b,c,d)}();$CLJS.l3.m(null,$CLJS.Wh,function(){return $CLJS.Ug});
$CLJS.m3=function(){function a(d,e,f){return $CLJS.l3.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();